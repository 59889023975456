import classNames from 'classnames';
import React from 'react';
import Loader from '../../../components/loader';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../components/input/search';
import { MessageSidebarItem } from './MessageSidebarItem';

function MessageSidebar({
  chats,
  isSearching,
  selected,
  setSelected,
  msgSidebarOpen,
  setMsgSidebarOpen,
  onSearchChats,

}) {
  const handleSidebarItemClick = (index) => {
    setMsgSidebarOpen(false);
    setSelected(index);
  };
  return (
    <div
      id="messages-sidebar"
      className={classNames(
        'tw-absolute tw-z-20 tw-top-0 tw-bottom-0 tw-w-full md:tw-w-auto md:tw-static md:tw-top-auto md:tw-bottom-auto tw--mr-px md:tw-translate-x-0 tw-transform tw-transition-transform tw-duration-200 tw-ease-in-out',
        msgSidebarOpen ? 'tw-translate-x-0' : 'tw--translate-x-full tw-invisible md:tw-visible'
      )}
    >
      <div className="tw-sticky tw-top-16 tw-bg-white tw-overflow-hidden tw-no-scrollbar tw-shrink-0 md:tw-w-72 xl:tw-w-80 tw-h-[calc(100vh-102px)]">
        <div className="tw-flex tw-flex-col tw-items-center tw-py-3  tw-gap-4">
          <Form onSubmit={() => {}}>
            {() => (
              <form className="tw-flex tw-flex-row tw-items-start tw-w-full tw-px-4">
                <div>
                  <SearchInput name="search" placeholder="Поиск" type="text" onSearchChange={onSearchChats} />
                </div>
              </form>
            )}
          </Form>
          <div className="tw-w-full tw-px-4 tw-max-h-[81dvh] tw-overflow-y-auto tw-overflow-x-hidden">
            {isSearching && <Loader />}
            {chats.length !== 0 ? (
              <ul className="tw-mb-6">
                {chats.map((chat, i) => (
                  <MessageSidebarItem
                    key={i}
                    isSelected={selected === i}
                    photo={chat.photos?.[0]}
                    host={chat.host}
                    messageState={chat?.lastMessage?.state}
                    sentAt={chat?.lastMessage?.sent_at}
                    lastMessage={chat?.lastMessage?.value}
                    unreadMessageAmount={chat?.unread_message_count}
                    handleClick={() => handleSidebarItemClick(i)}
                  />
                ))}
              </ul>
            ) : (
              <>
                {!isSearching && (
                  <div className="tw-flex tw-flex-row tw-mt-6 tw-justify-center">
                    <p className="tw-text-[#78858B] tw-leading-[18px] tw-font-medium tw-text-sm">Нет результатов</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageSidebar;
