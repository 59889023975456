import { Dialog, DialogActions } from '@mui/material';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { Form } from 'react-final-form';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback, useState, useEffect } from 'react';
import { createErrorNotification } from '../../../utils/notifications';

export const FormDialog = ({ title, onSubmit, onReject, value }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);
  useEffect(() => {
    if (formValue?.id !== value?.id) {
      setFormValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFormSubmit = useCallback(
    async (payload) => {
      const { id, name, standard_size, sub_categories } = payload;
      const correct_sub_categories =
        typeof sub_categories === 'string' && sub_categories.replace(/\s*,\s*/g, ',').split(',');
      const body = {
        name: name,
        standard_size: standard_size,
        sub_categories: correct_sub_categories,
      };
      try {
        onSubmit &&
          (!id ? await onSubmit(body).then(() => dialog.close()) : await onSubmit(id, body).then(() => dialog.close()));
      } catch (err) {
        console.error(err);
      }
    },
    [onSubmit, dialog]
  );

  const handleFormReject = useCallback(() => {
    onReject && onReject();
    dialog.close();
  }, [onReject, dialog]);

  return (
    <Dialog open={dialog.visibility}>
      <Form
        initialValues={formValue}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, values, submitError, submitting, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-xl">
              <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
              <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-mb-14 md:tw-w-[440px]">
                <div>
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Имя</p>
                  <Input form={form} value={values['name']} name="name" type="text" required />
                </div>
                <div>
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Размер</p>
                  <Input form={form} value={values['standard_size']} name="standard_size" type="text" />
                </div>
                <div>
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Подкатегория</p>
                  <Input form={form} value={values['sub_categories']} name="sub_categories" type="text" />
                </div>
              </div>

              <DialogActions className="tw-gap-[18px]">
                <Button
                  className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm"
                  variant="text"
                  disabled={submitting}
                  onClick={handleFormReject}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={submitting && invalid && pristine}
                  type="submit"
                >
                  Добавить
                </Button>
              </DialogActions>
              {submitError && createErrorNotification(submitError)}
            </div>
          </form>
        )}
      ></Form>
    </Dialog>
  );
};
