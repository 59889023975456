import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import VisibilitySensor from 'react-visibility-sensor';
import Alert from '../../components/alert';
import GeneralLayout from '../../components/general-layout';
import Loader from '../../components/loader';
import ModificationArchiveReport from '../../components/modification-archive-report';
import DeleteIcon from '../../icons/delete';
import { getArchivedProjects, removeArchivedProject } from '../../store/actions/organizations';
import { checkIsAdmin } from '../../utils/common';
import classes from './archived-projects.module.sass';

export default function ArchivedProjectPage() {
  const [activeDeleteId, setActiveDeleteId] = useState(null);
  const archivedProjects = useSelector((state) => state.organizations.archivedProjectList);
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  const history = useHistory();
  const [loadingProjects, setLoadingProjects] = useState(false);
  const canLoadMoreArchivedProjects = useSelector((state) => state.organizations.canLoadMoreArchivedProjects);
  const [showSelectProject, setShowSelectProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    if (archivedProjects.length === 0) {
      setLoadingProjects(true);
      getArchivedProjects().then(() => setLoadingProjects(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = (isVisible) => {
    if (isVisible && canLoadMoreArchivedProjects && !loadingProjects && archivedProjects.length > 0) {
      setLoadingProjects(true);
      getArchivedProjects().then(() => setLoadingProjects(false));
    }
  };

  const handleCheckAll = (e) => {
    e.stopPropagation();
    if (e.target.checked) {
      setIsSelectAll(true);
      setSelectedProject(archivedProjects.map((_, i) => i));
    } else {
      setIsSelectAll(false);
      setSelectedProject([]);
    }
  };

  const handleSelectProject = (e, index) => {
    const newSelectedProject = [...selectedProject, index];
    if (!e.target.checked) {
      setSelectedProject(selectedProject.filter((p) => p !== index));
      setIsSelectAll(false);
    } else {
      setSelectedProject(newSelectedProject);
    }
  };

  console.log(
    { archivedProjects, selectedProject },
    archivedProjects.filter((_, i) => selectedProject.includes(i)).map((project) => project.id)
  );

  return (
    <GeneralLayout>
      <Container className={classes.container}>
        <div className="sm:tw-flex sm:tw-justify-between sm:tw-items-center tw-mb-2">
          <div className="tw-mb-4 sm:tw-mb-0">
            <h1 className="tw-text-2xl md:tw-text-3xl tw-text-gray-800 tw-font-bold">Архивные отчеты </h1>
          </div>
        </div>
        <Row>
          <Col xs={12} xl={9} className="ms-auto" style={{ display: 'flex', gap: 10 }}>
            <div className={classes.projects}>
              {archivedProjects.length > 0 && (
                <table className={classes.table}>
                  <thead>
                    <tr>
                      {showSelectProject && (
                        <th style={{ width: 'fit-content', paddingRight: 4 }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={isSelectAll || selectedProject.length === archivedProjects.length}
                            onChange={handleCheckAll}
                          />
                        </th>
                      )}
                      <th style={{ maxWidth: '150px' }}>Название</th>
                      <th style={{ minWidth: '100px' }}>Дата создания</th>
                      {isAdmin ? <th>Email</th> : null}
                      <th>Количество товаров</th>
                      <th>Количество источников</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {archivedProjects.map((project, index) => {
                      const projectDate = moment(project.created_at).format('DD-MM-YYYY');
                      return (
                        <tr
                          className={classes.projectItem}
                          key={project.id}
                          onClick={() => history.push(`/archived-projects/${project.id}`)}
                        >
                          {showSelectProject && (
                            <td style={{ width: 'fit-content', paddingRight: 4 }}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={index}
                                checked={selectedProject.includes(index) || isSelectAll}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => handleSelectProject(e, index)}
                              />
                            </td>
                          )}
                          <td>{project.name}</td>
                          <td>{projectDate}</td>
                          {isAdmin ? <td>{project.author_email}</td> : null}
                          <td>{project.count_archived_products}</td>
                          <td>{project.count_archived_sources}</td>
                          <td>
                            <div className={classes.actions} onClick={(e) => e.stopPropagation()}>
                              <div className={classes.action} onClick={() => setActiveDeleteId(project.id)}>
                                <DeleteIcon />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {canLoadMoreArchivedProjects && (
                <VisibilitySensor onChange={handleLoadMore}>
                  <Loader className="mb-3" />
                </VisibilitySensor>
              )}
              {!canLoadMoreArchivedProjects && archivedProjects.length === 0 && <div>Нет проектов.</div>}
              <Alert
                show={activeDeleteId}
                onHide={() => setActiveDeleteId(null)}
                onSubmit={() => removeArchivedProject(activeDeleteId)}
                title="Вы уверены, что хотите удалить этот проект?"
              />
            </div>
          </Col>
          <Col xs={12} xl={3} className={classes.right}>
            <ModificationArchiveReport
              projectIds={archivedProjects.filter((_, i) => selectedProject.includes(i)).map((project) => project.id)}
              canSave={selectedProject.length > 0}
              showSelectProject={showSelectProject}
              setShowSelectProject={setShowSelectProject}
              setSelectedProject={setSelectedProject}
              setIsSelectAll={setIsSelectAll}
            />
          </Col>
        </Row>
      </Container>
    </GeneralLayout>
  );
}
