import { useEffect, useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import { userAPIs } from '../../services';
import { createErrorNotification } from '../../utils/notifications';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ReactComponent as ExpandIcon } from '../../icons/expandIcon.svg';

const AccordionMUI = ({ isExpanded, name, handleChange, header, content }) => {
  const textColor = isExpanded ? 'tw-text-[#FFF]' : 'tw-text-[#191919]';
  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={handleChange(name)}
        sx={{
          '&.MuiAccordion-root': {
            borderRadius: '8px',
            boxShadow: 'none',
            backgroundColor: isExpanded ? '#0A3D9B' : '#FFF',
            '&::before': {
              height: '0px',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          sx={{
            '&.MuiAccordionSummary-root': {
              padding: '0 32px',
              minHeight: '66px',
            },
          }}
        >
          <p className={`tw-text-[18px] tw-leading-[22px] tw-font-semibold ${textColor}`}>{header}</p>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&.MuiAccordionDetails-root': {
              padding: '2px 32px 30px',
            },
          }}
        >
          <p className={`${textColor} tw-text-[16px] tw-leading-5 tw-font-medium tw-max-w-[87%] tw-whitespace-pre-line`}>{content}</p>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const FaqPage = () => {
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState('panel0');
  useEffect(() => {
    const getData = async () => {
      await userAPIs
        .getFaq()
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          createErrorNotification('Произошла ошибка при получении данных');
          console.error(err);
        });
    };
    getData();
  }, []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <GeneralLayout>
        <h1 className="tw-text-[#191919] tw-font-semibold tw-text-[32px] tw-leading-[38px] tw-mb-[40px]">FAQ</h1>
        <div className="tw-flex tw-flex-col tw-gap-3">
          {data.length > 0 &&
            data.map((data, index) => (
              <>
                <AccordionMUI
                  content={data?.content}
                  isExpanded={expanded === `panel${index}`}
                  name={`panel${index}`}
                  key={data?.header}
                  handleChange={handleChange}
                  header={data?.header}
                />
              </>
            ))}
        </div>
      </GeneralLayout>
    </>
  );
};
