import {
  Table as TableMUI,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import classes from './table.module.sass';

const Table = ({ rows, columns, emptyRowsLabel = 'Нет данных', stickyHeader, isLoading }) => {
  const mobile = useMediaQuery('(max-width: 500px)');
  return (
    <>
      {!mobile && (
        <TableMUI className={classes.table} stickyHeader={stickyHeader}>
          <TableHead className="tw-w-full">
            <TableRow>
              {Object.keys(columns).map((key, index) => {
                return (
                  <>
                    <TableCell className="tw-text-[#666666] tw-text-sm tw-font-medium tw-border-b-0" key={index}>
                      {columns[key]?.renderHeaderCell ? columns[key].renderHeaderCell() : columns[key].label}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          {rows?.length > 0 && !isLoading ? (
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(columns).map((key, index) => (
                      <TableCell
                        key={index}
                        className={`tw-text-[#191919] tw-text-sm tw-font-medium tw-border-b-0 tw-line-clamp-2 ${classes.bodyCell}`}
                      >
                        {columns[key]?.renderBodyCell ? columns[key].renderBodyCell(row) : <p>{row[key]}</p>}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={12}>
                  <div className="tw-flex tw-flex-row tw-justify-center">
                    {isLoading && <CircularProgress />}
                    {!isLoading && (rows?.length === 0 || !rows) && <>{emptyRowsLabel}</>}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </TableMUI>
      )}
      {mobile && (
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full tw-pt-[20px]">
          {rows?.map((row, index) => {
            return (
              <div
                className="tw-flex tw-flex-col tw-items-start tw-pt-4 tw-border-t tw-border-t-[#E6E6E6] tw-w-full tw-gap-3"
                key={index}
              >
                {Object.keys(columns).map((key, index) => (
                  <div className="tw-flex tw-flex-col tw-gap-[2px] tw-relative" key={index}>
                    {columns[key]?.renderHeaderCell ? (
                      columns[key].renderHeaderCell()
                    ) : (
                      <p className="tw-text-[#666666] tw-text-xs tw-leading-4 tw-font-medium">{columns[key].label}</p>
                    )}
                    <div className={`${classes.bodyCell}`}>
                      {columns[key]?.renderBodyCell ? (
                        columns[key].renderBodyCell(row)
                      ) : (
                        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium ">{row[key]}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Table;
