import React from 'react';
import NumberFormat from 'react-number-format';
import { useHistory, Link } from 'react-router-dom';
import classes from '../landing.module.sass';
import { checkIsAdmin } from '../../../utils/common';
import { ReactComponent as SearchIcon } from '../../../icons/searchIcon.svg';
import Button from '../../../components/button';
import { useSelector } from 'react-redux';

function Statistic({ news = {} }) {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  return (
    <>
      <div className={`${classes.suppliersWidget}  tw-h-full`}>
        <div className="tw-h-full tw-py-4 tw-px-6 tw-bg-white tw-rounded-xl tw-flex tw-flex-col ">
          <h2 className={`tw-mb-[17px] tw-text-[#191919] tw-text-2xl tw-font-semibold tw-leading-[28px] `}>
            Поставщики
          </h2>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">Всего поставщиков</p>
            <Link
              to="/suppliers"
              className="tw-text-[#0844B4] tw-font-semibold tw-text-sm tw-leading-[18px] tw-cursor-pointer"
            >
              Все
            </Link>
          </div>
          <big className="tw-mb-6">
            <NumberFormat
              className="tw-text-[#191919] tw-font-bold tw-text-[40px] tw-leading-[48px]"
              value={news.count_suppliers || 0}
              // suffix=" ₽"
              decimalScale={2}
              displayType="text"
              thousandSeparator=" "
              decimalSeparator=","
            />
          </big>
          <div
            className="tw-flex tw-flex-row tw-items-center tw-rounded-lg tw-bg-[#F7F7F7] tw-gap-1 tw-py-[11px] tw-px-3 tw-mb-8"
            onClick={() => history.push('/suppliers')}
          >
            <SearchIcon className="tw-w-6 tw-h-6" />
            <p className="tw-text-sm tw-text-[#7F7F7F] tw-leading-[18px]">Найти поставщика</p>
          </div>
          {/* <div className="tw-absolute tw-inset-0 tw-1"></div> */}
          {isAdmin && (
            <Button
              variant="outlined"
              className="tw-flex tw-flex-row tw-items-center tw-gap-[6px] tw-rounded-lg tw-mt-auto"
              onClick={() => history.push('/suppliers')}
            >
              <svg
                class="tw-shrink-0 tw-h-6 tw-w-6"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="tw-shrink-0 tw-h-6 tw-w-6"
                  d="M9.325 15H10.825V10.85H15V9.35H10.825V5H9.325V9.35H5V10.85H9.325V15ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3583 0 9.975C0 8.60833 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.64167 0 10.025 0C11.3917 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10.025 18.5C12.375 18.5 14.375 17.6708 16.025 16.0125C17.675 14.3542 18.5 12.3417 18.5 9.975C18.5 7.625 17.675 5.625 16.025 3.975C14.375 2.325 12.3667 1.5 10 1.5C7.65 1.5 5.64583 2.325 3.9875 3.975C2.32917 5.625 1.5 7.63333 1.5 10C1.5 12.35 2.32917 14.3542 3.9875 16.0125C5.64583 17.6708 7.65833 18.5 10.025 18.5Z"
                  fill="#0844B4"
                ></path>
              </svg>
              Добавить поставщика
            </Button>
          )}
        </div>
      </div>

      {isAdmin && (
        <>
          <div
            className={`${classes.archiveWidget}  tw-relative tw-z-20 tw-overflow-hidden tw-h-full tw-bg-white  tw-rounded-xl`}
          >
            <button
              className={`tw-w-full  tw-hidden tw-relative tw-overflow-hidden tw-h-full tw-bg-white  tw-rounded-sm tw-rounded-2xl tw-h-[calc(120px)] tw-hidden `}
              onClick={() => history.push('/statistics/general')}
            >
              <div className={`${classes.label} tw-text-left tw-relative tw-z-10  mb-3`}>Архивные товары</div>
              <div className="tw-relative tw-z-10 tw-text-3xl tw-font-bold tw-text-gray-800 tw-mr-2">
                <NumberFormat
                  value={news.count_archived_products || 0}
                  decimalScale={2}
                  displayType="text"
                  thousandSeparator=" "
                  decimalSeparator=","
                />
              </div>
            </button>
            <div
              className={`${classes.scrollable} tw-w-full tw-py-4 tw-px-6 tw-text-left tw-relative tw-overflow-hidden tw-h-full tw-bg-white tw-rounded-xl`}
            >
              <div className={`tw-text-left mb-3 tw-leading-[28px] tw-text-2xl tw-font-semibold`}>Архивные отчеты</div>
              <p className="tw-text-[#666666] tw-text-sm tw-font-medium d-flex tw-leading-[18px]">
                Всего отчетов
                <Link
                  to="/statistics/archived-projects"
                  className="tw-text-[#0844B4] tw-font-semibold d-flex ms-auto text-decoration-none tw-leading-[18px]"
                >
                  Все
                </Link>
              </p>
              <big className="tw-text-[40px] tw-font-bold tw-text-gray-800 tw-leading-10">
                <NumberFormat
                  value={news.count_archived_projects || 0}
                  decimalScale={2}
                  displayType="text"
                  thousandSeparator=" "
                  decimalSeparator=","
                />
              </big>
              <p className="tw-text-[#666666] tw-text-sm tw-mb-1 tw-mt-4 tw-font-medium tw-leading-[18px]">Последняя добавленная рассылка</p>
              <p className="tw-text-[#0844B4] tw-mb-[14px] tw-leading-5">Бананы и кокосы</p>
              <p className="tw-text-[#666666] tw-text-sm tw-mb-1 tw-font-medium tw-leading-[18px]">Рассылка</p>
              <p className="mb-3 tw-leading-5 tw-font-medium">По понедельникам</p>
              <p className="tw-text-[#666666] tw-text-sm tw-mb-1 tw-font-medium tw-leading-[18px]">На почту</p>
              <p className="tw-mb-3 tw-leading-5 tw-font-medium">Exemple@mail.com</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Statistic;
