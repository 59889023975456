import GeneralLayout from '../../components/general-layout';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { adminAPIs } from '../../services/adminAPIs';
import { createErrorNotification } from '../../utils/notifications';
import Button from '../../components/button';
import { ReactComponent as CheckIcon } from '../../icons/greenCheckIcon.svg';
import { useDialog } from '../../providers/dialog.provider';
import { RequestSettingsModal } from './RequestSettingModal';
import { Link, useParams } from 'react-router-dom';
import { AnswerToTicket } from './modals/AnswerToTicket';

export const DetailedRequest = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const dialog = useDialog();
  const [refetch, setRefetch] = useState(false);
  const getCorrectDate = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY');
    } else return '-';
  };
  const getCorrectProject = (entity) => {
    if (entity?.project)
      return (
        <>
          <Link
            className="tw-text-[#0844B4] tw-leading-[18px] hover:tw-text-[#0A50D4] tw-cursor-pointer"
            to={`/projects/${entity?.project_id}`}
          >
            {entity?.project}
          </Link>
        </>
      );
    return (
      <>
        <p>-</p>
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await adminAPIs
        .getSupportRequest(id)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка получения заявки');
        });
    };
    id && fetchData();
  }, [id, refetch]);

  const getCorrectStatus = () => {
    if (data?.status === 'Принято к сведению') return 'accepted';
    if (data?.status === 'В очереди на обработку') return 'in_queue';

    return data?.status ?? '';
  };

  const getCorrectPriority = () => {
    return data?.criticality ?? '';
  };

  const handleChangeRequest = (value) => {
    setData(value);
  };

  const openSettingModal = useCallback(() => {
    dialog.open(
      <RequestSettingsModal
        currentPriority={getCorrectPriority()}
        currentStatus={getCorrectStatus()}
        request_id={data?.id}
        onChange={handleChangeRequest}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, data]);

  const onAnswerSend = () => {
    setRefetch((prev) => !prev);
  };
  const openAnswerModal = useCallback(() => {
    dialog.open(<AnswerToTicket onSend={onAnswerSend} supportRequestId={data?.id} />);
  }, [dialog, data?.id]);

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-h-full">
        <h2 className="tw-text-[#191919] tw-leading-[38px] tw-font-semibold tw-text-[32px] tw-mb-8">Обращение</h2>
        <div className="tw-flex tw-flex-col tw-bg-white tw-p-6 tw-gap-[20px] tw-rounded-lg">
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Проект</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{getCorrectProject(data)}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Имя</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{data?.name}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Email</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{data?.email}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Компания</p>
            <p className="tw-text-[#0A50D4] tw-leading-[18px] tw-text-sm tw-font-medium">{data?.organization}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Дата создания</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">
              {getCorrectDate(data?.created_at)}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Срок</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">
              {getCorrectDate(data?.deadline)}
            </p>
          </div>

          <div className={`tw-flex tw-flex-col tw-gap-1 tw-items-start ${data?.answer ? '' : 'tw-mb-12'}`}>
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Проблема</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium tw-max-w-[741px]">
              {data?.description}
            </p>
          </div>

          {data?.answer && (
            <>
              <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start tw-mb-12">
                <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Ответ</p>
                <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium tw-max-w-[741px]">
                  {data?.answer}
                </p>
              </div>
            </>
          )}
          {data?.status !== 'Исполнено' && data?.status !== 'completed' ? (
            <div className="tw-flex tw-flex-row tw-gap-4">
              <Button
                className="tw-w-fit tw-text-sm tw-leading-[18px] tw-font-semibold tw-text-center tw-rounded-lg"
                onClick={() => openSettingModal()}
              >
                Решение по заявке
              </Button>
              <Button
                onClick={() => openAnswerModal()}
                className="tw-w-fit tw-text-sm tw-leading-[18px] tw-font-semibold tw-text-center tw-rounded-lg"
              >
                Отправить ответ
              </Button>
            </div>
          ) : (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
              {' '}
              <CheckIcon />{' '}
              <p className="tw-text-sm tw-leading-[18px] tw-text-[#0BD90B] tw-font-semibold">Заявка выполнена</p>
            </div>
          )}
        </div>
      </div>
    </GeneralLayout>
  );
};
