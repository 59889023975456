import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../../utils/request';

export const ChatbotWidget = () => {
  const [chats, setChats] = useState(null)
  const checkDate = (date) => {
    const withoutTime = moment(date).format('DD.MM.YYYY');
    if (moment(withoutTime).isSame(new Date())) {
      const formattedDate = moment(date).format('HH:mm');
      return formattedDate;
    } else return withoutTime;
  };
  const formattedText = (text) => {
    const cleanText = text?.replace(/<\/?[^>]+(>|$)/g, '');
    if (cleanText) return cleanText;
    else return text;
  };
  useEffect(() => {
    const fetchRecentChats = async () => {
      await request('/api/chats?page_size=5').then((res) => setChats(res.data)).catch((err) => {
        console.error(err)
      })
    }
    fetchRecentChats()
  }, [])
  return (
    <div className="tw-bg-[#fff] tw-rounded-xl tw-flex tw-flex-col tw-pb-[16px] tw-pt-[20px] tw-px-[24px] tw-h-full tw-overflow-hidden">
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-mb-4">
        <h2 className="tw-text-2xl tw-text-[#191919] tw-font-semibold tw-leading-[28px]">Чат-бот</h2>
        {
          // Добавить при появлении поля о непрочитанных сообщениях в ответе с /api/chats/{chatId}
          /* <span className="tw-bg-[#FF4343] tw-flex tw-items-center tw-justify-center tw-text-[#fff] tw-rounded-full tw-leading-none tw-py-[3px] tw-px-[5px] tw-text-sm tw-min-w-[20px] tw-h-[20px]">
          3
        </span> */
        }
      </div>
      <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">Последние сообщения</p>
        <Link to="/chatbot" className="tw-text-[#0844B4] tw-text-sm tw-font-semibold tw-leading-[18px]">
          Все
        </Link>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-1 tw-mt-3">
        {chats?.length > 0 ? (
          chats.map((chat) => {
            return (
              <div className="tw-flex tw-flex-row tw-bg-[#F7F7F7] tw-rounded-[14px] tw-py-[14px] tw-px-[16px] tw-gap-2" key={`message-${chat.id}`}>
                {chat.photos[0] ? (
                  <img src={`${chat.photos[0]}`} className='tw-max-w-[42px] tw-max-h-[42px] tw-rounded-full' alt="conversation_photo" />
                ) : (
                  <img
                    className="tw-max-w-[42px] tw-max-h-[42px] tw-rounded-full"
                    src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                    alt="placeholder"
                  />
                )}
                <div className="tw-flex tw-flex-col tw-w-full">
                  <div className="tw-flex tw-flex-row tw-justify-between">
                    <p className="tw-text-[#191919] tw-font-semibold tw-text-sm">{chat.host}</p>
                    <p className="tw-text-[#78858B] tw-text-xs">{checkDate(chat?.lastMessage?.sent_at)}</p>
                  </div>
                  <p className="tw-text-[#666666] tw-text-sm tw-font-normal tw-truncate tw-max-w-[224px]">
                    {formattedText(chat?.lastMessage?.value)}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p>Нет сообщений</p>
        )}
      </div>
    </div>
  );
};
