import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GeneralLayout from '../../components/general-layout';
import Loader from '../../components/loader';
import { chatsAPIs } from '../../services';
import { EmptyMessageBody, MessageBody } from './components/MessageBody';
import MessageSidebar from './components/MessageSidebar';
import queryString from 'query-string';

function ChatbotPage() {
  const location = useLocation();
  const [chats, setChats] = useState([]);
  const [selected, setSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [msgSidebarOpen, setMsgSidebarOpen] = useState(JSON.parse(localStorage.getItem('msgSidebarOpen') || 'true'));
  const [isSearching, setIsSearching] = useState(false);

  const fetchData = async () => {
    try {
      const response = await chatsAPIs.getChats();
      setChats(response.data || []);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (searchText) => {
    try {
      setIsSearching(true);
      const response = await chatsAPIs.getChats(searchText);
      setChats(response.data || []);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (location) {
      const query = queryString.parse(location.search);
      setSelected(
        Math.max(
          chats.findIndex((chat) => chat.host === query.host),
          0
        )
      );
    }
  }, [location, chats]);

  useEffect(() => {
    localStorage.setItem('msgSidebarOpen', msgSidebarOpen);
  }, [msgSidebarOpen]);

  return (
    <GeneralLayout noPadding={true} fullWidth={true}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="tw-relative tw-flex tw-flex-col tw-w-full">
          <h2 className="tw-text-[#191919] tw-font-semibold tw-text-[32px] tw-p-8">Чат-бот</h2>
          <div className="tw-flex">
            <MessageSidebar
              chats={chats}
              isSearching={isSearching}
              selected={selected}
              setSelected={setSelected}
              msgSidebarOpen={msgSidebarOpen}
              setMsgSidebarOpen={setMsgSidebarOpen}
              onSearchChats={handleSearch}
            />
            {chats?.length > 0 ? (
              <MessageBody
                chat={chats?.[selected]}
                msgSidebarOpen={msgSidebarOpen}
                setMsgSidebarOpen={setMsgSidebarOpen}
              />
            ) : (
              <EmptyMessageBody />
            )}
          </div>
        </div>
      )}
    </GeneralLayout>
  );
}

export default ChatbotPage;
