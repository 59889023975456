import { Link } from 'react-router-dom';
import { usePagination } from '../../components/table/usePagination';
import { adminAPIs } from '../../services/adminAPIs';
import { useState } from 'react';
import { useEffect } from 'react';
import Table from '../../components/table';
import { Pagination } from '../../components/table/pagination';
import GeneralLayout from '../../components/general-layout';
import { Form } from 'react-final-form';
import { SearchInput } from '../../components/input/search';
import { createErrorNotification } from '../../utils/notifications';

export const CorrectPricesTable = () => {
  const [data, setData] = useState([]);

  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
  });
  const columns = {
    id: {
      label: 'ID',
      renderBodyCell: (value) => {
        return (
          <Link to={`/incorrect-prices/${value?.id}`} className='tw-text-[#0A50D4]'>{value?.id}</Link>
        )
      }
    },
    user_name: {
      label: 'Имя'
    },
    organization: {
      label: 'Компания',
    },
    project_name: {
      label: 'Проект',
      renderBodyCell: (value) => {
        return (
          <Link to={`/projects/${value?.project_id}`} className="tw-text-[#0A50D4] hover:tw-text-[#0844B4]">
            {value?.project_name}
          </Link>
        );
      },
    },
    name: {
      label: 'Товар',
      renderBodyCell: (value) => {
        return (
          <>
            <p className="tw-truncate tw-max-w-[121px]">{value?.name}</p>
          </>
        );
      },
    },
    url: {
      label: 'Ссылка',
      renderBodyCell: (value) => {
        return (
          <a href={`${value?.url}`} className="tw-text-[#0A50D4]">
            Ссылка
          </a>
        );
      },
    },
    found_price: {
      label: 'Неправильная цена',
      renderBodyCell: (value) => {
        return <>{numberFormatter.format(value?.found_price)}</>;
      },
    },
    correct_price: {
      label: 'Правильная цена',
      renderBodyCell: (value) => {
        return <>{numberFormatter.format(value?.correct_price)}</>;
      },
    },
  };
  const fetchData = async (page_size, page_num, query) => {
    const res = await adminAPIs.getPriceRequests(page_size, page_num, query).catch((err) => {
        console.error(err)
        createErrorNotification('Ошибка получения списка заявок')
    })
    return res
  }
  const {
    data: paginatedData,
    currentPage,
    goToPage,
    total,
    setPageSize,
    pageSize,
    handleChangeQuery
  } = usePagination(fetchData);
  useEffect(() => {
    setData(paginatedData);
  }, [paginatedData]);

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col">
        <h2 className="tw-text-[#191919] tw-leading-[38px] tw-font-semibold tw-text-[32px] tw-mb-8">
          Запрос на некорректную цену
        </h2>
        <div className="tw-bg-white tw-flex tw-flex-col tw-rounded-lg">
          <div className="tw-flex tw-flex-row tw-justify-start tw-pt-[25px] tw-px-6">
            <Form onSubmit={() => {}}>
              {() => (
                <form>
                  <SearchInput name="search" placeholder="Найти" type="text" onSearchChange={handleChangeQuery} />
                </form>
              )}
            </Form>
          </div>
          <div className="tw-p-2">
            <Table rows={data} columns={columns} />
          </div>
        </div>
        <Pagination
          rowsPerPage={pageSize}
          count={total}
          page={currentPage}
          onPageChange={goToPage}
          onLimitChange={setPageSize}
        />
      </div>
    </GeneralLayout>
  );
};
