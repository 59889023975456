export default function LogoIcon({ className, primary }) {
  return (
      <svg width="148" height="45" viewBox="0 0 148 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1070_7178)">
          <path d="M18 1C8.064 1 3.2573e-06 9.06399 3.2573e-06 19C-0.00145743 21.4289 0.488361 23.8331 1.44 26.0679L6.95996 23.716C6.336 22.2641 6.00005 20.6798 6.00005 19C6.00005 12.376 11.376 7.00004 18 7.00004C24.624 7.00004 30 12.376 30 19C30 25.1439 25.38 30.196 19.44 30.9159L20.16 36.8679C29.076 35.8 36 28.216 36 19C36 9.06399 27.936 1 18 1ZM16.2 43H10.2V16.0001H16.2V43Z" fill="#0BD90B"></path>
        </g>
        <path d="M51.7808 19.2729V28.0002H50.4683V20.3979H46.3604V28.0002H45.0479V19.2729H51.7808Z" fill="white"></path>
        <path d="M53.7943 28.0002V19.2729H56.9051C57.5841 19.2729 58.1466 19.3965 58.5926 19.6437C59.0386 19.8908 59.3724 20.2289 59.594 20.6579C59.8156 21.084 59.9264 21.5641 59.9264 22.0982C59.9264 22.6352 59.8142 23.1181 59.5898 23.5471C59.3682 23.9732 59.0329 24.3113 58.5841 24.5613C58.1381 24.8085 57.577 24.932 56.9008 24.932H54.7616V23.8156H56.7815C57.2105 23.8156 57.5585 23.7417 57.8256 23.594C58.0926 23.4434 58.2886 23.2389 58.4136 22.9803C58.5386 22.7218 58.6011 22.4278 58.6011 22.0982C58.6011 21.7687 58.5386 21.4761 58.4136 21.2204C58.2886 20.9647 58.0912 20.7644 57.8213 20.6195C57.5542 20.4747 57.202 20.4022 56.7645 20.4022H55.1111V28.0002H53.7943Z" fill="white"></path>
        <path d="M61.4701 28.0002V19.2729H66.9417V20.4065H62.7868V23.0656H66.6562V24.1948H62.7868V26.8667H66.9928V28.0002H61.4701Z" fill="white"></path>
        <path d="M68.04 30.1096V26.8837H68.6153C68.8113 26.719 68.9946 26.4846 69.165 26.1806C69.3355 25.8766 69.4804 25.4661 69.5997 24.9491C69.719 24.432 69.8014 23.7687 69.8469 22.959L70.0514 19.2729H75.4335V26.8837H76.4221V30.0968H75.1608V28.0002H69.3014V30.1096H68.04ZM70.1665 26.8837H74.1721V20.4065H71.2446L71.1082 22.959C71.0713 23.5755 71.0102 24.1295 70.925 24.621C70.8426 25.1124 70.7361 25.5471 70.6054 25.9249C70.4775 26.2999 70.3312 26.6195 70.1665 26.8837Z" fill="white"></path>
        <path d="M77.8646 19.2729H79.1686V25.682H79.2496L83.6899 19.2729H84.9086V28.0002H83.5919V21.5997H83.5109L79.0791 28.0002H77.8646V19.2729Z" fill="white"></path>
        <path d="M92.007 28.0002L89.0752 24.0883H88.1462V28.0002H86.8295V19.2729H88.1462V22.959H88.5766L91.8749 19.2729H93.4814L89.9914 23.1806L93.6135 28.0002H92.007Z" fill="white"></path>
        <path d="M94.403 20.4065V19.2729H101.157V20.4065H98.4342V28.0002H97.1217V20.4065H94.403Z" fill="white"></path>
        <path d="M102.65 19.2729H103.954V25.682H104.035L108.475 19.2729H109.694V28.0002H108.377V21.5997H108.296L103.864 28.0002H102.65V19.2729Z" fill="white"></path>
        <path d="M111.615 28.0002V19.2729H114.811C115.43 19.2729 115.943 19.3752 116.349 19.5798C116.755 19.7815 117.059 20.0556 117.261 20.4022C117.463 20.746 117.563 21.1337 117.563 21.5656C117.563 21.9292 117.497 22.236 117.363 22.486C117.23 22.7332 117.051 22.932 116.826 23.0826C116.605 23.2303 116.36 23.3383 116.093 23.4065V23.4917C116.383 23.5059 116.666 23.5997 116.941 23.7729C117.22 23.9434 117.45 24.1863 117.632 24.5016C117.813 24.817 117.904 25.2005 117.904 25.6522C117.904 26.0982 117.799 26.4988 117.589 26.8539C117.382 27.2062 117.061 27.486 116.626 27.6934C116.191 27.8979 115.636 28.0002 114.96 28.0002H111.615ZM112.931 26.871H114.832C115.463 26.871 115.914 26.7488 116.187 26.5045C116.46 26.2602 116.596 25.9548 116.596 25.5883C116.596 25.3127 116.527 25.0599 116.387 24.8298C116.248 24.5997 116.049 24.4164 115.791 24.2801C115.535 24.1437 115.231 24.0755 114.879 24.0755H112.931V26.871ZM112.931 23.0485H114.696C114.991 23.0485 115.257 22.9917 115.492 22.8781C115.731 22.7644 115.92 22.6053 116.059 22.4008C116.201 22.1934 116.272 21.9491 116.272 21.6678C116.272 21.307 116.146 21.0045 115.893 20.7602C115.64 20.5158 115.252 20.3937 114.73 20.3937H112.931V23.0485Z" fill="white"></path>
        <path d="M119.466 28.0002V19.2729H120.783V23.0656H125.134V19.2729H126.455V28.0002H125.134V24.1948H120.783V28.0002H119.466Z" fill="white"></path>
        <path d="M129.11 28.0002H127.712L130.853 19.2729H132.374L135.514 28.0002H134.117L131.649 20.8582H131.581L129.11 28.0002ZM129.344 24.5826H133.878V25.6906H129.344V24.5826Z" fill="white"></path>
        <path d="M142.962 28.0002H141.641V20.4022H139.988C139.556 20.4022 139.205 20.4676 138.935 20.5982C138.665 20.7261 138.468 20.9107 138.343 21.1522C138.218 21.3937 138.155 21.6792 138.155 22.0087C138.155 22.3354 138.218 22.6153 138.343 22.8482C138.468 23.0812 138.664 23.2587 138.931 23.3809C139.198 23.5031 139.546 23.5641 139.975 23.5641H142.212V24.6977H139.851C139.178 24.6977 138.617 24.5854 138.168 24.361C137.722 24.1366 137.387 23.8227 137.163 23.4193C136.941 23.013 136.83 22.5428 136.83 22.0087C136.83 21.4718 136.941 20.9988 137.163 20.5897C137.384 20.1778 137.718 19.8553 138.164 19.6224C138.613 19.3894 139.174 19.2729 139.847 19.2729H142.962V28.0002ZM138.654 24.0627H140.111L137.994 28.0002H136.498L138.654 24.0627Z" fill="white"></path>
        <path d="M45.6956 43.0002H44.2979L47.4385 34.273H48.9598L52.1004 43.0002H50.7027L48.2354 35.8582H48.1672L45.6956 43.0002ZM45.93 39.5826H50.464V40.6906H45.93V39.5826Z" fill="white"></path>
        <path d="M53.3607 43.0002V34.273H54.6775V38.0656H59.0283V34.273H60.3493V43.0002H59.0283V39.1948H54.6775V43.0002H53.3607Z" fill="white"></path>
        <path d="M63.0042 43.0002H61.6064L64.7471 34.273H66.2684L69.409 43.0002H68.0113L65.5439 35.8582H65.4758L63.0042 43.0002ZM63.2385 39.5826H67.7726V40.6906H63.2385V39.5826Z" fill="white"></path>
        <path d="M70.0088 43.0002V41.8582L70.2645 41.8497C70.6111 41.8411 70.8838 41.7232 71.0827 41.496C71.2844 41.2687 71.4307 40.9022 71.5216 40.3965C71.6153 39.8908 71.6778 39.2161 71.7091 38.3724L71.8582 34.273H77.3767V43.0002H76.077V35.3894H73.0983L72.9619 38.7218C72.925 39.6565 72.8312 40.442 72.6807 41.0783C72.5301 41.7119 72.2744 42.1906 71.9136 42.5144C71.5557 42.8383 71.0457 43.0002 70.3838 43.0002H70.0088Z" fill="white"></path>
        <path d="M79.2943 34.273H80.5983V40.682H80.6792L85.1196 34.273H86.3383V43.0002H85.0216V36.5997H84.9406L80.5088 43.0002H79.2943V34.273Z" fill="white"></path>
        <path d="M87.8287 35.4065V34.273H94.583V35.4065H91.86V43.0002H90.5475V35.4065H87.8287Z" fill="white"></path>
        <path d="M96.0756 34.273H97.3795V40.682H97.4605L101.901 34.273H103.12V43.0002H101.803V36.5997H101.722L97.29 43.0002H96.0756V34.273Z" fill="white"></path>
        <path d="M110.218 43.0002L107.286 39.0883H106.357V43.0002H105.04V34.273H106.357V37.959H106.788L110.086 34.273H111.692L108.202 38.1806L111.824 43.0002H110.218Z" fill="white"></path>
        <path d="M113.782 43.0002H112.384L115.524 34.273H117.046L120.186 43.0002H118.789L116.321 35.8582H116.253L113.782 43.0002ZM114.016 39.5826H118.55V40.6906H114.016V39.5826Z" fill="white"></path>
        <defs>
          <clipPath id="clip0_1070_7178">
            <rect width="36" height="42" fill="white" transform="translate(0 1)"></rect>
          </clipPath>
        </defs>
    </svg>
  );
}
