import { useCallback, useState, useEffect } from 'react';
import Button from '../../components/button';
import GeneralLayout from '../../components/general-layout';
import { Pagination } from '../../components/table/pagination';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { Form } from 'react-final-form';
import classes from './categories-handbook.module.sass';
import { SearchInput } from '../../components/input/search';
import { useDialog } from '../../providers/dialog.provider';
import { FormDialog } from './components/FormDialog';
import { useCrudEntity, CrudTable } from '../../components/table/CrudTable';
import { handbookAPIs } from '../../services';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { usePagination } from '../../components/table/usePagination';

export default function CategoriesHandbook() {
  const categoryCrudAdapter = useCrudEntity({
    readAll: (limit, page_num, query) => handbookAPIs.getHandbooks('categories', limit, page_num, query),
    read: (id) => handbookAPIs.getHandbook('categories', id),
    create: (payload) => handbookAPIs.addHandbook('categories', payload),
    edit: (id, payload) => handbookAPIs.editHandbook('categories', id, payload),
    delete: (id) =>
      handbookAPIs
        .deleteHandbook('categories', id)
        .then(() => categoryCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data))),
  });

  const [data, setData] = useState([]);
  const fetchData = useCallback(
    async (limit = 10, page_num, query = '') => {
      try {
        const res = await categoryCrudAdapter.readAll(limit, page_num, query);
        return res;
      } catch (err) {
        createErrorNotification('Что-то пошло не так');
        console.error(err);
      }
    },
    [categoryCrudAdapter]
  );
  const {
    data: paginatedData,
    currentPage,
    goToPage,
    total,
    isLoading,
    setPageSize,
    pageSize,
    handleChangeQuery
  } = usePagination(fetchData);
  useEffect(() => {
    setData(paginatedData);
  }, [paginatedData]);
  const dialog = useDialog();

  const columns = {
    name: {
      label: 'Имя',
    },
    standard_size: {
      label: 'Размер',
    },
    sub_categories: {
      label: 'Подкатегория',
      renderBodyCell: (value) => {
        return (
          <>
            {Array.isArray(value.sub_categories) ? (
              <p className='tw-line-clamp-2'>{value.sub_categories.join(', ')}</p>
            ) : (
              <p>{value.sub_categories}</p>
            )}
          </>
        );
      },
    },
  };

  const createCategory = useCallback(
    async (category) => {
      try {
        await categoryCrudAdapter
          .create(category)
          .then(() => categoryCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data)));
        createNotification('Категория создана', { type: 'success', hideProgressBar: true });
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      }
    },
    [categoryCrudAdapter]
  );

  const editCategory = useCallback(
    async (id, category) => {
      try {
        await categoryCrudAdapter
          .edit(id, category)
          .then(() => categoryCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data)));
        createNotification('Категория изменена', { type: 'success', hideProgressBar: true });
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      }
    },
    [categoryCrudAdapter]
  );

  const showCreateCategoryForm = useCallback(() => {
    dialog.open(
      <FormDialog
        title={'Добавление категории'}
        value={{
          name: '',
          standard_size: '',
          sub_categories: '',
        }}
        onSubmit={createCategory}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, createCategory]);

  const showEditCategoryForm = useCallback(
    ({ id: categoryId }) => {
      categoryCrudAdapter.read(categoryId).then((category) => {
        dialog.open(<FormDialog value={category} title={'Редактирование категории'} onSubmit={editCategory} />);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog, categoryCrudAdapter]
  );
  const askConfirmationDelete = useCallback(
    (category) => {
      dialog.open(
        <ConfirmModal
          value={category}
          title={`Вы уверены, что хотите удалить категорию ${category.name}?`}
          onAgree={categoryCrudAdapter.delete}
        />
      );
    },
    [dialog, categoryCrudAdapter]
  );

  const crudButtons = (entity) => {
    return (
      <div className="tw-flex tw-flex-row tw-border-b-0">
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => showEditCategoryForm(entity)}
        >
          <EditIcon />
        </Button>
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => askConfirmationDelete(entity)}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  };

  const handleLimitChange = (value) => {
    setPageSize(value);
    goToPage(1);
  };
  return (
    <GeneralLayout>
      <h1 className="tw-text-[2rem] tw-font-semibold tw-leading-[2.375rem] tw-text-[#191919] tw-mb-8">
        Справочник категорий
      </h1>
      <div className="tw-bg-white tw-py-[25px] tw-px-[24px] tw-rounded-lg">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                className={` tw-max-w-[312px] ${classes.searchInput}`}
                onSearchChange={handleChangeQuery}
                name="search"
                placeholder="Найти категорию"
                type="text"
                required
                size="small"
              />
            )}
          </Form>
          <Button variant="text" className="tw-p-2 tw-w-[fit-content]" onClick={() => showCreateCategoryForm()}>
            <AddIcon className="tw-mr-[4px]" />
            Добавить категорию
          </Button>
        </div>
        {!isLoading ? (
          <CrudTable rows={data} entityColumns={columns} crudButtons={crudButtons} />
        ) : (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
            <div
              class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            />
          </div>
        )}
      </div>
      <Pagination
        rowsPerPage={pageSize}
        count={total}
        page={currentPage}
        onPageChange={goToPage}
        onLimitChange={handleLimitChange}
      />
    </GeneralLayout>
  );
}
