import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GeneralLayout from '../../components/general-layout';
import Button from '../../components/button';
import Loader from '../../components/loader';
import classes from './saved.prices.module.sass';
import Input from '../../components/input';
import { Form } from 'react-final-form';
import { getArchivedFile, getArchivedProducts, getCategories } from '../../store/actions/organizations';
import ProductStatisticCard from '../../components/product-statistics-card';
import { ConfirmModal } from '../../components/confirm-modal';
import { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Select from 'react-select';

const monthButtons = [
  { label: '1 месяц', value: 1 },
  { label: '3 месяца', value: 3 },
  { label: '6 месяцев', value: 6 },
];

export default function ArchivedProductPage() {
  const savedSources = useSelector((state) => state.organizations.archivedProductList);
  const { categories } = useSelector((state) => state.organizations);
  const canLoadMoreArchivedProducts = useSelector((state) => state.organizations.canLoadMoreArchivedProducts);
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({
    query: '',
    periodMonth: 1,
    page_num: 1,
    page_size: 20,
    categories: '',
  });

  useEffect(() => {
    if (savedSources.length === 0) {
      setLoader(true);
      Promise.all([
        getArchivedProducts(
          filters.query,
          filters.periodMonth,
          {
            page_num: filters.page_num,
            page_size: filters.page_size,
          },
          filters.categories
        ),
        getCategories(),
      ]).then(() => setLoader(false));
    }
  }, []);

  const handleGetArchivedProducts = async (newFilters, refresh = false) => {
    try {
      setLoader(true);
      setFilters(newFilters);
      const { query, periodMonth } = newFilters;
      await getArchivedProducts(
        query,
        periodMonth,
        { page_num: newFilters.page_num, page_size: newFilters.page_size },
        newFilters.categories,
        refresh
      );
    } catch (e) {
    } finally {
      setLoader(false);
    }
  };

  const reFilter = () => {
    return handleGetArchivedProducts(filters, true);
  };

  const handleChange = (selectedOptions) => {
    setFilters((state) => ({ ...state, categories: selectedOptions.map((item) => item.value).toString() }));
  };

  const handleSubmit = (values) => {
    const query = values.query ?? '';
    const periodMonth = filters.periodMonth ?? 1;
    const newFilters = { query, periodMonth, page_num: 1, page_size: 20, categories: filters.categories };
    handleGetArchivedProducts(newFilters, true);
  };

  const handleChangePeriodMonth = (periodMonth) => {
    const newFilters = { ...filters, periodMonth, page_num: 1, page_size: 20 };
    handleGetArchivedProducts(newFilters, true);
  };

  const handleReloadSavedSource = () => {
    handleGetArchivedProducts({ ...filters, page_num: 1, page_size: 20 }, true);
  };

  const handleLoadMore = (isVisible) => {
    if (isVisible && canLoadMoreArchivedProducts && !loader && savedSources.length > 0) {
      const newFilters = {
        ...filters,
        page_num: filters.page_num + 1,
      };
      handleGetArchivedProducts(newFilters);
    }
  };

  const onDownload = () => {
    getArchivedFile(filters);
  };

  return (
    <GeneralLayout>
      <Container className={classes.container}>
        <div className="sm:tw-flex sm:tw-justify-between sm:tw-items-center tw-mb-4">
          <div className="tw-mb-4 sm:tw-mb-0">
            <h1 className="tw-text-2xl md:tw-text-3xl tw-text-gray-800 tw-font-bold">Архивные продукты </h1>
          </div>
        </div>

        <Form onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Input className={classes.calendarInput} name="query" placeholder="Введите наименование товара" />
              <Button className={classes.searchBtn} type="submit">
                Поиск
              </Button>
              <Button onClick={onDownload} secondary className={classes.exportBtn}>
                Экспорт
              </Button>
            </form>
          )}
        </Form>

        <div className={classes.selectMonth}>
          <div className={classes.title}>Вывести статистику за:</div>
          {monthButtons.map((button) => (
            <Button
              onClick={() => handleChangePeriodMonth(button.value)}
              secondary
              size="small"
              key={button.value}
              className={classes.button}
              disabled={filters.periodMonth === button.value}
            >
              {button.label}
            </Button>
          ))}
          <Select
            placeholder="Категория"
            onChange={handleChange}
            className={classes.select}
            isMulti
            closeMenuOnSelect={false}
            options={categories.map((cate) => ({ value: cate, label: cate }))}
          />
          <Button className={classes.button} secondary onClick={reFilter} size="small">
            Показать
          </Button>
        </div>
        {loader && <Loader />}
        {savedSources &&
          savedSources.map((product, i) => (
            <div className={classes.savedSources} key={i}>
              <ProductStatisticCard product={product} onReloadSavedSources={handleReloadSavedSource} />
            </div>
          ))}

        {canLoadMoreArchivedProducts && !loader && (
          <VisibilitySensor onChange={handleLoadMore}>
            <Loader className="mb-3" />
          </VisibilitySensor>
        )}
        <ConfirmModal title="Вы уверены, что хотите удалить этот сохраненный источник?" />
      </Container>
    </GeneralLayout>
  );
}
