import { request } from '../utils/request';

export const handbookAPIs = {
    getHandbooks: (endpoint, limit = 10, page_num = 1, query = '') => 
    request(`/api/admin/catalogs/${endpoint}?page_size=${limit}&page_num=${page_num}&query=${query}`, {
        method: 'GET',
    }),
    addHandbook: (endpoint, body) =>
    request(`/api/admin/catalogs/${endpoint}`, {
        method: 'POST',
        body
    }),
    deleteHandbook: (endpoint, id) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`,{
        method: 'DELETE'
    }),
    editHandbook: (endpoint, id, body) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
        method: 'PATCH',
        body
    }),
    getHandbook: (endpoint, id) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
        method: 'GET'
    }),
}