import { ReactComponent as SearchIcon } from '../../../icons/searchIcon.svg';
import { ReactComponent as MoreIcon } from '../../../icons/MoreIcon.svg';

export const MessageBodyHeader = ({ handleToggleSidebar, photo, hostName }) => {
  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-bg-white tw-px-4 sm:tw-px-6 md:tw-px-5 tw-h-16 tw-py-3">
        <div className="tw-flex tw-items-center">
          <button
            className="md:tw-hidden tw-text-gray-400 hover:tw-text-gray-500 tw-mr-4"
            onClick={handleToggleSidebar}
          >
            <span className="tw-sr-only">Close sidebar</span>
            <svg className="tw-w-6 tw-h-6 tw-fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          <div className="tw-flex tw--ml-px tw-gap-2 tw-items-center">
            <img
              className="tw-rounded-full tw-border-2 tw-border-white tw-box-content"
              src={photo || '/images/defaultLogo.png'}
              width="42"
              height="42"
              alt=""
            />

            <span
              className="tw-text-base tw-text-medium tw-text-[#191919]"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                overflowWrap: 'anywhere',
              }}
            >{`${hostName || ''}`}</span>
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-gap-4">
          <SearchIcon className="tw-hidden" />
          <MoreIcon className="tw-hidden" />
        </div>
      </div>
    </>
  );
};
