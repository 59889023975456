import moment from 'moment';

export const NewsCard = ({ log }) => {
  return (
    <>
      <div className="tw-flex tw-flex-col tw-bg-white tw-pt-6 tw-px-8 tw-pb-8 tw-rounded-lg">
        <p className="tw-text-[#4C4C4C] tw-leading-[18px] tw-text-sm tw-mb-[10px]">
          {moment(log?.date, 'DD-MM-YYYY').format('DD MMMM YYYY')}
        </p>
        <h2 className="tw-text-[#191919] tw-font-semibold tw-leading-[28px] tw-text-2xl tw-mb-[14px]">{log?.title}</h2>
        <p className='tw-text-[#191919] tw-leading-[22px]' style={{whiteSpace: 'break-spaces'}}>
            {log?.description}
        </p>
        <ul className='tw-text-[#191919] tw-leading-[22px] tw-list-disc'>
            {log?.list.length > 0 && log.list.map((item) => (
                <li key={item} className='tw-ml-5'>
                    {item}
                </li>
            ))}
        </ul>
      </div>
    </>
  );
};
