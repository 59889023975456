import moment from 'moment';
import Table from '../../table';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSortableData } from '../../../hooks/useSortableData';

export const ProjectResults = ({ data }) => {
  const { items, requestSort, sortingLabel } = useSortableData(data);
  const getProjectStatus = (value) => {
    switch (value?.report_status) {
      case 'finished':
        return (
          <div className="tw-flex tw-flex-row tw-justify-start ">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#EEFCEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#49CA4E] tw-text-xs'
              }
            >
              Завершено
            </div>
          </div>
        );
      case 'processing':
      case 'initial':
        return (
          <div className="tw-flex tw-flex-row tw-justify-start ">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
              }
            >
              В процессе
            </div>
          </div>
        );
      case 'failed':
        return (
          <div className="tw-flex tw-flex-row tw-justify-start ">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#FFEAEA] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FF4343] tw-text-xs'
              }
            >
              Прервано
            </div>
          </div>
        );
      default:
        return (
          <div className="tw-flex tw-flex-row tw-justify-start ">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#EEEEEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#7F7F7F] tw-text-xs'
              }
            >
              Статус
            </div>
          </div>
        );
    }
  };
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
  });
  const columns = {
    name: {
      label: 'Название',
      renderBodyCell: (value) => {
        return (
          <Link to={`/projects/${value.id}`} className="tw-text-[#0844B4] tw-text-sm">
            {value.name}
          </Link>
        );
      },
    },
    created_at: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('created_at')}>
            {sortingLabel('Дата создания', 'created_at')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return <p className="tw-text-sm">{moment(value.created_at).format('DD.MM.YYYY')}</p>;
      },
    },

    price: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('price')}>
            {sortingLabel('Цена', 'price')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return (
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
            {!value.price
              ? '-'
              : numberFormatter
                  .format(value.price ?? 0)
                  .replace('₽', '')
                  .trim()}
            <span className="tw-ml-1">₽</span>
          </p>
        );
      },
    },
    report_status: {
      label: 'Статус',
      renderBodyCell: getProjectStatus,
    },
    product_count: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('product_count')}>
            {sortingLabel('Позиции', 'product_count')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return <p>{value.product_count}</p>;
      },
    },
  };
  //  max height of table content is 651px
  // ! need to calculate max height of table content in %
  return (
    <div className='tw-overflow-y-auto'>
      <Table rows={items} columns={columns} />
    </div>
  );
};
