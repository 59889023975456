import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { Link } from 'react-router-dom';
import Button from '../../../button';
import { Form } from 'react-final-form';
import Input from '../../../input';
import { createErrorNotification, createNotification } from '../../../../utils/notifications';
import { productsAPIs } from '../../../../services';
import { useSelector } from 'react-redux';

export const CorrectingPrice = ({ item, onSubmit }) => {
  const dialog = useDialog();
  const { project_id } = useSelector((state) => state.organizations.selectedReport);
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 2,
  });
  const organization = useSelector((state) => state.organizations.selected)
  
  const handleSubmit = async (price) => {
    const body = {
      correct_price: Number(price),
      found_price: item.price,
      name: item.title,
      original_price: item.price,
      project_id: project_id,
      url: item.url,
      organization_id: organization?.id
    };
    await productsAPIs
      .addCorrectPriceRequest(body)
      .then(() => {
        createNotification('Жалоба отправлена');
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при корректировании цены');
      });
  };
  return (
    <Dialog open={dialog.visibility}>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-min-w-[400px]">
        <p className="tw-text-[#191919] tw-font-semibold tw-leading-5 tw-mb-[26px]">Некорректная цена товара</p>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-mb-[22px]">
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">Заявленная цена</p>
          <p className="tw-text-[#191919] tw-leading-5 tw-font-medium">
            {numberFormatter
              .format(item?.price ?? 0)
              .replace('₽', '')
              .trim()}{' '}
            <span className="tw-ml-1">₽</span>
          </p>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-mb-2">
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">Найденная в источнике цена</p>
          <p className="tw-text-[#191919] tw-leading-5 tw-font-medium">
            {numberFormatter
              .format(item?.price ?? 0)
              .replace('₽', '')
              .trim()}{' '}
            <span className="tw-ml-1">₽</span>
          </p>
        </div>
        <a href={item?.url} className="tw-text-[#0844B4] tw-leading-[18px] tw-max-w-[389px] tw-truncate tw-mb-[22px] tw-cursor-pointer">
          {item.url}
        </a>
        <Form
          initialValues={{ price: '' }}
          onSubmit={({ price }) => {
            handleSubmit(price);
          }}
          render={({ handleSubmit, submitting, form, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-flex tw-flex-col ">
                <p className="tw-text-[#191919] tw-leading-[18px] tw-mb-[10px]">Цена на сайте</p>
                <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mb-14">
                  <Input form={form} name="price" type="number" required placeholder={'Цена в рублях'} />
                </div>
                <DialogActions className="tw-gap-6 tw-p-0">
                  <Button
                    variant="text"
                    className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent"
                    disabled={submitting}
                    onClick={() => dialog.close()}
                  >
                    Закрыть
                  </Button>
                  <Button
                    type="submit"
                    className="tw-w-fit tw-rounded-lg tw-font-semibold tw-text-sm"
                    disabled={!values['price'] || submitting}
                  >
                    Отправить
                  </Button>
                </DialogActions>
              </div>
            </form>
          )}
        ></Form>
      </div>
    </Dialog>
  );
};
