import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classes from './chart.module.sass';

export default function Chart({ title, data, xLabels, xAxisLabel, yAxisLabel }) {
  return (
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            backgroundColor: null,
          },
          title: { text: title },
          xAxis: {
            categories: xLabels,
            title: {
              text: xAxisLabel,
            },
          },
          yAxis: {
            title: {
              text: yAxisLabel,
            },
          },
          series: [
            {
              type: 'column',
              data,
              label: {
                enabled: false,
              },
            },
          ],
          plotOptions: {
            column: {
              maxPointWidth: 50,
            },
          },
          legend: { enabled: false },
          tooltip: {
            formatter: function () {
              return `<b>${this.y}</b>`;
            },
          },
          credits: { enabled: false },
        }}
      />
    </div>
  );
}
