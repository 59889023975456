import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AllCategories from '../../components/analytics/AllCategories';
import TopSources from '../../components/analytics/TopSources';
import GeneralLayout from '../../components/general-layout';
import Loader from '../../components/loader';
import RangePicker from '../../components/range-picker';
import { statisticsAPIs } from '../../services';
import { AnalyticsSearchBar } from '../../components/analytics-searchbar/AnalyticsSearchBar';

export default function GeneralAnalyticsPage() {
  const organization = useSelector((state) => state.organizations.selected);
  const [categories, setCategories] = useState()
  const [data, setData] = useState(null);
  //const [hosts, setHosts] = useState()
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month').startOf('hour').unix() * 1000,
    till: moment().startOf('hour').unix() * 1000,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const params = queryString.stringify({
        ...queryParams,
        organization_id: organization.id,
      });
      const response = await statisticsAPIs.getGeneralStatistics(params);
      await statisticsAPIs.getCategoriesStatistics().then((res) => setCategories(res.categories))
      setData(response);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (organization) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, queryParams]);
  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-w-full">
        <h1 className="tw-text-2xl md:tw-text-3xl tw-text-gray-800 tw-font-bold tw-mb-8">Аналитика </h1>
        <div className='tw-flex tw-flex-row tw-mb-8'>
          <AnalyticsSearchBar formValues={{query: ''}} categories={categories && categories}/>
        </div>
        <div className="tw-flex tw-flex-row tw-self-start">
          <RangePicker queryParams={queryParams} setQueryParams={setQueryParams} />
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="tw-flex tw-flex-col tw-gap-[16px] tw-mt-6">
            <AllCategories
              categories={(data?.categories?.categories || []).sort((a, b) => b.count_products - a.count_products)}
              total={data?.categories?.total || 0}
            />
            <TopSources hosts={data?.top_hosts || []} queryParams={queryParams} />
          </div>
        )}
      </div>
    </GeneralLayout>
  );
}
