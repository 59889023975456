import * as constants from "../constants/personalArea";
import { dispatch } from "../index";
import { request } from "../../utils/request";
import * as organizationsConsts from "../constants/organizations";
import { FORM_ERROR } from "final-form";

export const postOrganization = async ({ name }) => {
  try {
    const organization = await request("/api/admin/organizations", {
      method: "POST",
      body: { name },
    });
    dispatch({
      type: organizationsConsts.ORGANIZATIONS__ADD_ORGANIZATION,
      organization,
    });
  } catch (error) {
    return { [FORM_ERROR]: "Такая организация уже существует" };
  }
};

export const putOrganization = async ({ id, name }) => {
  try {
    const { organization } = await request(`/api/organizations/${id}`, {
      method: "PUT",
      body: { name },
    });
    dispatch({
      type: organizationsConsts.ORGANIZATIONS__UPDATE_ORGANIZATION,
      organization,
    });
  } catch (error) {
    return { [FORM_ERROR]: "Такая организация уже существует" };
  }
};

export const deleteOrganization = async (id) => {
  try {
    await request(`/api/admin/organizations/${id}`, {
      method: "DELETE",
    });
    dispatch({
      type: organizationsConsts.ORGANIZATIONS__DELETE_ORGANIZATION,
      id,
    });
  } catch (error) {
    return false;
  }
};

export const getUsers = async (limit = 10, page_num = 1, query = '') => {
  try {
    const { data } = await request(`/api/admin/users?page_size=${limit}&page_num=${page_num}&query=${query}`);
    dispatch({ type: constants.PERSONAL_AREA__GET_USERS, users: data });
  } catch (error) {}
};

export const putUser = async (user) => {
  try {
    // await request(`/api/users/${user.id}`, {
    await request(`/api/admin/users`, {
      method: "PATCH",
      body: user,
    });
    dispatch({ type: constants.PERSONAL_AREA__UPDATE_USER, user });
  } catch (error) {
    return false;
  }
};

export const deleteUser = async (id) => {
  try {
    await request(`/api/admin/users/${id}`, {
      method: "DELETE",
    });
    dispatch({ type: constants.PERSONAL_AREA__DELETE_USER, id });
  } catch (error) {}
};

export const addUser = async ({ email, full_name, password, role }) => {
  
  try {
    const user = await request("/api/admin/users", {
      method: "POST",
      body: { full_name, email, password, role },
    });
    dispatch({ type: constants.PERSONAL_AREA__ADD_USER, user });
  } catch (error) {
    return false;
  }
};

export const getProviders = async () => {
  try {
    const  providers  = await request("/api/admin/providers?limit=10&skip=0");
    console.log('getProviders', providers);
    dispatch({ type: constants.PERSONAL_AREA__GET_PROVIDERS, providers });
    dispatch({ type: constants.PERSONALAREA__FILTER_LIST, providers });
  } catch (error) {}
};

export const addProvider = async (values) => {
  try {
    const  provider  = await request("/api/admin/providers", {
      method: "POST",
      body: values,
    });
    dispatch({ type: constants.PERSONAL_AREA__ADD_PROVIDER, provider });
  } catch (error) {
    console.log('addProvider error', error);
    return { [FORM_ERROR]: "Такой провайдер уже существует" };
  }
};

export const deleteProvider = async (id) => {
  try {
    await request(`/api/admin/providers/${id}`, {
      method: "DELETE",
    });
    dispatch({ type: constants.PERSONAL_AREA__DELETE_PROVIDER, id });
  } catch (error) {}
};

export const filterProviders = (text) => {
  dispatch({ type: constants.PERSONALAREA__FILTER_PROVIDERS, text });
};

export const getAccesses = async (id) => {
  try {
    const { organization_accesses: accesses } = await request(
      `/api/admin/accesses?organization_id=${id}`
    );
    dispatch({ type: constants.PERSONAL_AREA__GET_ACCESSES, accesses });
  } catch (error) {}
};

export const createAccess = async (values) => {
  try {
    const access = await request(`/api/admin/accesses`, {
      method: "POST",
      body: values,
    });
    dispatch({ type: constants.PERSONAL_AREA__ADD_ACCESS, access });
  } catch (error) {}
};

export const updateAccess = async (values) => {
  try {
    const access = await request(`/api/admin/accesses`, {
      method: "PATCH",
      body: values,
    });
    dispatch({ type: constants.PERSONAL_AREA__UPDATE_ACCESS, access });
  } catch (error) {}
};

export const deleteAccess = async (id) => {
  try {
    await request(`/api/admin/accesses/${id}`, { method: "DELETE" });
    dispatch({ type: constants.PERSONAL_AREA__DELETE_ACCESS, id });
  } catch (error) {}
};

export const cleanAccesses = () => {
  dispatch({ type: constants.PERSONAL_AREA__CLEAN_ACCESS });
};

export const setProjectsListShow = () => {
  dispatch({ type: constants.PERSONAL_AREA__SHOW_PROVIDERS });
};
