import moment from 'moment';
import { useEffect, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrowRightIcon.svg';
import EmptyImageIcon from '../../icons/empty-image';
import { LineChart } from '../charts';
import { Collapse } from '@mui/material';
import Button from '../button';
import { useHistory, useParams } from 'react-router-dom';

export default function ProductStatisticCard({ product, index }) {
  const [showProductSources, setShowProductSources] = useState(false);
  const firstPic = product.image;
  const history = useHistory()
  const { id: categoryId } = useParams()
  const id = `product-category-chart-${index}`;
  let chart;

  const labels = [];
  const data = [];
  product?.change_points?.forEach((p) => {
    labels.push(moment.unix(p[0]).format('MM-DD-YY'));
    data.push(Math.round(p[1]));
  });

  useEffect(() => {
    if (labels && data && showProductSources) {
      const chartInstance = LineChart({ id, labels, data });
      if (chartInstance) {
        chart = chartInstance.chart;
        chart.update();
      }
    }
    return () => {
      if (chart) chart.destroy();
    };
  }, [product, showProductSources]);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-lg">
        <div className="tw-flex tw-flex-row tw-items-start tw-w-full tw-justify-between">
          <div className="tw-flex tw-flex-row tw-pt-4 tw-pl-4 tw-gap-[23px] tw-w-full">
            {firstPic ? (
              <img src={firstPic} alt="" className="tw-w-[120px] tw-h-[120px] tw-aspect-[4/3] tw-object-contain" />
            ) : (
              <EmptyImageIcon />
            )}
            <h3 className="tw-text-[#191919] tw-font-semibold tw-text-left tw-cursor-pointer" onClick={() => history.push(`/statistics/${categoryId}/${product.id}`)}>{product.name}</h3>
          </div>
          <div className="tw-flex tw-flex-row tw-gap-6">
            <div className="tw-bg-[#D9D9D9] tw-w-[1px] tw-h-[226px]"></div>
            <div className="tw-flex tw-flex-col tw-items-start tw-min-w-[220px] tw-pt-6 tw-pr-6 tw-pb-[17px]">
              <div className="tw-flex tw-flex-col tw-mb-[15px]">
                <p className="tw-text-[#666666] tw-text-sm tw-font-medium">Средняя цена за товар</p>
                {product?.avg_price ? (
                  <p className="tw-text-[#191919] tw-font-semibold tw-text-2xl">
                    {product?.avg_price} <span className="tw-text-[#191919] tw-font-semibold tw-text-base">₽</span>
                  </p>
                ) : (
                  <p className="tw-text-[#191919] tw-font-semibold tw-text-base">Нет данных</p>
                )}
              </div>
              <div className="tw-flex tw-flex-col tw-mb-[18px]">
                <p className="tw-text-[#666666] tw-text-sm tw-font-medium">Минимальная цена за товар</p>
                {product?.min_price ? (
                  <p className="tw-text-[#191919] tw-font-semibold tw-text-2xl">
                    {product?.min_price} <span className="tw-text-[#191919] tw-font-semibold tw-text-base">₽</span>
                  </p>
                ) : (
                  <p className="tw-text-[#191919] tw-font-semibold tw-text-base">Нет данных</p>
                )}
              </div>
              <Button
                variant="text"
                className="tw-p-0 hover:tw-bg-transparent tw-text-sm tw-font-medium"
                onClick={() => setShowProductSources(!showProductSources)}
              >
                Посмотреть график цен{' '}
                <ArrowDownIcon
                  className={`${
                    showProductSources ? 'tw-rotate-90' : 'tw-rotate-[270deg]'
                  } tw-transition-transform tw-fill-[#0844B4]`}
                />
              </Button>
            </div>
          </div>
        </div>
        <Collapse in={showProductSources}>
          <div className={`tw-m-auto tw-w-full tw-pr-6`}>
            <canvas id={id} width="100%" height="300"></canvas>
          </div>
        </Collapse>
      </div>
    </>
  );
}
