import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import ChevronDownIcon from '../../icons/chevron-down';
import { blockHost, unblockHost, getBlockedHosts } from '../../store/actions/organizations';
import Button from '../button';
import Input from '../input';
import Loader from '../loader';
import classes from './blocked-hosts-modal.module.sass';

export default function BlockedHostsModal({ onHide }) {
  const [blockedHosts, setBlockedHosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    getBlockedHosts().then((hosts) => {
      setBlockedHosts(hosts);
      setIsLoading(false);
    });
  }, []);
  const onSubmit = async ({ host }, form) => {
    return blockHost(host).then((error) => {
      if (error) {
        if (error.statusCode === 400) {
          return { host: 'Невалидный источник' };
        }
        switch (error.statusCode) {
          case 400:
            return { host: 'Невалидный источник' };
          case 409:
            return { host: 'Такой источник уже существует' };
          default:
            return { host: 'Возникла ошибка' };
        }
      } else {
        setBlockedHosts([...blockedHosts, host]);
        form.restart();
      }
    });
  };
  const onUnblock = (host) => {
    setBlockedHosts(blockedHosts.filter((h) => h !== host));
    unblockHost(host);
  };
  return (
    <Modal show onHide={onHide} className={classes.modal}>
      <div className={classes.root}>
        <h3 className={classes.title}>Заблокированные источники</h3>
        <div className={classes.list}>
          {isLoading && <Loader />}
          {!isLoading && blockedHosts.length === 0 && (
            <div>На текущий момент заблокированных хостов нет :)</div>
          )}
          {blockedHosts.map((host) => {
            return (
              <div className={classes.host} key={host}>
                <div className={classes.field} title={host}>
                  {host}
                </div>
                <Button onClick={() => onUnblock(host)} className={classes.button}>
                  Удалить
                </Button>
              </div>
            );
          })}
        </div>
        <div className={classes.actions}>
          <Button className={classes.ok} onClick={() => setShowForm(!showForm)}>
            {showForm ? (
              <span>
                <ChevronDownIcon /> Скрыть
              </span>
            ) : (
              '+ Добавить'
            )}
          </Button>
        </div>
        {showForm && (
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Input
                  name="host"
                  className={classes.input}
                  required
                  placeholder="website.example"
                />
                <Button type="submit" className={classes.button}>
                  Заблокировать
                </Button>
              </form>
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
}
