import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useInfiniteScroll = (fetchFn, key = 'data', initialPage = 1, limit = 10, initialQuery = '') => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(initialPage);
  const [query, setQuery] = useState(initialQuery);
  const [hasMore, setHasMore] = useState(false);
  const [allData, setAllData] = useState([]);

  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    setData([]);
    setAllData([]);
    setPage(1);
  }, [query]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      setError(false);
      await fetchFn(page, limit, query, signal)
        .then((res) => {
          setAllData(res);
          setData((prev) => {
            if (res !== undefined) {
              return [...new Set([...prev, ...res[key]])];
            } else return [];
          });
          setHasMore(res[key].length === limit);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error(err);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getData();

    return () => {
      controller.abort();
    };
  }, [page, query, fetchFn]);

  const handleChangePage = (value) => {
    setPage(1);
  };

  const goToNextPage = () => {
    setPage(prev => prev + 1)
  }

  const handleChangeQuery = useCallback(
    debounce((value) => {
      setQuery(value);
    }, 500),
    []
  );

  return { handleChangePage, isLoading, error, data, allData, handleChangeQuery, hasMore, query, goToNextPage };
};
