import { request } from '../utils/request';

export const supportAPIs = {
  changeRequestStatus: (id, body) =>
    request(`/api/admin/tech_supports/technical_support/${id}`, {
      method: 'PATCH',
      body,
    }),
  getProjects: (organization_id, limit, page_num, query, signal) =>
    request(
      `/api/organizations/${organization_id}/projects?page_size=${limit}&page_num=${page_num}&query=${query}`,
      {
        method: 'GET',
      },
      signal
    ),
  getReport: (start_date, end_date) =>
    request(`/api/admin/tech_supports/technical_support/export_file?start_date=${start_date}&end_date=${end_date}`, {
      method: 'GET',
    }),
  sendAnswerToTicket: (body) =>
    request('/api/admin/tech_supports/technical_support/answer', {
      method: 'POST',
      body
    })
};
