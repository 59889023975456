import { Dialog, DialogActions } from '@mui/material';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { Form } from 'react-final-form';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback, useState, useEffect } from 'react';
import { createErrorNotification } from '../../../utils/notifications';
import { useSelector } from 'react-redux';

export const ProjectsFormModal = ({ title, onSubmit, onReject, value }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);
  const [error, setError] = useState(false);
  const organizationId = useSelector((store) => store.organizations.selected.id);
  useEffect(() => {
    if (JSON.stringify(formValue) !== JSON.stringify(value)) {
      setFormValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFormSubmit = useCallback(
    async (payload) => {
      const { id, name } = payload;
      const body = {
        name: name,
        organization_id: organizationId,
      };
      setError(null);
      // onSubmit &&
      //   (!id
      //     ? await onSubmit(body).catch((err) => {
      //         console.error(err);
      //         if (err.statusCode === 409) {
      //           setError(true);
      //         } else if (err.message === 'name is too short') {
      //           createErrorNotification('Название проекта должно содержать не менее ');
      //         } else createErrorNotification('Ошибка при создании проекта');
      //       })
      //     : await onSubmit(id, payload).catch((err) => {
      //         console.error(err);
      //         if (err.statusCode === 409) {
      //           setError(true);
      //         } else createErrorNotification('Ошибка при редактировании проекта');
      //       }));
      if (onSubmit) {
        try {
          if (!id) {
            await onSubmit(body);
          } else {
            await onSubmit(id, payload);
          }
        } catch (err) {
          console.error(err);
          if (err.statusCode === 409) {
            setError(true);
          } else {
            if (err.message === 'name is too short') {
              createErrorNotification('Название проекта должно содержать не менее 5 символов');
            } else {
              createErrorNotification('Ошибка при ' + (id ? 'редактировании' : 'создании') + ' проекта');
            }
          }
        }
      }
    },
    [onSubmit, organizationId]
  );

  const handleFormReject = useCallback(() => {
    onReject && onReject();
    dialog.close();
  }, [onReject, dialog]);

  const validate = (values) => {
    if (values?.name?.length === 0) return
    const errors = {}
    if (values?.name?.length < 5) {
      errors.name = 'Название должно содержать не менее 5 символов'
    }
    return errors
  }

  return (
    <Dialog open={dialog.visibility}>
      <Form
        validate={validate}
        initialValues={formValue}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, values, submitError, submitting, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-xl">
              <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
              <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-mb-14 md:tw-w-[440px]">
                <div className="tw-flex tw-flex-col tw-gap-1">
                  <Input
                    form={form}
                    placeholder="Название проекта"
                    value={values['name']}
                    name="name"
                    type="text"
                    required
                  />
                  {error && (
                    <p className="tw-text-[#FF4343] tw-leading-4 tw-text-xs tw-font-medium tw-pl-4">
                      Такое название проекта уже используется
                    </p>
                  )}
                </div>
              </div>

              <DialogActions className="tw-gap-[18px]">
                <Button
                  className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent hover:tw-text-[#7F7F7F]"
                  variant="text"
                  disabled={submitting}
                  onClick={handleFormReject}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={submitting && invalid && pristine}
                  type="submit"
                >
                  Добавить
                </Button>
              </DialogActions>
              {submitError && createErrorNotification(submitError)}
            </div>
          </form>
        )}
      ></Form>
    </Dialog>
  );
};
