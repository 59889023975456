import { Checkbox, Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import { useState } from 'react';
import Button from '../../../button';

export const AddToArchive = ({ suppliers }) => {
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const dialog = useDialog();
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 2,
  });
  const getUrlDomen = (url) => {
    try {
      const path = new URL(url);
      return path.hostname;
    } catch {
      return null;
    }
  };
  const handleCheckbox = (checked, item) => {
    if (checked) {
        const newSelectedSuppliers = [...selectedSuppliers, item]
        setSelectedSuppliers(newSelectedSuppliers)
    }
    else {
        const newSelectedSuppliers = selectedSuppliers.filter((selectedSupplier) => selectedSupplier.id !== item.id)
        setSelectedSuppliers(newSelectedSuppliers)
    }
  };
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '60%',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-p-6 tw-relative tw-w-full">
        <CloseIcon className="tw-absolute tw-w-6 tw-h-6 tw-top-[14px] tw-right-[14px] tw-cursor-pointer" onClick={() => dialog.close()} />
        <p className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-normal tw-mb-4">Добавление в архив</p>
        <p className="tw-text-[#191919] tw-text-sm tw-leading-normal tw-mb-8">
          Текст, который объясняет, что делает пользователь и зачем
        </p>
        <div className="tw-flex tw-flex-col tw-divide-y tw-divide-[#DDDDDD] tw-max-h-[403px] tw-overflow-y-auto tw-w-full">
          {suppliers.map((supplier) => (
            <div className="tw-flex tw-flex-row tw-items-center tw-py-6 tw-pr-2">
              <Checkbox className="tw-mr-[20px]" onChange={(event) => handleCheckbox(event.target.checked, supplier)}/>
              <img
                src={supplier?.pics[0]}
                className="tw-w-16 tw-h-16 tw-rounded-[4px] tw-aspect-square"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/images/emptyProduct.png';
                }}
                alt=""
              />
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-[5px]">
                <p className="tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-medium">
                  {getUrlDomen(supplier?.url)}
                </p>
                <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-max-w-[505px] tw-truncate">
                  {supplier?.title}
                </p>
              </div>
              <p className="tw-text-[#191919] tw-leading-normal tw-font-semibold tw-ml-auto">
                {!supplier.price
                  ? '-'
                  : numberFormatter
                      .format(supplier.price ?? 0)
                      .replace('₽', '')
                      .trim()}
                <span className="tw-ml-1 tw-text-xs">₽</span>
              </p>
            </div>
          ))}
        </div>
        <DialogActions className='tw-mt-12 tw-flex tw-flex-row tw-justify-start'>
          <Button disabled={selectedSuppliers.length === 0} className='tw-w-fit'>Архивировать</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
