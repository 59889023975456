import Button from '../../button';

export const GovermnetSourcesItem = ({ item }) => {
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 2,
  });
  const getUrlDomen = (url) => {
    try {
      const path = new URL(url);
      return path.hostname;
    } catch {
      return null;
    }
  };
  return (
    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-[18px] tw-relative">
      <div className="tw-flex tw-flex-row tw-gap-[14px] tw-items-start">
        <img className="tw-w-16 tw-h-16 tw-rounded-[4px]" src={item?.pics[0]} alt="" />
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-[5px">
          <p className="tw-text-[#0844B4] tw-text-sm tw-font-medium tw-leading-[18px]">{getUrlDomen(item?.url)}</p>
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">{item?.title}</p>
        </div>
      </div>
      <p className="tw-text-[#191919] tw-font-semibold tw-leading-5 tw-mr-[5%]">
        {numberFormatter
          .format(item?.price ?? 0)
          .replace('₽', '')
          .trim()}{' '}
        <span className="tw-text-xs">₽</span>
      </p>
      <Button
        variant="text"
        className="tw-absolute tw-top-[18px] tw-right-0 tw-p-0 hover:tw-bg-transparent tw-w-fit tw-h-fit"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M5.19645 13.1998C4.86548 13.1998 4.58333 13.082 4.35 12.8463C4.11667 12.6106 4 12.3272 4 11.9963C4 11.6653 4.11785 11.3831 4.35355 11.1498C4.58923 10.9165 4.87257 10.7998 5.20355 10.7998C5.53452 10.7998 5.81667 10.9177 6.05 11.1534C6.28333 11.389 6.4 11.6724 6.4 12.0034C6.4 12.3343 6.28215 12.6165 6.04645 12.8498C5.81077 13.0831 5.52743 13.1998 5.19645 13.1998ZM11.9964 13.1998C11.6655 13.1998 11.3833 13.082 11.15 12.8463C10.9167 12.6106 10.8 12.3272 10.8 11.9963C10.8 11.6653 10.9179 11.3831 11.1536 11.1498C11.3892 10.9165 11.6726 10.7998 12.0036 10.7998C12.3345 10.7998 12.6167 10.9177 12.85 11.1534C13.0833 11.389 13.2 11.6724 13.2 12.0034C13.2 12.3343 13.0821 12.6165 12.8464 12.8498C12.6108 13.0831 12.3274 13.1998 11.9964 13.1998ZM18.7964 13.1998C18.4655 13.1998 18.1833 13.082 17.95 12.8463C17.7167 12.6106 17.6 12.3272 17.6 11.9963C17.6 11.6653 17.7179 11.3831 17.9536 11.1498C18.1892 10.9165 18.4726 10.7998 18.8036 10.7998C19.1345 10.7998 19.4167 10.9177 19.65 11.1534C19.8833 11.389 20 11.6724 20 12.0034C20 12.3343 19.8821 12.6165 19.6464 12.8498C19.4108 13.0831 19.1274 13.1998 18.7964 13.1998Z"
            fill="#666666"
          />
        </svg>
      </Button>
    </div>
  );
};
