import { request } from '../utils/request';

export const productsAPIs = {
  addProduct: (reportId, body) =>
    request(`/api/reports/${reportId}/products`, {
      method: 'POST',
      body,
    }),
  deleteProduct: (reportId, productId) =>
    request(`/api/reports/${reportId}/products/${productId}`, {
      method: 'DELETE',
    }),
  addCorrectPriceRequest: (body) =>
    request(`/api/admin/product_requests`, {
      method: 'POST',
      body,
    }),
  reloadProduct: (report_id, product_id) =>
    request(`/api/reports/restart/${report_id}/products/${product_id}`, {
      method: 'PATCH'
    })
};
