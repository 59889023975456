import { request } from '../utils/request';

export const adminAPIs = {
  addUser: (body) =>
    request('/api/admin/users', {
      method: 'POST',
      body: body,
    }),
  editUser: (body) =>
    request('/api/admin/users', {
      method: 'PATCH',
      body: body,
    }),
  deleteUser: (id) =>
    request(`/api/admin/users/${id}`, {
      method: 'DELETE',
    }),
  getUsers: (limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/users?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getOrganization: (limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/organizations?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  addOrganization: (name, inn) =>
    request('/api/admin/organizations', {
      method: 'POST',
      body: { name, inn },
    }),
  deleteOrganization: (id) =>
    request(`/api/admin/organizations/${id}`, {
      method: 'DELETE',
    }),
  getOrganizationAccesses: (id) =>
    request(`/api/admin/accesses?organization_id=${id}`, {
      method: 'GET',
    }),
  deleteAccess: (id) =>
    request(`/api/admin/accesses/${id}`, {
      method: 'DELETE',
    }),
  createAccess: (body) =>
    request(`/api/admin/accesses`, {
      method: 'POST',
      body: body,
    }),
  updateAccess: (body) =>
    request(`/api/admin/accesses`, {
      method: 'PATCH',
      body: body,
    }),
  getPriceRequests: (page_size, page_num, query) =>
    request(`/api/admin/product_requests?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getPriceRequest: (id) =>
    request(`/api/admin/product_requests/${id}`, {
      method: 'GET',
    }),
  getSupportRequests: (page_size, page_num, query) =>
    request(`/api/admin/tech_supports/technical_support?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getSupportRequest: (id) =>
    request(`/api/admin/tech_supports/technical_support/${id}`, {
      method: 'GET',
    }),
  addSupportRequest: (body) =>
    request('/api/admin/tech_supports/technical_support', {
      method: 'POST',
      body: body,
    }),
  loginAs: (id) =>
    request('/api/login/login_as', {
      method: 'POST',
      body: {
        user_id: id,
      },
    }),
  getTechStatus: () => request('/api/admin/tech_supports/technical_support/get_status_problem', { method: 'GET' }),
  setTechStatus: (status) =>
    request('/api/admin/tech_supports/technical_support/set_status_problem', {
      method: 'POST',
      body: {
        status: status,
      },
    }),
};
