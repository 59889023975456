import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import classes from '../products.module.sass';
import { createErrorNotification } from '../../../../utils/notifications';
import { SelectMUI } from '../../../../components/selectMUI';

export const AddUser = ({ title, onSubmit, value }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);
  useEffect(() => {
    if (formValue?.id !== value?.id) {
      setFormValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const roles = {
    Админ: 'admin',
    Юзер: 'user',
  };
  const handleFormSubmit = useCallback(
    async (payload) => {
      const { id, full_name, email, password, role } = payload;

      const body = {
        ...(id ? { id: id } : null),
        full_name: full_name,
        email: email,
        password: password || '',
        role: roles[role] || 'user',
      };

      try {
        onSubmit && (await onSubmit(body));
      } catch (err) {
        console.error(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog, onSubmit]
  );
  return (
    <Dialog open={dialog.visibility} className={classes.select}>
      <div onClick={(e) => e.stopPropagation()}>
        <Form
          initialValues={formValue}
          onSubmit={handleFormSubmit}
          render={({ handleSubmit, values, submitError, form, invalid, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-min-w-[440px]">
                <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">{title}</p>
                <div className="tw-flex tw-flex-col tw-gap-6">
                  <div className="tw-flex tw-flex-col tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">ФИО</p>
                    <Input form={form} value={values['full_name']} name="full_name" type="text" required />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Email</p>
                    <Input form={form} value={values['email']} name="email" type="text" required />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Пароль</p>
                    <Input form={form} value={values['password']} name="password" type="text" required={!value?.id} />
                  </div>
                  <div className={`tw-flex tw-flex-col tw-gap-[10px] ${classes.select}`}>
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Роль</p>
                    <Field form={form} options={['Админ', 'Юзер']} name="role" type="text" required>
                      {(props) => (
                        <SelectMUI
                          {...props}
                          sx={{
                            '& .Mui-focused': {
                              fieldset: {
                                borderColor: '#D5D5D5 !important',
                                borderWidth: '1px !important',
                              },
                            },
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {/* <div className="tw-flex tw-flex-col tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Дневной лимит</p>
                    <Input form={form} value={values['max_scan_product_day']} name="max_scan_product_day" type="number" />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Месячный лимит</p>
                    <Input form={form} value={values['max_scan_product_month']} name="max_scan_product_month" type="number" />
                  </div> */}
                </div>
                <DialogActions className="tw-gap-6 tw-mt-14">
                  <Button
                    className="tw-w-fit tw-p-0 tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
                    variant="text"
                    disabled={submitting}
                    onClick={() => dialog.close()}
                  >
                    Отменить
                  </Button>
                  <Button
                    className="tw-text-sm tw-w-fit tw-rounded-lg tw-font-semibold"
                    disabled={submitting || invalid || pristine}
                    type="submit"
                  >
                    {value?.id ? 'Сохранить' : 'Создать'}
                  </Button>
                </DialogActions>
                {submitError && createErrorNotification(submitError)}
              </div>
            </form>
          )}
        />
      </div>
    </Dialog>
  );
};
