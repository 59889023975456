import { CircularProgress, Dialog, TextField } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import Button from '../../../components/button';
import { Field, Form } from 'react-final-form';
import { useState } from 'react';
import { supportAPIs } from '../../../services/supportAPIs';
import { createErrorNotification, createNotification } from '../../../utils/notifications';

export const AnswerToTicket = ({ supportRequestId, onSend }) => {
  const dialog = useDialog();
  const [isSending, setIsSending] = useState(false);

  const handleSendMessage = async (message) => {
    const body = {
      answer: message,
      support_request_id: supportRequestId,
    };
    setIsSending(true);
    await supportAPIs
      .sendAnswerToTicket(body)
      .then(() => {
        setIsSending(false);
        createNotification('Ответ отправлен');
        onSend();
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при отправке сообщения');
        setIsSending(false);
      });
  };
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '500px',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-bg-white tw-p-6 tw-rounded-lg">
        <h2 className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">Ответ на запрос тех. поддержки</h2>
        <Form
          onSubmit={({ message }) => {
            handleSendMessage(message);
          }}
        >
          {({ handleSubmit, pristine }) => (
            <>
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-mb-[40px] tw-h-full " id="text-body">
                <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Текст сообщения:</p>
                <Field
                  name="message"
                  render={({ input }) => (
                    <div className="tw-w-full tw-h-full">
                      <TextField required className="tw-w-full" multiline rows={6} {...input} />
                    </div>
                  )}
                />
              </div>
              <div className="tw-flex tw-flex-row tw-gap-6 tw-items-center tw-justify-end">
                <Button
                  variant="text"
                  className="tw-text-[#666666] tw-p-0 hover:tw-bg-transparent tw-w-fit"
                  onClick={() => dialog.close()}
                >
                  <span>Отменить</span>
                </Button>
                <Button
                  className={'tw-w-fit tw-text-sm tw-rounded-lg'}
                  disabled={pristine | isSending}
                  type="submit"
                  onClick={handleSubmit}
                >
                  <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                    <span>Отправить</span>
                    {isSending && (
                      <CircularProgress
                        className="tw-text-white"
                        sx={{
                          height: '14px !important',
                          width: '14px !important',
                        }}
                      />
                    )}
                  </div>
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </Dialog>
  );
};
