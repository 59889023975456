import moment from 'moment';
import { useState, useLayoutEffect, useRef } from 'react';
import classes from '../chatbot.module.sass'

export const Message = ({ message, isNextMessageDifferent, checkProfileImage, lastMessageRef, isLastMessage }) => {
  const messageTextRef = useRef(null);
  const [multiline, setMultiline] = useState(false);
  const handleImageCheck = (is_supplier_msg) => {
    if (!isLastMessage) return checkProfileImage(is_supplier_msg)
    else return checkProfileImage(is_supplier_msg, lastMessageRef)
  }
  useLayoutEffect(() => {
    if (messageTextRef.current.offsetHeight > 22) {
      setMultiline(true);
    }
  }, []);
  return (
    <div className={`tw-flex tw-mb-6 tw-gap-3 ${message.is_supplier_msg ? 'tw-flex-row tw-pl-4' : 'tw-flex-row-reverse tw-pr-4'}`} >
      <div className="tw-flex tw-flex-col tw-justify-end">
        {isNextMessageDifferent ? (
          handleImageCheck(message.is_supplier_msg)
        ) : (
          <div className="tw-w-[42px] tw-h-[42px] tw-bg-transparent"></div>
        )}
      </div>
      <div className={`tw-max-w-[60%]`}>
        <div
          className={`tw-text-sm  tw-flex tw-mb-1 ${
            message.is_supplier_msg
              ? 'tw-bg-white tw-rounded-r-xl tw-rounded-tl-lg tw-rounded-bl-0'
              : 'tw-bg-[#CEF7CE] tw-rounded-l-xl tw-rounded-tr-lg tw-rounded-br-0'
          } ${
            multiline
              ? 'tw-flex-col tw-gap-1 tw-px-3 tw-pt-3 tw-pb-[6px]'
              : 'tw-flex-row tw-gap-2 tw-items-center tw-p-3'
          }`}
        >
          <p ref={messageTextRef} className={`tw-leading-[18px] ${classes.messageBox}`} dangerouslySetInnerHTML={{__html: message.text}}  />
          
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-[6px]">
            <div className="tw-text-sm tw-leading-[18px] tw-text-gray-500 tw-font-medium">
              {moment(message.created_at).format('HH:mm')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
