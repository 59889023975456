import moment from 'moment';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { LineChart } from '../charts';
import { ReactComponent as DownIcon } from '../../icons/downIcon.svg';
import { ReactComponent as UpIcon } from '../../icons/upIcon.svg';

let chart;
function Category({ category }) {
  const labels = [];
  const data = [];
  const id = `category-chart`
  category?.change_points?.forEach((p) => {
    labels.push(moment.unix(p[0]).format('MM-DD-YYYY'));
    data.push(Math.round(p[1]));
  });

  useEffect(() => {
    if (labels && data) {
      const chartInstance = LineChart({ id, labels, data });
      chart = chartInstance.chart;
      chart.update();
    }
    if (labels.length === 0 && chart) {
      chart.destroy();
    }
    return () => {
      if (chart) chart.destroy();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, data]);

  return (
    <div className="tw-flex tw-flex-col tw-col-span-full xl:tw-col-span-8 tw-bg-white">
      <header className="tw-flex tw-items-center">
        <h2 className="tw-font-semibold tw-text-gray-800">{category?.name}</h2>
      </header>
      <div>
        <div className="tw-flex tw-flex-wrap tw-flex-row tw-items-end">
          <div className="tw-flex tw-items-start tw-flex-col tw-py-2">
            <h3 className="tw-text-sm tw-text-gray-500">Число товаров</h3>
            <div className="tw-flex tw-items-center tw-flex-row tw-gap-[14px]">
              <div className="tw-text-3xl tw-font-bold tw-text-gray-800 ">
                <NumberFormat
                  value={category?.count_products || 0}
                  decimalScale={2}
                  displayType="text"
                  thousandSeparator=" "
                  decimalSeparator=","
                />
              </div>
              <div className="tw-flex tw-flex-row tw-items-center">
                <div className="tw-flex tw-items-center tw-gap-[4px]">
                  {category?.percent_change > 0 && <UpIcon className="tw-w-[18px] tw-h-[18px]" />}
                  {category?.percent_change < 0 && <DownIcon className="tw-w-[18px] tw-h-[18px]" />}
                  <div
                    className={`tw-text-base tw-font-bold tw-text-gray-800 tw-mr-2 ${
                      category?.percent_change > 0 && 'tw-text-[#FF4343]'
                    } ${category?.percent_change < 0 && 'tw-text-[#49CA4E]'}`}
                  >
                    <NumberFormat
                      value={category?.percent_change || 0}
                      suffix="%"
                      decimalScale={2}
                      displayType="text"
                      thousandSeparator=" "
                      decimalSeparator=","
                    />
                  </div>
                </div>
                <div className="tw-text-sm tw-text-gray-500">Процент изменения</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grow">
        <canvas id="category-chart" width="800" height="300"></canvas>
      </div>
    </div>
  );
}

export default Category;
