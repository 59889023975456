import { createForm } from 'final-form';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg';
import { ReactComponent as ArrowIcon } from '../../icons/arrowRightIcon.svg';
import {
  changeCategoryProduct,
  deleteProduct,
  restartProductSource,
  saveArchivedProduct,
  saveSourceProduct,
  setArchivedSources,
  setPriceRequests,
  setShowArchive,
  setShowRequestPrice,
  updatePriceRequest,
  updateProduct,
} from '../../store/actions/organizations';
// import AmountToggle from '../amount-toggle';
import { AmountToggle } from './components/AmountToggle';
import Button from '../button';
import { Collapse, Tooltip } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { PriceRange } from './components/PriceRange';
import { Sources } from './components/Sources';
import { useDialog } from '../../providers/dialog.provider';
import { ConfirmModal } from '../modal/ConfirmModal';
import { LogisticOverview } from './components/modals/LogisticOverview';
import Loader from '../loader';
import styles from './product-card.module.sass';
import { placeholderImages } from './placeholderImage';
import { productsAPIs } from '../../services';
import { createErrorNotification } from '../../utils/notifications';

const DEFAULT_PRICE_RANGE = { min: -1, max: -1 };

export default function ProductCard({ product, priceFilter, onReloadProducts }) {
  const filter = {
    min_price: '1',
    avg_price: '2',
    max_price: '3',
  };

  const showArchive = useSelector((state) => state.organizations.showArchive);
  const archivedSources = useSelector((state) => state.organizations.archivedSources);
  const showRequestPrice = useSelector((state) => state.organizations.showRequestPrice);
  const priceRequests = useSelector((state) => state.organizations.priceRequests);
  const report = useSelector((state) => state.organizations.selectedReport);

  const [amount, setAmount] = useState(product.amount);
  const [showSources, setShowSources] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priceRange, setPriceRange] = useState(DEFAULT_PRICE_RANGE);
  const [isChangedPriceRange, setIsChangedPriceRange] = useState(false);
  const [isSourcesOpen, setIsSourcesOpen] = useState(false);
  const dialog = useDialog();
  const [showArchiveProduct, setShowArchiveProduct] = useState(false);
  const [archivedProduct, setArchivedProduct] = useState([]);
  const [showPriceRequest, setShowPriceRequest] = useState(false);
  const [priceRequest, setPriceRequest] = useState([]);

  const createdAt = moment(product.found_at * 1000).format('DD.MM.YYYY');

  const handleChangeProductAmount = (value) => {
    if (amount <= 1 && value === -1) {
      return;
    }
    setAmount(value);
    updateProduct(product.id, { amount: Number(value) });
  };
  const handleCount = (value) => {
    if (value < 0) {
      updateProduct(product.id, { amount: 0 });
      return;
    }
    setAmount(Number(value));
    updateProduct(product.id, { amount: Number(value) });
  };

  const isProcessing = product.status === 'parsing' || product.status === 'initial';
  const handleRestartProcessing = () => {
    if (isProcessing) {
      return;
    }
    setPriceRange(DEFAULT_PRICE_RANGE);
    setIsChangedPriceRange(false);
    setIsLoading(true);
    updateProduct(product.id, { status: 'initial' }).then(() => setIsLoading(false));
  };

  const handleRestartProductSource = (link) => {
    return restartProductSource(product.id, link);
  };

  const handleDeleteProduct = async () => {
    await deleteProduct(product.id);
    await onReloadProducts();
  };

  const handleShowArchiveProduct = () => {
    if (showArchive && !showArchiveProduct) {
      setShowArchive();
    }
    if (!showArchiveProduct) {
      setArchivedSources([]);
      formRef.current.reset();
    }
    setShowPriceRequest(false);
    setShowSources(true);
    setShowArchiveProduct(!showArchiveProduct);
  };

  const handleSaveArchivedProduct = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isLoading) return;
    try {
      setIsLoading(true);
      await saveArchivedProduct(
        product.id,
        archivedProduct.map(({ price, url }) => ({ price, url }))
      );
      setShowArchiveProduct(false);
      setShowArchive(true);
      await onReloadProducts();
    } finally {
      setIsLoading(false);
    }
  };

  const handleArchivedPrices = (checked, archivedPrice) => {
    if (checked) {
      if (showArchive) {
        setArchivedSources(archivedSources.concat([{ ...archivedPrice, product_id: product.id }]));
      }
      if (showArchiveProduct) {
        setArchivedProduct(archivedProduct.concat([archivedPrice]));
      }
    } else {
      if (showArchive) {
        setArchivedSources(archivedSources.filter((e) => e.id !== archivedPrice.id && e.product_id === product.id));
      }
      if (showArchiveProduct) {
        setArchivedProduct(archivedProduct.filter((e) => e.id !== archivedPrice.id));
      }
    }
  };

  const handlePriceRequest = (checked, archivedPrice) => {
    if (checked) {
      if (showRequestPrice) {
        setPriceRequests(priceRequests.concat([{ ...archivedPrice, product_id: product.id }]));
      }
      if (showPriceRequest) {
        setPriceRequest(priceRequest.concat([archivedPrice]));
      }
    } else {
      if (showRequestPrice) {
        setPriceRequests(priceRequests.filter((e) => e.id !== archivedPrice.id && e.product_id === product.id));
      }
      if (showPriceRequest) {
        setPriceRequest(priceRequest.filter((e) => e.id !== archivedPrice.id));
      }
    }
  };

  // const handleChangeLink = (sourceId, optionSourceId) => {
  //   setPriceRange(DEFAULT_PRICE_RANGE);
  //   setIsChangedPriceRange(false);
  //   setIsLoading(true);
  //   updateProduct(product.id, {
  //     sources: productSources.map((source) => {
  //       if (source.uuid === sourceId) {
  //         const updatedSource = { ...source };
  //         const optionSource = { ...updatedSource?.option_sources?.[optionSourceId] };
  //         updatedSource.price = optionSource?.price;
  //         updatedSource.url = optionSource?.url;
  //         updatedSource.option_sources[optionSourceId].price = source.price;
  //         updatedSource.option_sources[optionSourceId].url = source.url;
  //         updatedSource.option_sources[optionSourceId].pics = source.pics;
  //         updatedSource.option_sources[optionSourceId].title = source.title;
  //         updatedSource.title = optionSource?.title;
  //         return updatedSource;
  //       }
  //       return source;
  //     }),
  //   }).then(() => setIsLoading(false));
  // };

  useEffect(() => {
    if (product && priceRange.min < 0 && priceRange.max < 0) {
      const minPrice = product.min_price;
      const maxPrice = product.max_price;
      const delta = (maxPrice - minPrice) / 3;
      setPriceRange({
        min: Math.floor(minPrice + delta),
        max: Math.ceil(minPrice + 2 * delta),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (showArchive || showRequestPrice) {
      setShowArchiveProduct(false);
      setShowPriceRequest(false);
      setArchivedProduct([]);
      setPriceRequest([]);
      formRef.current.reset();
    }
    if ((showArchive || showRequestPrice) && !showSources) {
      // setShowSources(true);
    }
  }, [showArchive, showRequestPrice]);

  const formRef = useRef(
    createForm({
      onSubmit: () => {},
    })
  );

  const handleChange = (selected) => {
    changeCategoryProduct(product.id, selected.value);
  };

  const [currentTab, setCurrentTab] = useState(filter[priceFilter]);

  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 2,
  });
  const getCorrectNoun = (number, one, two, multiple) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return multiple;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return multiple;
  };
  const isProductEmpty = () => {
    if (product.sources.length === 0 && product.status === 'finished') {
      return true;
    }
    return false;
  };
  const [sourcesList, setSourcesList] = useState({
    openSources: [],
    govermentSources: [],
  });

  useEffect(() => {
    setSourcesList({
      openSources: product.sources.filter(
        (source) => source.type === 'yandex_search' || source.type === 'engine_search'
      ),
      govermentSources: product.sources.filter((source) => source.type === 'zakupki'),
    });
  }, [product]);

  const showConfirmationModal = useCallback(() => {
    dialog.open(
      <ConfirmModal title={`Вы действительно хотите удалить товар ${product.name}?`} onAgree={handleDeleteProduct} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, product]);

  const showLogisticOverviewModal = useCallback(
    (name) => {
      dialog.open(<LogisticOverview rows={product?.delivery_stats} priceColumnName={name} />);
    },
    [dialog, product?.delivery_stats]
  );

  const isProductLoading = product?.status === 'parsing' || product?.status === 'initial';

  const handleReloadSingleProduct = useCallback(async () => {
    await productsAPIs.reloadProduct(report?.id, product.id).catch((err) => {
      createErrorNotification('Произошла ошибка при запуске поиска')
    });
    await onReloadProducts()
  }, [report?.id, product?.id, onReloadProducts]);
  return (
    <>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-mt-2 ">
        <div className="tw-flex tw-flex-row tw-justify-between tw-relative ">
          <div className="tw-flex tw-flex-row tw-items-start tw-p-4 tw-w-full tw-relative tw-z-10">
            {isProductLoading && (
              <>
                <span className="tw-absolute tw-top-0 tw-right-0 tw-left-0 tw-bottom-0 tw-block tw-z-20 tw-backdrop-blur-sm tw-rounded-l-lg"></span>
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-absolute tw-top-[40%] tw-left-[10%] tw-z-50">
                  <Loader />
                  <p className='tw-mb-[6px]'>Ведётся поиск товара</p>
                  <Button variant='text' className='tw-w-fit tw-p-0 hover:tw-bg-transparent tw-leading-[18px] tw-text-sm' onClick={() => handleReloadSingleProduct()}>
                    Перезапустить поиск
                  </Button>
                </div>
              </>
            )}
            <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-w-[151px] tw-h-[151px]">
              <img
                src={
                  isProductLoading
                    ? placeholderImages[product.category] ?? '/images/emptyProduct.png'
                    : product.pics?.[0]
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `${placeholderImages[product.category] ?? '/images/emptyProduct.png'}`;
                }}
                loading="lazy"
                className="tw-min-w-[120px] tw-min-h-[120px]"
                alt=""
              />
              <Button
                variant="text"
                onClick={() => showConfirmationModal()}
                className="tw-p-0 tw-text-[#666666] tw-text-sm tw-font-semibold tw-leading-[18px] tw-justify-start hover:tw-bg-transparent hover:tw-text-[#7F7F7F]"
              >
                <CloseIcon />
                Удалить
              </Button>
            </div>
            <div className={`tw-flex tw-flex-col tw-gap-1 tw-items-start tw-ml-4 ${styles.product_name}`}>
              <Tooltip title={product.name} arrow>
                <p className="tw-text-[#191919] tw-font-semibold tw-leading-5 ">{product.name} </p>
              </Tooltip>
              <div className="tw-flex tw-flex-row tw-gap-[6px] tw-items-center">
                <p className="tw-text-[#666666] tw-font-medium tw-leading-normal tw-text-sm tw-max-w-fit">
                  Ед. измерения
                </p>
              </div>
            </div>
            <div className="tw-ml-auto">
              <AmountToggle currentAmount={amount} handleChange={handleChangeProductAmount} />
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-border-l tw-border-[#D9D9D9] tw-pb-4 tw-relative">
            {isProductLoading && (
              <span className="tw-absolute tw-top-0 tw-right-0 tw-left-0 tw-bottom-0 tw-block tw-z-20 tw-backdrop-blur-sm tw-rounded-r-lg"></span>
            )}

            <TabContext value={currentTab}>
              <TabList
                onChange={handleTabChange}
                sx={{
                  borderBottom: 'none',
                  '& .MuiTabs-indicator': {
                    display: 'none',
                  },
                }}
              >
                <Tab
                  label="Мин."
                  value={'1'}
                  disableRipple
                  className="tw-normal-case tw-font-medium tw-pl-[20px] tw-pt-[8px] tw-pr-[19px] tw-pb-[7px] tw-bg-[#F7F7F7]"
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#fff !important',
                    },
                  }}
                />
                <Tab
                  label="Сред."
                  value={'2'}
                  disableRipple
                  className="tw-normal-case tw-font-medium tw-pl-[20px] tw-pt-[8px] tw-pr-[19px] tw-pb-[7px] tw-bg-[#F7F7F7]"
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#fff !important',
                    },
                  }}
                />
                <Tab
                  label="Макс."
                  value={'3'}
                  disableRipple
                  className="tw-normal-case tw-font-medium tw-pl-[20px] tw-pt-[8px] tw-pr-[19px] tw-pb-[7px] tw-bg-[#F7F7F7] tw-rounded-tr-lg"
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#fff !important',
                    },
                  }}
                />
              </TabList>
              <TabPanel value="1" className="tw-pt-4 tw-px-[21px] tw-pb-0">
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px]">
                  <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">
                    Минимальная цена за товар
                  </p>
                  {!isProductEmpty() ? (
                    <>
                      <p className="tw-text-[#191919] tw-text-2xl tw-leading-[28px] tw-font-semibold">
                        {product?.status === 'parsing'
                          ? '-'
                          : numberFormatter
                              .format(product.min_price ?? 0)
                              .replace('₽', '')
                              .trim()}
                        <span className="tw-text-base tw-leading-[20px] tw-ml-[2px]">₽</span>
                      </p>
                      <div className="tw-flex tw-flex-col">
                        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px] tw-mb-[2px]">
                          Логистика
                        </p>
                        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                          <p className="tw-text-sm tw-leading-[18px] tw-text-[#191919] tw-font-semibold">
                            {product?.status === 'parsing'
                              ? '-'
                              : numberFormatter
                                  .format(product?.min_cost_of_delivery ?? 0)
                                  .replace('₽', '')
                                  .trim()}
                            <span className="tw-text-[10px] tw-ml-[2px]">₽</span>
                          </p>
                          <Button
                            variant="text"
                            onClick={() => {
                              showLogisticOverviewModal('min_price');
                            }}
                            disabled={product?.status === 'parsing'}
                            className="disabled:tw-text-[#83A1D9] tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#0844B4] tw-leading-[18px] tw-font-medium tw-text-sm"
                          >
                            Детально
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => handleReloadSingleProduct()}
                      className="tw-w-fit tw-text-sm tw-py-3 tw-px-4 tw-rounded-lg"
                    >
                      Запросить цену
                    </Button>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="2" className="tw-pt-4 tw-px-[21px] tw-pb-0">
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px]">
                  <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">Средняя цена за товар</p>
                  {!isProductEmpty() ? (
                    <>
                      <p className="tw-text-[#191919] tw-text-2xl tw-leading-[28px] tw-font-semibold">
                        {product?.status === 'parsing'
                          ? '-'
                          : numberFormatter
                              .format(product.avg_price ?? 0)
                              .replace('₽', '')
                              .trim()}
                        <span className="tw-text-base tw-leading-[20px] tw-ml-[2px]">₽</span>
                      </p>
                      <div className="tw-flex tw-flex-col">
                        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px] tw-mb-[2px]">
                          Логистика
                        </p>
                        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                          <p className="tw-text-sm tw-leading-[18px] tw-text-[#191919] tw-font-semibold">
                            {product?.status === 'parsing'
                              ? '-'
                              : numberFormatter
                                  .format(product?.avg_cost_of_delivery ?? 0)
                                  .replace('₽', '')
                                  .trim()}
                            <span className="tw-text-[10px] tw-ml-[2px]">₽</span>
                          </p>
                          <Button
                            variant="text"
                            onClick={() => {
                              showLogisticOverviewModal('avg_price');
                            }}
                            disabled={product?.status === 'parsing'}
                            className="disabled:tw-text-[#83A1D9] tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#0844B4] tw-leading-[18px] tw-font-medium tw-text-sm"
                          >
                            Детально
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => handleRestartProcessing()}
                      className="tw-w-fit tw-text-sm tw-py-3 tw-px-4 tw-rounded-lg"
                    >
                      Запросить цену
                    </Button>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="3" className="tw-pt-4 tw-px-[21px] tw-pb-0">
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px]">
                  <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">
                    Максимальная цена за товар
                  </p>
                  {!isProductEmpty() ? (
                    <>
                      <p className="tw-text-[#191919] tw-text-2xl tw-leading-[28px] tw-font-semibold">
                        {product?.status === 'parsing'
                          ? '-'
                          : numberFormatter
                              .format(product.max_price ?? 0)
                              .replace('₽', '')
                              .trim()}
                        <span className="tw-text-base tw-leading-[20px] tw-ml-[2px]">₽</span>
                      </p>
                      <div className="tw-flex tw-flex-col">
                        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px] tw-mb-[2px]">
                          Логистика
                        </p>
                        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                          <p className="tw-text-sm tw-leading-[18px] tw-text-[#191919] tw-font-semibold">
                            {product?.status === 'parsing'
                              ? '-'
                              : numberFormatter
                                  .format(product?.max_cost_of_delivery ?? 0)
                                  .replace('₽', '')
                                  .trim()}
                            <span className="tw-text-[10px] tw-ml-[2px]">₽</span>
                          </p>
                          <Button
                            variant="text"
                            onClick={() => {
                              showLogisticOverviewModal('max_price');
                            }}
                            disabled={product?.status === 'parsing'}
                            className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#0844B4] tw-leading-[18px] tw-font-medium tw-text-sm disabled:tw-text-[#83A1D9]"
                          >
                            Детально
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => handleRestartProcessing()}
                      className="tw-w-fit tw-text-sm tw-py-3 tw-px-4 tw-rounded-lg"
                    >
                      Запросить цену
                    </Button>
                  )}
                </div>
              </TabPanel>
            </TabContext>
            <div className="tw-flex tw-flex-col tw-items-start tw-mt-[13px] tw-px-[21px]">
              <Button
                variant="outlined"
                disabled={product?.status === 'parsing'}
                className="tw-flex tw-flex-row tw-items-center tw-w-fit tw-rounded-lg tw-pl-3 tw-pr-[9px] tw-py-[11px] tw-text-sm tw-leading-[18px] disabled:tw-text-[#83A1D9]"
                onClick={() => setIsSourcesOpen((prev) => !prev)}
              >
                <p>Источники {product?.sources?.length ?? 0}</p>
                <ArrowIcon
                  className={`tw-fill-[#0844B4] tw-w-[20px] tw-h-[20px] tw-transition-transform ${
                    isSourcesOpen ? 'tw-rotate-[-90deg]' : 'tw-rotate-90'
                  }`}
                />
              </Button>
            </div>
          </div>
        </div>
        <Collapse in={isSourcesOpen}>
          <div className="tw-flex tw-flex-col">
            <p className="tw-ml-[18px] tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-[74px]">
              Найдено {product?.sources?.length ?? 0}{' '}
              {getCorrectNoun(product?.sources?.length ?? 0, 'источник', 'источника', 'источников')}
            </p>
            {(product?.price !== 0 ||
              product?.min_price !== 0 ||
              product?.avg_price !== 0 ||
              product?.max_price !== 0) && <PriceRange product={product} />}
            <div className="tw-px-4">
              <Sources
                product={product}
                sources={sourcesList}
                KTRUList={product?.show_ktru_list}
                productId={product.id}
                handleProductReload={onReloadProducts}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
}
