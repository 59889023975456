import moment from 'moment';
import React from 'react';
import GeneralLayout from '../../components/general-layout';
import changelog from './changelog.json';
import { NewsCard } from './components/NewsCard';

function ChangelogPage() {
  return (
    <GeneralLayout>
      <div className='tw-w-full tw-flex tw-flex-col tw-items-center'>
      <div className='tw-flex tw-flex-col tw-gap-8 tw-max-w-[744px]'>
        <h1 className='tw-text-[#191919] tw-text-[32px] tw-leading-[38px] tw-font-semibold'>Новости</h1>
        <div className='tw-flex tw-flex-col tw-gap-4'>
            {changelog.map((log) => (
              <>
              <NewsCard log={log}/>
              </>
            ))}
        </div>
      </div>
      </div>
    </GeneralLayout>
  );
}

export default ChangelogPage;
