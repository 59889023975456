import { Dialog, DialogActions, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { Form } from 'react-final-form';
import { useCallback } from 'react';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import { createErrorNotification } from '../../../../utils/notifications';

export const AddOrganization = ({ onSubmit }) => {
  const mobile = useMediaQuery('(max-width: 500px)');
  const dialog = useDialog();
  const handleFormSubmit = useCallback(
    async (payload) => {
      const { name, inn } = payload;

      try {
        onSubmit && (await onSubmit(name, inn));
      } catch (err) {
        console.error(err);
      }
    },
    [onSubmit]
  );
  return (
    <Dialog
      open={dialog.visibility}
      onClick={() => dialog.close()}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '90%' : '440px',
          margin: mobile ? '0px' : '32px',
        },
      }}
    >
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, submitErrors, submitting, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-flex tw-flex-col tw-bg-white tw-p-6 tw-w-full" onClick={(e) => e.stopPropagation()}>
              <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">Новая организация</p>
              <div className="tw-mb-[56px] tw-w-full">
                <div className='tw-flex tw-flex-col tw-w-full tw-gap-4'>
                <Input form={form} placeholder={'Название организации'} name="name" type="text" required />
                <Input form={form} placeholder={'ИНН'} name="inn" type="number" required />
                </div>
              </div>
              <DialogActions className="tw-flex tw-flex-row tw-gap-6 tw-items-center tw-justify-end">
                <Button
                  variant="text"
                  className="tw-w-fit tw-p-0 tw-text-[#666666] tw-text-sm hover:tw-bg-transparent tw-leading-[18px] hover:tw-text-[#7f7f7f]"
                  disabled={submitting}
                  onClick={() => dialog.close()}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-fit tw-px-4 tw-py-3 tw-text-white tw-text-sm tw-leading-[18px]"
                  disabled={invalid || pristine || submitting}
                  type="submit"
                >
                  Создать
                </Button>
              </DialogActions>
              {submitErrors && createErrorNotification('Что-то пошло не так')}
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
