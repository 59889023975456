import * as constants from '../constants/organizations';
import { dispatch, getCurrentState } from '../index';
import { downloadFile, request } from '../../utils/request';
import moment from 'moment';
import { push } from 'connected-react-router';
import { checkIsAdmin } from '../../utils/common';
import { createNotification, createErrorNotification } from '../../utils/notifications';
import { productsAPIs } from '../../services';

export const getOrganizations = async (user) => {
  try {
    const isAdmin = checkIsAdmin(user);
    const url = isAdmin ? '/api/admin/organizations' : '/api/organizations';
    const { data: organizations } = await request(url);
    const cachedId = localStorage.getItem('organizationId');
    if (cachedId) {
      const cachedOrg = organizations.find((o) => o.id === cachedId);
      if (cachedOrg) {
        setSelectedOrganization(cachedOrg);
      } else {
        toggleOrganizationModal();
      }
    } else {
      if (organizations?.length === 1) {
        setSelectedOrganization(organizations[0]);
      } else {
        toggleOrganizationModal();
      }
    }

    dispatch({ type: constants.ORGANIZATIONS__GET_LIST, organizations });
  } catch (error) {}
};

export const getProducts = async (reportId, filters = {}, loadMore) => {
  try {
    let url = `/api/reports/${reportId}/products?`;
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (key === 'search') {
          url += `query=${value}&`;
        } else {
          url += `${key}=${value}&`;
        }
      }
    });

    const { data } = await request(url);
    const products = data;
    if (loadMore) {
      dispatch({
        type: constants.ORGANIZATIONS__LOAD_MORE_PRODUCTS,
        products,
      });
    } else {
      dispatch({
        type: constants.ORGANIZATIONS__GET_PRODUCTS,
        products,
      });
    }
    return products;
  } catch (error) {
    return [];
  }
};

export const addProduct = async (reportId, name, amount, unit) => {
  try {
    const body = { name };
    if (amount) body.amount = amount;
    if (unit) body.unit = unit;
    await productsAPIs.addProduct(reportId, body);
    return true;
  } catch (error) {
    return null;
  }
};

export const deleteProduct = async (productId) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    await productsAPIs.deleteProduct(selectedReport.id, productId);
    dispatch({ type: constants.ORGANIZATIONS__DELETE_PRODUCT, id: productId });
  } catch (error) {
    console.log(error);
  }
};

export const resetProjects = () => {
  dispatch({
    type: constants.ORGANIZATIONS__GET_PROJECTS,
    projects: [],
    canLoadMoreProjects: true,
  });
};

export const getProjects = async (newOrganization, query = '', page_size = 10) => {
  try {
    const { selected, projectList } = getCurrentState().organizations;
    const selectedOrg = newOrganization || selected;
    const page_num = newOrganization ? 1 : Math.floor(projectList.length / page_size + 1);
    const { data } = await request(
      `/api/organizations/${selectedOrg.id}/projects?page_size=${page_size}&page_num=${page_num}&query=${query}`
    );
    const projects = data;
    const canLoadMoreProjects = projects.length === page_size;

    dispatch({
      type: constants.ORGANIZATIONS__GET_PROJECTS,
      projects,
      canLoadMoreProjects,
      upsert: false, //!newOrganization,
    });
    return projects;
  } catch (error) {
    return [];
  }
};
export const getNumberOfProjects = async () => {
  try {
    const { selected } = getCurrentState().organizations;
    const numberOfProjects = await request(`/api/organizations/${selected.id}/projects?`);
    return numberOfProjects;
  } catch (err) {
    return 0;
  }
};
export const getArchivedProjects = async () => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    const archivedProjectList = getCurrentState().organizations.archivedProjectList;
    console.log('getCurrentState().organizations-first');
    console.log(getCurrentState().organizations);
    const pageSize = 20;
    const pageNum = Math.floor(archivedProjectList.length / pageSize + 1);
    const { data } = await request(
      `/api/organizations/${organizationId}/archived_projects?page_size=${pageSize}&page_num=${pageNum}`
    );
    const archived_projects = data;
    const canLoadMoreProjects = archived_projects.length === pageSize;
    dispatch({
      type: constants.ORGANIZATIONS__GET_ARCHIVED_PROJECTS,
      projects: archived_projects,
      canLoadMoreProjects,
    });
    console.log('getCurrentState().organizations-second');
    console.log(getCurrentState().organizations);
    return archived_projects;
  } catch (error) {
    console.log('error');
    console.log(error);
    return [];
  }
};

export const setSelectedOrganization = async (organization) => {
  localStorage.setItem('organizationId', organization.id);
  await getProjects(organization);
  dispatch({
    type: constants.ORGANIZATIONS__SET_SELECTED,
    organization,
  });
};

export const toggleOrganizationModal = () => {
  dispatch({ type: constants.ORGANIZATIONS__TOGGLE_SELECT_MODAL });
};
export const toggleOrganizationModalCloseBtn = (showSelectModalCloseBtn) => {
  dispatch({
    type: constants.ORGANIZATIONS__TOGGLE_SELECT_MODAL_CLOSEBTN,
    showSelectModalCloseBtn,
  });
};

export const createProject = async ({ name, region_id }) => {
  try {
    const selectedOrg = getCurrentState().organizations.selected;
    const { project } = await request('/api/projects', {
      method: 'POST',
      body: {
        name,
        organization_id: selectedOrg.id,
        region_id,
      },
    });
    dispatch({ type: constants.ORGANIZATIONS__ADD_PROJECT, project });
    return project;
  } catch (error) {
    return null;
  }
};

export const uploadProjectFile = async (projectId, file, requireNameChange) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('usegpt', requireNameChange);
    const reportId = getCurrentState().organizations.selectedReport?.id;
    const { report, products } = await request(`/api/projects/${projectId}/reports/${reportId}`, {
      method: 'PUT',
      body: formData,
    });
    dispatch(push(`/projects/${projectId}`));
    dispatch({ type: constants.ORGANIZATIONS__GET_PRODUCTS, products });
    return report;
  } catch (error) {
    return null;
  }
};

export const getReportsFile = async (reportId) => {
  try {
    await downloadFile(`/api/reports/${reportId}/export`, `Export-${moment().format('DD-MM-YYYY')}.xlsx`);
  } catch (error) {
    createErrorNotification('Не удалось скачать отчет.');
    return false;
  }
};

export const getArchivedReportsFile = async (projectId) => {
  try {
    await downloadFile(
      `/api/projects/${projectId}/saved-sources/export`,
      `Export-${moment().format('DD-MM-YYYY')}.xlsx`
    );
  } catch (error) {
    createErrorNotification('Не удалось скачать отчет.');
    return false;
  }
};

export const getReport = async (projectId, filters = {}) => {
  try {
    const { reports, project_name } = await request(`/api/projects/${projectId}/reports`);
    const report = { ...reports[0], project_name: project_name };
    if (!reports[0]) {
      return { report: null, products: [] };
    }
    const products = await getProducts(report.id, filters);

    dispatch({
      type: constants.ORGANIZATIONS__SET_SELECTED_REPORT,
      report,
    });
    return { report, products };
  } catch (error) {
    return null;
  }
};

export const startProcessingReport = async (reportId, region_id) => {
  try {
    const report = await request(`/api/reports/${reportId}`, {
      method: 'PATCH',
      body: { status: 'processing', region_id },
    });
    dispatch({
      type: constants.ORGANIZATIONS__SET_SELECTED_REPORT,
      report,
    });
  } catch (error) {}
};

export const updateReport = async (reportId, payload) => {
  try {
    await request(`/api/reports/${reportId}`, {
      method: 'POST',
      body: payload,
    });
  } catch (error) {}
  createNotification('Все продукты были добавлены в архив');
};

export const updateProduct = async (id, { name, amount, status, sources, show_ktru_list }) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    const { product, report } = await request(`/api/reports/${selectedReport.id}/products/${id}`, {
      method: 'PATCH',
      body: { name, amount, status, sources, show_ktru_list },
    });
    dispatch({ type: constants.ORGANIZATIONS__UPDATE_PRODUCT, product, report: { ...selectedReport, ...report } });
  } catch (error) {}
};

export const addKtruProduct = async (id) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    const { product, report } = await request(`/api/reports/${selectedReport.id}/products/${id}/ktru`, {
      method: 'POST',
    });
    dispatch({ type: constants.ORGANIZATIONS__UPDATE_PRODUCT, product, report });
  } catch (error) {}
};

export const saveCorrectSources = async (id, sources) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    const { product, report } = await request(`/api/reports/${selectedReport.id}/products/${id}`, {
      method: 'PATCH',
      body: { sources },
    });
    dispatch({ type: constants.ORGANIZATIONS__UPDATE_PRODUCT, product, report: { ...selectedReport, ...report } });
  } catch (error) {}
};

export const saveArchivedProduct = async (id, archivedProduct) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    await request(`/api/reports/${selectedReport.id}/products/${id}`, {
      method: 'POST',
      body: { saved_sources: archivedProduct },
    });
    createNotification('Источник сохранен');
  } catch (error) {}
};

export const saveSourceProduct = async (id, source) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    await request(`/api/reports/${selectedReport.id}/products/${id}`, {
      method: 'POST',
      body: { source },
    });
    createNotification('Источник сохранен');
  } catch (error) {}
};

export const setShowArchive = (hideArchive = false) =>
  dispatch({ type: constants.ORGANIZATIONS__SET_SHOW_ARCHIVE, hideArchive });

export const setArchivedSources = (archivedSources) => {
  dispatch({ type: constants.ORGANIZATIONS__SET_ARCHIVED_SOURCES, archivedSources });
};

export const saveArchivedSources = async () => {
  try {
    const { selectedReport, archivedSources } = getCurrentState().organizations;
    if (!selectedReport) return null;
    await request(`/api/reports/${selectedReport.id}`, {
      method: 'POST',
      body: { saved_sources: archivedSources.map(({ price, url, product_id }) => ({ price, url, product_id })) },
    });
    setShowArchive(true);
    createNotification('Источник сохранен');
  } catch (error) {}
};

export const restartProductSource = async (productId, restart_url) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    const { product, report } = await request(`/api/reports/${selectedReport.id}/products/${productId}`, {
      method: 'PATCH',
      body: { restart_url },
    });
    dispatch({ type: constants.ORGANIZATIONS__UPDATE_PRODUCT, product, report });
  } catch (error) {}
};

export const cleanProducts = () => {
  dispatch({ type: constants.ORGANIZATIONS__CLEAN_PRODUCTS });
};

export const removeArchivedProject = async (id) => {
  try {
    await request(`/api/projects/${id}`, {
      method: 'PATCH',
      body: { has_archived_report: false },
    });
    dispatch({ type: constants.ORGANIZATIONS__REMOVE_ARCHIVED_PROJECT, id });
  } catch (error) {}
};

export const deleteProject = async (id) => {
  try {
    await request(`/api/projects/${id}`, {
      method: 'DELETE',
    });
    dispatch({ type: constants.ORGANIZATIONS__DELETE_PROJECT, id });
  } catch (error) {}
};

export const updateProject = async (project) => {
  try {
    await request(`/api/projects/${project.id}`, {
      method: 'PATCH',
      body: project,
    });
    dispatch({ type: constants.ORGANIZATIONS__UPDATE_PROJECT, project });
  } catch (error) {}
};

export const getSampleReport = async () => {
  await downloadFile('/api/reports/sample.xslx', 'sample.xlsx');
};

export const getBlockedHosts = async () => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    const { blocked_hosts } = await request(`/api/organizations/${organizationId}/blocked-hosts`);
    return blocked_hosts;
  } catch (error) {
    return false;
  }
};

export const blockHost = async (host) => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    await request(`/api/organizations/${organizationId}/blocked-host`, {
      method: 'POST',
      body: { blocked_host: host },
    });
    return null;
  } catch (error) {
    return error;
  }
};

export const unblockHost = async (blocked_host) => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    await request(`/api/organizations/${organizationId}/blocked-host`, {
      method: 'DELETE',
      body: { blocked_host },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getArchivedProducts = async (query, periodMonth, pagination, categories, refresh) => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    const queryString = new URLSearchParams({ query, period_month: periodMonth, categories, ...pagination });
    const { data } = await request(`/api/organizations/${organizationId}/archived_products?${queryString.toString()}`, {
      method: 'GET',
    });
    const saved_sources = data;
    const canLoadMoreArchivedProducts = saved_sources.length === pagination.page_size;
    dispatch({
      type: constants.ORGANIZATIONS__GET_ARCHIVED_PRODUCTS,
      archivedProducts: saved_sources,
      canLoadMoreArchivedProducts,
      refresh,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getSavedSources = async (projectId, query, periodMonth, pagination) => {
  try {
    const queryString = new URLSearchParams({ query, period_month: periodMonth, ...pagination });
    const { saved_sources } = await request(`/api/projects/${projectId}/saved-sources?${queryString.toString()}`, {
      method: 'GET',
    });
    dispatch({ type: constants.ORGANIZATIONS__SET_SAVED_SOURCES, savedSources: saved_sources });
  } catch (error) {
    return false;
  }
};

export const deleteSavedSource = async (id) => {
  try {
    await request(`/api/saved-sources/${id}`, {
      method: 'DELETE',
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const favoriteSavedSource = async (id, isFavorite) => {
  try {
    await request(`/api/saved-sources/${id}`, {
      method: 'PATCH',
      body: {
        is_favorited: isFavorite,
      },
    });
    const savedSources = getCurrentState()?.organizations?.savedSources?.map((source) => ({
      ...source,
      product_sources: source.product_sources?.map((_source) =>
        _source.id === id ? { ..._source, is_favorited: isFavorite } : _source
      ),
    }));
    dispatch({ type: constants.ORGANIZATIONS__SET_SAVED_SOURCES, savedSources });
    return true;
  } catch (error) {
    return false;
  }
};

export const getModificationArchiveReport = async () => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    const response = await request(`/api/organizations/${organizationId}/recievers`, {
      method: 'GET',
    });
    return response.recievers;
  } catch (error) {
    return [];
  }
};

export const modificationArchiveReport = async ({ email, send_email_day, project_ids }) => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    await request(`/api/organizations/${organizationId}/recievers`, {
      method: 'POST',
      body: { email, send_email_day, project_ids },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteEmailModification = async (email) => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    await request(`/api/organizations/${organizationId}/recievers`, {
      method: 'PATCH',
      body: { email },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getCategories = async () => {
  try {
    const { categories } = await request(`/api/organizations/categories`, {
      method: 'GET',
    });
    //    console.log('categories', categories);
    //    const arr_categories = Object.keys(categories);
    //    console.log('arr_categories', arr_categories);
    dispatch({ type: constants.ORGANIZATIONS_CATEGORIES, categories: Object.keys(categories) });
    return true;
  } catch (error) {
    return false;
  }
};

export const getArchivedFile = async (filters) => {
  try {
    const organizationId = localStorage.getItem('organizationId');
    const queryString = new URLSearchParams(filters);
    await downloadFile(
      `/api/organizations/${organizationId}/saved-sources/export?${queryString.toString()}`,
      `Export-${moment().format('DD-MM-YYYY')}.xlsx`
    );
  } catch (error) {
    createErrorNotification('Не удалось скачать отчет.');
    return false;
  }
};

export const changeCategoryProduct = async (id, category) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    await request(`/api/reports/${selectedReport.id}/products/${id}`, {
      method: 'PATCH',
      body: { category },
    });
  } catch (error) {}
};

export const updatePriceRequest = async (id, priceRequests) => {
  try {
    const { selectedReport } = getCurrentState().organizations;
    if (!selectedReport) return null;
    const success = await request(`/api/reports/${selectedReport.id}/products/${id}/price_request`, {
      method: 'POST',
      body: { price_requests: priceRequests },
    });
    if (success) {
      createNotification('В процессе отправки запроса');
    }
    if (!success) {
      createErrorNotification('Что то не так');
    }
  } catch (error) {}
};

export const setShowRequestPrice = (hide = false) =>
  dispatch({ type: constants.ORGANIZATIONS__SET_SHOW_REQUEST_PRICE, hide });

export const setPriceRequests = (priceRequests) => {
  dispatch({ type: constants.ORGANIZATIONS__SET_PRICE_REQUESTS, priceRequests });
};

export const savePriceRequests = async () => {
  try {
    const { selectedReport, priceRequests } = getCurrentState().organizations;
    if (!selectedReport) return null;
    await request(`/api/reports/${selectedReport.id}/price_request`, {
      method: 'POST',
      body: { price_requests: priceRequests.map(({ url, contact, product_id }) => ({ url, contact, product_id })) },
    });
    setShowRequestPrice(true);
    createNotification('Запросы отправлены');
  } catch (error) {}
};
