import React, { useCallback } from 'react';
// import { useSelector } from 'react-redux';
import { downloadFile } from '../../../utils/request';
import { createErrorNotification } from '../../../utils/notifications';
import { useDialog } from '../../../providers/dialog.provider';
import { LogisticGuide } from '../../../components/user-guide/LogisticGuide';
import { MainGuide } from '../../../components/user-guide/MainGuide';
import { ShowQR } from './modal/ShowQR';

function WelcomeBanner() {
  // const user = useSelector((state) => state.auth.user);
  const dialog = useDialog();
  const handleLinkClick = async () => {
    await downloadFile('/api/organizations/user_guide', 'tutorial.pdf').catch((err) => {
      console.error(err);
      createErrorNotification('Произошла ошибка при скачивании файла');
    });
  };
  const handleOpenLogisticGuide = useCallback(() => {
    dialog.open(<LogisticGuide />);
  }, [dialog]);
  const handleOpenMainGuide = useCallback(() => {
    dialog.open(<MainGuide />);
  }, [dialog]);
  const showLargerQR = useCallback(() => {
    dialog.open(<ShowQR />)
  }, [dialog])
  return (
    <div
      className="tw-relative tw-p-4 sm:tw-p-6 tw-overflow-hidden tw-text-white tw-h-full"
      style={{ borderRadius: '12px', zIndex: 1 }}
    >
      <div className="tw-absolute tw-inset-0">
        <img
          src="/images/background/welcome-01.png"
          alt=""
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
      <div className="tw-relative tw-flex tw-flex-col tw-z-20 ">
        <div className="tw-col-span-full md:tw-col-span-4 tw-mb-6">
          <span className="tw-text-lg md:tw-text-2xl lg:tw-text-[2rem] tw-font-bold"> Добро пожаловать! </span>{' '}
          {/* <span className="tw-text-xl tw-hidden md:tw-text-2xl"> {user.full_name}</span> */}
        </div>
        <p
          className="tw-col-span-full md:tw-col-span-6 tw-text-base tw-mb-[22px] text-decoration-underline tw-cursor-pointer tw-leading-5"
          onClick={() => handleOpenMainGuide()}
        >
          Руководство по работе с сервисом предиктивной аналитики
        </p>
        <p onClick={() => handleOpenLogisticGuide()} className="tw-cursor-pointer text-decoration-underline tw-mb-[14px] tw-leading-5">
          Руководство по логистике
        </p>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-[17px] tw-flex-wrap">
          <a href='https://t.me/+tOwVMmIWThhkYWVi' className="tw-cursor-pointer text-decoration-underline tw-leading-5 hover:tw-text-white" target='_blank' rel='noreferrer'>
            Присоединиться к чату в Telegram
          </a>
          <img src="/images/telegram.png" className='tw-cursor-pointer' onClick={() => showLargerQR()} alt="telegramQR" />
        </div>
      </div>
    </div>
  );
}

export default WelcomeBanner;
