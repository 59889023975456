import { request } from '../utils/request';

export const reportsAPIs = {
  updateAddress: (report_id, newAddress, postal_code) =>
    request(`/api/reports/${report_id}/search_address`, {
      method: 'POST',
      body: {
        address: newAddress,
        postal_code: postal_code,
      },
    }),
  selectSources: (report_id, body) =>
    request(`/api/reports/${report_id}/save_selected_sources`, {
      method: 'POST',
      body
    })
};
