import { Checkbox, Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import Button from '../../../button';
import { useState } from 'react';
import { SearchInput } from '../../../input/search';
import { Form } from 'react-final-form';
import { useCallback } from 'react';
import { updateProduct } from '../../../../store/actions/organizations';
import { createErrorNotification, createNotification } from '../../../../utils/notifications';

export const ChangeKTRU = ({ ktruList, choosenKtruList, handleSubmit, productId }) => {
  const [selectedKtruList, setSelectedKtruList] = useState(choosenKtruList);
  const [searchText, setSearchText] = useState('');
  const dialog = useDialog();
  const handleChange = (checked, item) => {
    if (checked) {
      setSelectedKtruList((prev) => [...prev, item]);
    } else {
      const filteredList = selectedKtruList.filter(
        (selectedKtru) => selectedKtru.code !== item.code || selectedKtru.name !== item.name
      );
      setSelectedKtruList(filteredList);
    }
  };
  const handleSubmitChanges = useCallback(async () => {
    await updateProduct(productId, { show_ktru_list: selectedKtruList })
      .then(() => {
        createNotification('ОКПД2/КТРУ обновлено', { type: 'success' });
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при обновлении ОКПД2/КТРУ');
      });
  }, [productId, selectedKtruList, dialog]);

  return (
    <Dialog
      open={dialog.visibility}
      className="tw-w-full"
      sx={{
        '& .MuiPaper-root': {
          minWidth: '80%',
        },
        '& .MuiInputBase-root': {
          backgroundColor: '#F7F7F7',
        },
        fieldset: {
          borderColor: 'transparent',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-p-6 tw-w-full">
        <p className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-normal tw-mb-6">Изменить ОКПД2/КТРУ</p>
        <Form onSubmit={() => {}}>
          {() => (
            <SearchInput
              className={`tw-mb-4`}
              onSearchChange={(value) => {
                setSearchText(value);
              }}
              name="searchKTRU"
              placeholder="Найти ОКПД2/КТРУ"
              type="text"
              required
            />
          )}
        </Form>
        <div className="tw-flex tw-flex-col tw-divide-y tw-divide-[#E6E6E6] tw-max-h-[582px] tw-overflow-y-auto tw-mb-6">
          {ktruList
            .filter((ktru) => ktru.code.includes(searchText) || ktru.name.includes(searchText))
            .map((ktru) => {
              return (
                <div className="tw-flex tw-flex-row tw-justify-start tw-items-start tw-py-4">
                  <div className="tw-flex tw-flex-row tw-gap-3">
                    <Checkbox
                      className="tw-p-0 tw-w-5 tw-h-5 tw-min-w-5"
                      checked={selectedKtruList.some((choosenKtru) => choosenKtru.code === ktru.code)}
                      onChange={(e) => handleChange(e.target.checked, ktru)}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          width: '100%',
                          height: '100%',
                        },
                      }}
                    />
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-semibold tw-mr-[6px]">
                      {ktru.code}
                    </p>
                  </div>
                  <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] first-letter:tw-uppercase tw-max-w-[85%]">
                    {ktru.name}
                  </p>
                </div>
              );
            })}
        </div>
        <DialogActions className="tw-flex tw-flex-row tw-items-center tw-w-full tw-justify-end tw-gap-6">
          <Button
            variant="text"
            onClick={() => dialog.close()}
            className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
          >
            Отменить
          </Button>
          <Button
            className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-font-semibold tw-leading-[18px]"
            disabled={JSON.stringify(choosenKtruList) === JSON.stringify(selectedKtruList)}
            onClick={() => handleSubmitChanges()}
          >
            Сохранить
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
