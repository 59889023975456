import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import classes from './scroll-top-button.module.sass';

export default function ScrollTopButton() {
  const [showBtn, setShowBtn] = useState(false);

  const handleScroll = () => {
    let displayPosition = 300;

    const position = window.pageYOffset;

    if (position > displayPosition) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <CSSTransition in={showBtn} timeout={500} classNames="fade-in" unmountOnExit>
      <div onClick={scrollTop} className={cx('scroll-to-top-btn-container', classes.root)}>
        <i className="fas fa-arrow-up" style={{ fontSize: '20px' }} />
      </div>
    </CSSTransition>
  );
}
