export default function LoaderIcon() {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 2V9.6M21 32.4V40M7.567 7.567L12.944 12.944M29.056 29.056L34.433 34.433M2 21H9.6M32.4 21H40M7.567 34.433L12.944 29.056M29.056 12.944L34.433 7.567"
        stroke="#3F4FB7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
