import { useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { uploadProjectFile } from '../../store/actions/organizations';
import Button from '../button';
import classes from './create-project.module.sass';
import Loader from '../loader';
import { Checkbox } from '@mui/material';

export default function CreateProjectCard({ onReloadProducts }) {
  const [preloader, setPreloader] = useState(false);
  const [requireNameChanger, setRequireNameChanger] = useState(false)
  const [error, setError] = useState(false);
  const { id } = useParams();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setPreloader(true);
      await uploadProjectFile(id, acceptedFiles[0], requireNameChanger).then((res) => {
        if (res === null) {
          setError(true);
          return;
        }
        setError(false);
        onReloadProducts();
      });
      setPreloader(false);
    },
    [id, onReloadProducts, requireNameChanger]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/png',
      'application/pdf',
    ],
    maxFiles: 1,
  });
  return (
    <Container fluid className={classes.root}>
      <h2 className={classes.title}>Загрузите отчет</h2>
      <div className={`${classes.buttons} tw-flex-col tw-items-center tw-gap-3`}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {preloader ? <Loader /> : <Button className={classes.button}>Выбрать файл</Button>}
          {error && <p className={classes.error}>Ошибка загрузки</p>}
        </div>
        <div className='tw-flex tw-flex-row tw-items-center tw-gap-1 tw-bg-white tw-rounded-xl tw-p-2'>
      <Checkbox className='tw-p-0' value={requireNameChanger} onChange={() => setRequireNameChanger((prev) => !prev)} />
      <p className='tw-text-sm'>Использовать оптимизатор названий</p>
      </div>
      </div>
    </Container>
  );
}
