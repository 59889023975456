import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import GeneralLayout from '../../components/general-layout';
import { getStatistics, setDates } from '../../store/actions/statistics';
import Button from '../../components/button';
import Loader from '../../components/loader';
import classes from './statistics.module.sass';
import CalendarRange from '../../components/calendar-range';
import Chart from '../../components/chart';
import { getWordEnding2 } from '../../utils/common';

const monthButtons = [
  { label: '1 месяц', value: 1 },
  { label: '3 месяца', value: 3 },
  { label: '6 месяцев', value: 6 },
  { label: '9 месяцев', value: 9 },
  { label: '12 месяцев', value: 12 },
  { label: '24 месяца', value: 24 },
];

export default function StatisticsPage() {
  const [loader, setLoader] = useState(false);
  const { statistics, dates } = useSelector((state) => state.statistics);

  const handleGetStatistics = ({ since, till }) => {
    setLoader(true);
    getStatistics({ since: since.unix(), till: till.unix() }).then(() => setLoader(false));
  };
  const handleSubmitDates = (e) => {
    e.preventDefault();
    handleGetStatistics({ since: moment(dates.since), till: moment(dates.till) });
  };
  const handleDatesChange = ([since, till]) => {
    setDates({ since, till });
  };
  const getStatisticsLastMonth = (month) => {
    const since = moment().subtract(month, 'M');
    const till = moment();
    setDates({ since: since.toDate(), till: till.toDate() });
    handleGetStatistics({ since, till });
  };

  const noStatistics = statistics && statistics.timeline.length === 0;
  return (
    <GeneralLayout>
      <Container className={classes.container}>
        <form className={classes.form} onSubmit={handleSubmitDates}>
          <CalendarRange
            placeholder="Укажите период отчёта"
            startDate={dates.since}
            endDate={dates.till}
            onChange={handleDatesChange}
            className={classes.calendarInput}
          />
          <Button className={classes.submit} type="submit">
            Поиск
          </Button>
        </form>
        <div className={classes.selectMonth}>
          <div className={classes.title}>Вывести статистку за последние:</div>
          {monthButtons.map((button) => (
            <Button
              onClick={() => getStatisticsLastMonth(button.value)}
              secondary
              size="small"
              key={button.value}
              className={classes.button}
            >
              {button.label}
            </Button>
          ))}
        </div>
        {loader && <Loader />}
        {noStatistics && !loader && (
          <div className={classes.noStatistics}>За этот период статистики нет</div>
        )}
        {statistics && !noStatistics && !loader && (
          <Row>
            <Col xs={12} lg={4}>
              <div className={classes.box}>
                <span className={classes.value}>{statistics?.count_reports}</span>
                <span className={classes.text}>Количество отчетов</span>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className={classes.box}>
                <span className={classes.value}>
                  {Math.floor(statistics?.avg_duration_per100)}{' '}
                  {getWordEnding2('секунд', statistics?.avg_duration_per100)}
                </span>
                <span className={classes.text}>Среднее время обработки 100 товаров на отчет</span>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className={classes.box}>
                <span className={classes.value}>{Math.floor(statistics?.percent_success)}%</span>
                <span className={classes.text}>Процент успешных товаров</span>
              </div>
            </Col>
            <Col xs={12} className="mx-auto">
              <Chart
                xAxisLabel="Дата"
                yAxisLabel="Количество отчетов"
                data={statistics.timeline.map((d) => d.count)}
                xLabels={statistics.timeline.map((d) => d.month)}
              />
            </Col>
          </Row>
        )}
      </Container>
    </GeneralLayout>
  );
}
