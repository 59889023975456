import { Dialog } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import Button from '../../button';
import { loginAs, logout } from '../../../store/actions/auth';
import { useCallback } from 'react';
import { adminAPIs } from '../../../services/adminAPIs';
import { createErrorNotification } from '../../../utils/notifications';

export const MobileDropdownModal = ({ user, organization, showChangeOrganization, limits }) => {
  const dialog = useDialog();
  const startedAccount_id = sessionStorage.getItem('start_account_id');

  const handleChangeOrganization = () => {
    dialog.close();
    showChangeOrganization();
  };
  const returnToStartAccount = useCallback(async () => {
    await adminAPIs
      .loginAs(startedAccount_id)
      .then(({ token, user }) => {
        sessionStorage.removeItem('start_account_id');
        loginAs(token, user);
      })
      .catch((err) => {
        createErrorNotification('Произошла ошибка при попытке входа в аккаунт');
      });
  }, [startedAccount_id]);
  return (
    <Dialog open={dialog.visibility} onClick={() => dialog.close()}>
      <div
        className="tw-bg-white tw-flex tw-flex-col tw-rounded-xl tw-w-[290px] tw-gap-2 tw-pb-1"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="tw-flex tw-flex-col tw-py-4 tw-gap-[6px] tw-px-[14px] tw-w-full">
          <p className="tw-font-medium tw-text-[#191919] tw-text-sm tw-leading-[18px]">{organization?.name}</p>
          <p className="tw-text-xs tw-text-[#999999] tw-font-medium tw-leading-4">{user?.email}</p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-center">
          <Button
            variant="text"
            className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
            onClick={() => {}}
          >
            Лимит запросов {limits?.reached} из {limits?.maxLimit}
          </Button>
          <Button
            variant="text"
            className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
            onClick={() => handleChangeOrganization()}
          >
            Изменить организацию
          </Button>
          {startedAccount_id !== null && (
            <Button
              variant="text"
              className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
              onClick={() => returnToStartAccount()}
            >
              Вернуться в мой аккаунт
            </Button>
          )}
          <Button
            variant="text"
            className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
            onClick={logout}
          >
            Выход
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
