import { request } from '../utils/request';

export const organizationsAPIs = {
  searchInOrganization: (organizationId, query = '') =>
    request(`/api/organizations/${organizationId}/search?query=${query}`, {
      method: 'GET',
    }),
  getNews: (organizationId) =>
    request(`/api/organizations/${organizationId}/news`, {
      method: 'GET',
    }),
  getProductsInSuppliers: (organizationId, supplierId) =>
    request(`/api/organizations/${organizationId}/suppliers/${supplierId}`, {
      method: 'GET',
    }),
  addSupplier: (organizationId, body) =>
    request(`/api/organizations/${organizationId}/suppliers`, {
      method: 'POST',
      body,
    }),
  addSupplierInReport: (reportId, productId, body) =>
    request(`/api/reports/${reportId}/products/${productId}/suppliers`, {
      method: 'POST',
      body,
    }),
  getOrganizations: (query) =>
    request(`/api/organizations?query=${query}`, {
      method: 'GET',
    }),
  getOrganizationsAdmin: (query) =>
    request(`/api/admin/organizations?query=${query}`, {
      method: 'GET',
    }),
  editInn: (body) =>
    request('/api/admin/accesses/edit_inn', {
      method: 'POST',
      body,
    }),
  editOrganization: (body) =>
    request(`/api/admin/organizations/update`, {
      method: 'PATCH',
      body,
    }),
  getAdminOrganization: (id) =>
    request(`/api/admin/organizations/${id}`, {
      method: 'GET',
    }),
  getUserOrganization: (id) =>
    request(`/api/organizations/${id}`, {
      method: 'GET',
    }),
  updateOrganizationsLimits: (body) =>
    request(`/api/admin/organizations/import_limits`, {
      method: 'POST',
      body
    }),
  updateOrganizationsInn: (body) =>
    request('/api/admin/organizations/import_inn', {
      method: 'POST',
      body
    })
};
