import Button from '../button';
import classes from './status-report.module.sass';
import { Divider, LinearProgress } from '@mui/material';
import { ReactComponent as RestartIcon } from '../../icons/restartIcon.svg';
import { ReactComponent as PauseIcon } from '../../icons/pauseIcon.svg';
// import { startProcessingReport } from '../../store/actions/organizations';
import { projectsAPIs } from '../../services';
import { createErrorNotification, createNotification } from '../../utils/notifications';

export default function StatusReport({ isLoading, report, onRestart, onStop }) {
  if (!report) {
    return null;
  }
  const loading = false;
  const progress = Math.round(report.total_count ? (report.success_product_count / report.total_count) * 100 : 0);
  const getProjectStatus = (value) => {
    switch (value?.status) {
      case 'finished':
        return (
          <div className="tw-flex tw-flex-row tw-justify-center tw-items-center ">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#EEFCEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#49CA4E] tw-text-xs'
              }
            >
              Завершено
            </div>
          </div>
        );
      case 'processing':
      case 'initial':
        return (
          <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
              }
            >
              В процессе
            </div>
          </div>
        );
      case 'failed':
        return (
          <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#FFEAEA] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FF4343] tw-text-xs'
              }
            >
              Прервано
            </div>
          </div>
        );
      case 'stopped':
        return (
          <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#FFEAEA] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FF4343] tw-text-xs'
              }
            >
              Остановлено
            </div>
          </div>
        );
      default:
        return (
          <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
            <div
              className={
                'tw-rounded-[9px] tw-bg-[#EEEEEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#7F7F7F] tw-text-xs'
              }
            >
              Статус
            </div>
          </div>
        );
    }
  };
  const handleStopSearch = async () => {
    await projectsAPIs
      .stopSearch(report?.project_id)
      .then(() => {
        createNotification('Поиск остановлен');
        onRestart()
      })
      .catch((err) => {
        console.error(err)
        createErrorNotification('Произошла ошибка');
      });
  };
  const handleRestartSearch = async () => {
    await projectsAPIs.restartSearch(report?.project_id).then(() => {
      createNotification('Поиск перезапущен')
      onRestart()
    }).catch((err) => {
      console.error(err)
      createErrorNotification('Произошла ошибка при попытке перезапуска')
    })
  }
  return (
    <div className={`${classes.root} tw-mb-4 sm:tw-mb-[26px] md:tw-mb-6 tw-w-full lg:tw-pr-[114px] `}>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-rounded-lg tw-py-3 tw-px-[14px]">
        <p className="tw-text-base tw-font-semibold tw-text-[#191919]">Cтатус отчета</p>
        <div className="tw-flex tw-flex-row tw-gap-4 tw-h-fit">
          <div className="tw-flex tw-flex-col tw-gap-[6px] tw-items-center">
            <p className="tw-font-semibold tw-text-[#191919] tw-text-lg tw-leading-[22px]">{progress}%</p>
            <LinearProgress
              value={progress}
              variant="determinate"
              className={`tw-w-[100px] tw-bg-[#EEEEEE] ${classes.progressBar}`}
              sx={{
                color: '#000',
                borderRadius: '50px',
                span: {
                  backgroundColor: progress >= 100 ? '#49CA4E' : '#FEB945',
                },
              }}
            />
          </div>
          <Divider orientation="vertical" className="lg:tw-h-8" />
          {getProjectStatus(report)}

          <Divider orientation="vertical" className="lg:tw-h-8" />
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mr-3">
            <p className="tw-text-sm tw-text-[#666666] tw-font-medium tw-leading-[18px]">Количество товаров</p>
            <span className="tw-text-sm tw-font-semibold tw-text-[#0C0B31] tw-leading-no">
              {report.total_count ?? 0}{' '}
            </span>
          </div>
          <Divider orientation="vertical" className="lg:tw-h-8" />
          <div className="tw-flex tw-flex-row tw-items-center">
            {report?.status === 'finished' || report?.status === 'stopped'  ? (
              <Button
                className="tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-text-sm tw-p-0 tw-h-fit hover:tw-bg-transparent"
                variant="text"
                onClick={() => handleRestartSearch()}
              >
                <RestartIcon /> Перезапустить поиск
              </Button>
            ) : (
              <Button
                className="tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-text-sm tw-p-0 tw-h-fit hover:tw-bg-transparent"
                variant="text"
                onClick={() => handleStopSearch()}
              >
                <PauseIcon /> Остановить поиск
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
