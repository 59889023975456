import { Dialog } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import Table from '../../../table';

export const LogisticOverview = ({ rows, priceColumnName='price' }) => {
  const dialog = useDialog();
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 2,
  });
  const deliveryServices = {
    1: 'СДЕК',
    2: 'Почта России',
    3: 'DPD',
    4: 'ПЭК',
    5: 'ЖелДорЭкспедиция',
    6: 'ГК «Деловые линии»',
    7: 'Транспортная компания «КИТ»'
  };
  const columns = {
    delivery_service_id: {
      label: 'Служба доставки',
      renderBodyCell: (value) => {
        return (
            <p className="tw-text-[#191919] tw-font-medium tw-leading-[18px] tw-text-sm">{deliveryServices[value.delivery_service_id]}</p>
          )
      },
    },
    [priceColumnName]: {
      label: 'Цена',
      renderBodyCell: (value) => {
        return (
            <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
              {!value[priceColumnName] ? '-' :numberFormatter
                .format(value[priceColumnName] ?? 0)
                .replace('₽', '')
                .trim()}
              <span className='tw-ml-1'>₽</span>
            </p>
          )
      },
    },
  };
  return (
    <Dialog open={dialog.visibility}>
      <div className=" tw-bg-white tw-rounded-lg tw-relative tw-w-[496px]">
        <CloseIcon
          className="tw-absolute tw-top-[14px] tw-right-[16px] tw-cursor-pointer"
          onClick={() => dialog.close()}
        />
        <div className="tw-flex tw-flex-col tw-p-6 tw-px-2">
          <p className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-normal tw-pl-4">Варианты доставки</p>
          <Table rows={rows} columns={columns} />
        </div>
      </div>
    </Dialog>
  );
};
