import { Menu, MenuItem } from '@mui/material';
import Button from '../button';
import { useState } from 'react';
import Input from '../input';
import { Form } from 'react-final-form';
import { ReactComponent as ListIcon } from '../../icons/listIcon.svg';
import { useHistory } from 'react-router-dom';
import classes from './analytics-searchbar.module.sass';

export const AnalyticsSearchBar = ({ categories, formValues, onSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-w-[80%]">
      <Button onClick={handleMenuClick} className="tw-w-[fit-content]" disabled={categories?.length === 0}>
        <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center">
          <ListIcon /> <span>Категории</span>
        </div>
      </Button>
      <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose} className="tw-mt-2">
        <div className="lg:tw-w-[362px]">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-[10px]">
            <p className="tw-text-[#191919] tw-text-sm tw-font-semibold">Категории</p>
            <p className="tw-text-[#191919] tw-text-sm tw-font-semibold">Поставщиков</p>
          </div>
          {categories &&
            categories.map((category, index) => (
              <MenuItem
                key={index}
                className="hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-text-[#191919]"
                onClick={() => history.push(`/statistics/${category.id}`)}
              >
                <div className="tw-flex tw-flex-row tw-justify-between tw-w-full" name={category.id}>
                  <p className="">{category.name}</p>
                  <p>{category.suppliers_count}</p>
                </div>
              </MenuItem>
            ))}
        </div>
      </Menu>
      <div className="tw-w-full">
        <Form
          onSubmit={({ search }) => onSubmit && onSubmit(search)}
          render={({ handleSubmit, submitError, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-flex tw-flex-row tw-bg-white tw-items-center tw-rounded-lg">
                <Input
                  placeholder="Введите название товара или поставщика"
                  name="search"
                  type="text"
                  className={classes.searchInput}
                />
                <Button className="tw-w-[fit-content] tw-rounded-lg tw-text-sm tw-py-[14px] tw-px-[24px]" type="submit">
                  Найти
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};
