import Table from './index';

export function CrudTable({ rows, entityColumns, crudButtons, crudHead }) {
  return (
    <Table
      rows={rows}
      columns={{
        ...entityColumns,
        crudAction: {
          label: crudHead ? crudHead() : '',
          renderBodyCell: crudButtons,
        },
      }}
    />
  );
}

export function useCrudEntity(impl) {
  return Object.assign(
    {},
    { 
      create: (payload) => Promise.resolve(),
      read: (id) => Promise.resolve(),
      readAll: (queryParams) => Promise.resolve(),
      edit: (id, payload) => Promise.resolve(),
      detele: (id) => Promise.resolve(),
    },
    impl
  );
}
