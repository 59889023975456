import * as constants from '../constants/statistics';
import { request } from '../../utils/request';
import { dispatch, getCurrentState } from '../index';

export const getStatistics = async ({ since, till }) => {
  try {
    const organization_id = getCurrentState().organizations.selected.id;
    const statistics = await request(
      `/api/statistics/all?since=${since}&till=${till}&organization_id=${organization_id}`
    );
    dispatch({ type: constants.STATISTICS__GET, statistics });
  } catch (error) {
    return false;
  }
};

export const setDates = ({ since, till }) => {
  dispatch({ type: constants.STATISTICS__SET_DATE, since, till });
};
