import GeneralLayout from '../../components/general-layout';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddProjectModal from '../../components/add-project-modal';
import RecentlyActivity from '../../components/analytics/RecentlyActivity';
import Loader from '../../components/loader';
import { organizationsAPIs } from '../../services';
import CreateProjectForm from './components/CreateProjectForm';
import { ChatbotWidget } from './components/ChatbotWidget';
import Statistic from './components/Statistic';
import WelcomeBanner from './components/WelcomeBanner';
import classes from './landing.module.sass';
// import { AnalyticsWidget } from './components/AnalyticsWidget';
import { checkIsAdmin } from '../../utils/common';
import { UserGuide } from '../../components/user-guide';

export default function LandingPage() {
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  const organization = useSelector((state) => state.organizations.selected);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  const fetchData = useCallback( async () => {
    try {
      setIsLoading(true);
      // const params = queryString.stringify({
      //   since: moment().subtract(5, 'days').unix() * 1000,
      //   till: moment().unix() * 1000,
      //   organization_id: organization.id,
      // });
      const [resNews] = await Promise.all([organizationsAPIs.getNews(organization.id)]);
      // setData(generalStatistics);
      setNews(resNews);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [organization?.id]);

  useEffect(() => {
    if (organization) {
      fetchData();
    }
  }, [organization, fetchData]);

  return (
    <GeneralLayout bg="white">
      {isLoading && (
        <div className={classes.loaderPage}>
          <Loader className={classes.loaderIcon} />
        </div>
      )}
      <h1 className="tw-inline-flex tw-text-[#191919] tw-text-2xl sm:tw-text-[32px] tw-font-semibold tw-mb-8 md:tw-leading-[38px] tw-leading-[28px]">
        Рабочий стол
      </h1>
      <div className={`${classes.root} ${isAdmin ? classes.layout : classes.layoutNotAdmin} tw-mb-6`}>
        <div className={classes.welcomeBanner}>
          <WelcomeBanner />
        </div>
        <div className={classes.newsWidget}>
          {isLoading ? (
            <Loader className="tw-m-auto" />
          ) : (
            <div className={`${classes.recentlyActivity}`}>
              <RecentlyActivity news={news?.news || []} />
            </div>
          )}
        </div>

        <div className={`tw-flex-col ${classes.projectWidget}`}>
          <CreateProjectForm />
        </div>

        <Statistic news={news} />

        <div className={classes.chatbotWidget}>
          <ChatbotWidget/>
        </div>
        {/* {isAdmin && (
          <div className={classes.analyticsWidget}>
            <AnalyticsWidget />
          </div>
        )} */}
      </div>

      <AddProjectModal show={showAddProjectModal} onHide={() => setShowAddProjectModal(false)} />
      <UserGuide />
    </GeneralLayout>
  );
}
