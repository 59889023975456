import * as constants from '../constants/auth';
import { dispatch } from '../index';
import { request } from '../../utils/request';
import { getOrganizations } from './organizations';

export const login = async ({ email, password }) => {
  try {
    const { token, user } = await request('/api/login', {
      method: 'POST',
      body: { email, password },
    });
    const status = false;
    localStorage.setItem('token', token);
    dispatch({ type: constants.AUTH__LOGIN_SUCCESS, user });
    dispatch({ type: constants.AUTH__ERROR, status });
    await getOrganizations(user);
    return true;
  } catch (error) {
    const status = true;
    dispatch({ type: constants.AUTH__ERROR, status });
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('organizationId')
  localStorage.removeItem('isFeedbackSurveyCompleted');
  sessionStorage.removeItem('showFeedbackSurvey')
  dispatch({ type: constants.AUTH__LOGOUT });
};

export const getCurrentUser = async () => {
  try {
    const user = await request('/api/users/current');
    
    localStorage.setItem('isFeedbackSurveyCompleted', user.is_form_completed)

    dispatch({ type: constants.AUTH__LOGIN_SUCCESS, user });
    await getOrganizations(user);
    await getRegions();
  } catch (error) {}
};

export const getRegions = async () => {
  try {
    const response = await request('/api/projects/regions');

    dispatch({ type: constants.AUTH__SET_REGIONS, regions: response.regions });
  } catch (error) {}
};

export const loginAs = async (token, user) => {
  try {
    const status = false;
    localStorage.removeItem('organizationId')
    localStorage.setItem('token', token);
    dispatch({ type: constants.AUTH__LOGIN_SUCCESS, user });
    dispatch({ type: constants.AUTH__ERROR, status });
    await getOrganizations(user);
    return true;
  } catch (error) {
    const status = true;
    dispatch({ type: constants.AUTH__ERROR, status });
    return false;
  }
}