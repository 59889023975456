import { useCallback, useEffect, useRef, useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import { Field, Form } from 'react-final-form';
import Input from '../../components/input';
import { ReactComponent as AttachmentIcon } from '../../icons/attachmentIcon.svg';
import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import Button from '../../components/button';
import { useSelector } from 'react-redux';
import { adminAPIs } from '../../services/adminAPIs';
import { ReactComponent as ArrowDown } from '../../icons/arrowDownIcon.svg';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { supportAPIs } from '../../services/supportAPIs';
import { ReactComponent as ClearIcon } from '../../icons/closeIcon.svg';

export default function TechnicalSupportPage() {
  const [files, setFiles] = useState([]);
  const organization = useSelector((state) => state.organizations.selected);
  const user = useSelector((state) => state.auth.user.id);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [organizationId, setOrganizationId] = useState('');
  const [lastElementName, setLastElementName] = useState('');
  const [isSending, setIsSending] = useState(false);

  const getProjects = useCallback(
    async (page, limit, query, signal) => {
      if (organizationId !== '') {
        return supportAPIs.getProjects(organizationId, limit, page, query, signal);
      }
    },
    [organizationId]
  );

  useEffect(() => {
    setOrganizationId(organization?.id);
  }, [organization]);

  const { data, handleChangeQuery, hasMore, isLoading, goToNextPage } = useInfiniteScroll(
    getProjects,
    'data',
    1,
    10,
    ''
  );

  const observer = useRef();

  const lastProjectRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          goToNextPage();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const handleDeleteFile = (name) => {
    const filteredFiles = files.filter((file) => file.name !== name);
    setFiles(filteredFiles);
  };
  const handleAddFile = (files) => {
    setFiles((prev) => [...prev, ...files]);
  };
  const handleSubmit = async (payload, form) => {
    const date = new Date().toJSON();

    const { email, name, problem, description, urgency, comment } = payload;
    const body = new FormData();
    const data = {
      comment: comment ?? '',
      created_at: date,
      description,
      email,
      name,
      organization_id: organization.id,
      user_id: user,
      problem,
      status: 'ACCEPTED',
      urgency: urgency ? 1 : 0,
    };

    Object.entries(data).forEach(([key, value]) => {
      body.append(key, value);
    });

    if (selectedProject.id) {
      body.append('project_id', selectedProject.id);
    }

    if (files && files.length > 0) {
      files?.forEach((file) => {
        body.append('files', file);
      });
    }
    setIsSending(true)
    await adminAPIs
      .addSupportRequest(body)
      .then(() => {
        createNotification('Заявка успешно отправлена');
        setIsSending(false)
        setFiles([]);
        form?.restart();
      })
      .catch((err) => {
        console.error(err);
        setIsSending(false)
        if (err.statusCode === 409) {
          createErrorNotification('Заявка с таким названием уже существует');
        } else {
          createErrorNotification('Произошла ошибка при отправке заявки');
        }
      });
  };
  const handleSearchChange = (value) => {
    handleChangeQuery(value ?? '');
  };

  useEffect(() => {
    setProjects(data);
    setLastElementName(data[data.length - 1]?.name);
  }, [data]);
  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-gap-6">
        <div className="tw-flex tw-flex-col tw-rounded-lg tw-border tw-border-[#FF4343] tw-px-10 tw-py-[18px] tw-bg-white tw-max-w-[800px] tw-gap-3 tw-text-[#191919] tw-font-medium">
          <p className="tw-leading-5">
            При возникновении вопросов по работе сервиса просим звонить по номеру{' '}
            <a
              href="tel:88432366001"
              className="tw-cursor-pointer tw-text-[#0844B4] hover:tw-text-[#0A50D4] tw-transition-colors"
            >
              8 (843) 236-60-01{' '}
            </a>{' '}
            с 9:00 до 18:00 по мск.
          </p>
          <p className="tw-leading-5">
            Заявки по техподдержке вы можете оформлять круглосуточно в разделе «Техподдержка» в системе предиктивной
            аналитики.
          </p>
        </div>
        <div className=" tw-p-4 tw-bg-white tw-rounded-xl tw-flex tw-flex-col tw-px-10 tw-pb-10 tw-pt-[22px]">
          <h2 className="tw-text-xl sm:tw-text-2xl md:tw-text-[32px] tw-font-semibold tw-text-[#191919] tw-mb-4">
            Поддержка сервиса предиктивной аналитики
          </h2>
          <Form
            onSubmit={(payload, form) => handleSubmit(payload, form)}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="tw-flex tw-flex-col tw-gap-3">
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <p className="tw-text-[#191919] tw-font-medium">Эл. почта</p>
                    <Input form={form} name="email" type="text" placeholder="name@company.com" />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <p className="tw-text-[#191919] tw-font-medium">Имя</p>
                    <Input form={form} name="name" type="text" />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <p className="tw-text-[#191919] tw-font-medium">Проект</p>
                    <Autocomplete
                      freeSolo
                      forcePopupIcon
                      loading={isLoading}
                      loadingText={<CircularProgress color="inherit" size={20} />}
                      clearIcon={<ClearIcon />}
                      onChange={(e, value) => setSelectedProject(value)}
                      onInputChange={(e) => handleSearchChange(e?.target?.value)}
                      sx={{
                        border: 'none',
                        '& .MuiInputBase-root': {
                          borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused': {
                            fieldset: {
                              border: '1px solid #CCC',
                            },
                          },
                          '&:hover': {
                            fieldset: {
                              border: '1px solid #CCC',
                            },
                          },
                        },
                        fieldset: {
                          border: '1px solid #CCC',
                        },
                      }}
                      options={projects}
                      noOptionsText={'Не найдено проекта'}
                      getOptionLabel={(data) => data.name}
                      popupIcon={<ArrowDown />}
                      isOptionEqualToValue={(option, value) => option?.name === value?.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Выберите проект"
                          // fullWidth
                          name="project_id"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      renderOption={(props, option, state, ownerState) => {
                        return (
                          <>
                            <li
                              {...props}
                              key={option?.id}
                              ref={option.name === lastElementName ? lastProjectRef : null}
                            >
                              {ownerState.getOptionLabel(option)}
                            </li>
                          </>
                        );
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: '200px',
                        },
                        role: 'list-box',
                      }}
                    />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <p className="tw-text-[#191919] tw-font-medium">
                      Проблема <span className="tw-text-[#FF4343]">*</span>
                    </p>
                    <Input form={form} name="problem" type="text" required />
                    <p className="tw-text-xs tw-text-[#666666]">
                      Напишите суть проблемы, например, «Не работает почта»
                    </p>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <p className="tw-text-[#191919] tw-font-medium">
                      Описание проблемы <span className="tw-text-[#FF4343]">*</span>
                    </p>
                    <Input form={form} name="description" type="text" required />
                    <p className="tw-text-xs tw-text-[#666666]">
                      Опишите проблему: что случилось, как произошло, что надо сделать
                    </p>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <p className="tw-text-[#191919] tw-font-medium">Файлы</p>
                    <input
                      type="file"
                      name="files"
                      id="uploadBtn"
                      multiple
                      hidden
                      onChange={(e) => handleAddFile(e.target.files)}
                    />
                    <label for="uploadBtn" className="tw-flex tw-flex-row tw-items-center tw-cursor-pointer">
                      <AttachmentIcon /> Загрузить файл
                    </label>
                    <div className="tw-flex tw-flex-col tw-gap-1">
                      {files?.map((file, index) => (
                        <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center" key={index}>
                          <p className="tw-text-sm tw-text-[#191919]">{file.name}</p>{' '}
                          <CloseIcon
                            className="tw-w-4 tw-h-4 tw-cursor-pointer"
                            onClick={() => handleDeleteFile(file.name)}
                          />
                        </div>
                      ))}
                    </div>
                    <p className="tw-text-xs tw-text-[#666666]">
                      Приложите скриншоты, логи ошибки или другие файлы, если возможно
                    </p>
                  </div>
                  {/* <div className="tw-flex tw-flex-col tw-gap-1">
                    <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center">
                      <Field component="input" type="checkbox" id="urgency" name={'urgency'} />
                      <label for="urgency" className="tw-text-[#191919]">
                        Срочность
                      </label>
                    </div>
                    <p className="tw-text-xs tw-text-[#666666]">
                      Поставьте галочку, если задача срочная и её надо решить как можно скорее
                    </p>
                  </div> */}
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <p className="tw-text-[#191919] tw-font-medium">Комментарии к заявке</p>
                    <Field name="comment">
                      {({ input }) => (
                        <TextField
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          multiline
                          sx={{
                            textarea: {
                              fontSize: '14px',
                            },
                            '& .Mui-focused': {
                              fieldset: {
                                border: '1px solid #ccc !important',
                              },
                            },
                            '& .MuiOutlinedInput-root:hover': {
                              fieldset: {
                                border: '1px solid #ccc !important',
                              },
                            },
                          }}
                          maxRows={4}
                        />
                      )}
                    </Field>
                    <p className="tw-text-xs tw-text-[#666666]">
                      Добавьте дополнительную информацию по задаче, если нужно
                    </p>
                  </div>
                  <div className="tw-flex tw-flex-row tw-justify-start tw-mt-2">
                    <Button type="submit" className="tw-w-fit" disabled={isSending}>
                      <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                        <span>Отправить</span>
                        {isSending && (
                          <CircularProgress
                            className="tw-text-white"
                            sx={{
                              height: '14px !important',
                              width: '14px !important',
                            }}
                          />
                        )}
                      </div>
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </GeneralLayout>
  );
}
