import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from './RecentlyActivity.module.sass';

function RecentlyActivity({ news }) {
  const history = useHistory();
  const newsObj = news.reduce((obj, item) => {
    const createdAt = moment.unix(item.created_at).format('YYYY-MM-DD');
    return { ...obj, [createdAt]: [...(obj[createdAt] || []), item] };
  }, {});

  const handleClick = (item) => {
    switch (item.type) {
      case 'report':
        history.push(`/archived-projects/${item.id}`);
        return;
      case 'category':
        // history.push(`/statistics/categories?category_id=${item.id}`);
        history.push(`/statistics/categories/${item.id}`);
        return;
      case 'project':
        history.push(`/projects/${item.id}`);
        return;
      default:
        return;
    }
  };

  const rows = Object.keys(newsObj).sort((a, b) => moment(b).diff(moment(a), 'days'));
  const stringModifier = (string, item) => {
    const lowerSentence = string.toLowerCase();
    const regex = /Проект\s+(.*)\s+был/i;
    if (string.match(regex) && string.match(regex).length > 0) {
      const matchedText = string.match(regex)[1];
      const indexOfFirstWord = lowerSentence.split(' ').indexOf('проект');
      const indexOfLastWord = lowerSentence.split(' ').indexOf('был');
      const firstHalf = string
        .split(' ')
        .slice(0, indexOfFirstWord + 1)
        .join(' ');
      const secondHalf = string.split(' ').slice(indexOfLastWord).join(' ');
      return (
        <>
          <p>
            {firstHalf} <span onClick={() => handleClick(item)}>{matchedText}</span> {secondHalf}
          </p>
        </>
      );
    } else
      return (
        <>
          <p>{string}</p>
        </>
      );
  };
  return (
    <div className="tw-bg-white tw-rounded-xl tw-py-4 tw-px-6 ">
      <header className="tw-border-gray-100 tw-mb-4">
        <h2 className="tw-font-semibold tw-text-gray-800 tw-text-left tw-text-[1.5rem]">Последняя активность</h2>
      </header>
      <div className={`${classes.newsContainer}`}>
        {rows.length === 0 ? (
          <span className="tw-mx-2 tw-my-1">Нет действий</span>
        ) : (
          rows.map((key, i) => (
            <div key={i}>
              {newsObj[key].length > 0 && (
                <>
                  <ul className="tw-my-1">
                    {newsObj[key].map((item, i) => (
                      <li
                        className="tw-px-[10px] tw-py-[12px] tw-rounded-lg tw-mb-2"
                        style={{ backgroundColor: '#F7F7F7' }}
                        key={i}
                      >
                        <div className="tw-grow tw-flex tw-flex-col tw-items-start tw-border-b tw-border-gray-100 tw-text-sm tw-max-w-[321px]">
                          <header
                            className="tw-self-start tw-text-sm tw-text-[#666] tw-font-normal tw-text-left"
                            style={{ color: '#666' }}
                          >
                            {moment(key).isSame(moment(), 'days')
                              ? 'Сегодня'
                              : moment(key).isSame(moment().subtract(1, 'days'), 'days')
                              ? 'Вчерашний день'
                              : moment(key).fromNow()}{' '}
                            {`- (${moment(key).format('ll')})`}
                          </header>
                          <div className="tw-grow tw-flex tw-justify-between">
                            <div className={`tw-self-start ${classes.newsText}`}>{stringModifier(item.news, item)}</div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default RecentlyActivity;
