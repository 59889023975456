import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import GeneralLayout from '../../components/general-layout';
import Loader from '../../components/loader';
import CategoryIcon from '../../icons/category';
import CloseIcon from '../../icons/close';
import { statisticsAPIs } from '../../services';
import StatisticsCategoryPage from '../statistics-category';
import queryString from 'query-string';

export default function StatisticsCategoriesPage() {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await statisticsAPIs.getCategoriesStatistics();
      setData(response);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (category) => {
    history.push(`/statistics/categories/${category.id}`);
  };

  const onHide = () => setShowModal(false);

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (location && location.search && data && data.length) {
  //     const query = queryString.parse(location.search);
  //     const categoryId = query.category_id;
  //     if (categoryId) {
  //       const categories = (data?.categories || []).sort((a, b) => b.count_products - a.count_products);
  //       setSelected(
  //         Math.max(
  //           categories.findIndex((c) => c.id === categoryId),
  //           0
  //         )
  //       );
  //     }
  //   }
  // }, [location, data]);

  const categories = (data?.categories || []).sort((a, b) => b.count_products - a.count_products);

  return (
    <GeneralLayout>
      <Container>
        {/* {categories?.[selected] && (
          <StatisticsCategoryPage
            id={categories?.[selected].id}
            onShowCategoriesModal={() => setShowModal(!showModal)}
          />
        )} */}
        <div className="sm:tw-flex sm:tw-justify-between sm:tw-items-center tw-mb-4">
          <div className="tw-mb-4 sm:tw-mb-0">
            <h1 className="tw-text-2xl md:tw-text-3xl tw-text-gray-800 tw-font-bold">Аналитика по категориям </h1>
          </div>
        </div>

        {isLoading ? (
          <Loader className="tw-mt-4" />
        ) : (
          <div className="tw-grid tw-grid-cols-12 tw-gap-6">
            {categories.map((category, i) => (
              <div
                key={i}
                className="tw-cursor-pointer hover:tw-shadow-lg tw-col-span-full sm:tw-col-span-6 xl:tw-col-span-4 tw-bg-white tw-rounded-sm tw-border tw-border-gray-200"
                onClick={() => handleClick(category)}
              >
                <div className="tw-flex tw-flex-col tw-h-full">
                  <div className="tw-grow tw-p-3">
                    <div className="tw-flex tw-justify-between tw-items-start">
                      <header>
                        <div className="tw-flex">
                          <div className="tw-relative tw-inline-flex tw-items-start tw-mr-3" href="#0">
                            <img width="32" height="32" src={`/images/categories/${category.name}.svg`} />
                          </div>
                          <div className="tw-inline-flex tw-text-gray-800 hover:tw-text-gray-900" href="#0">
                            <h2 className="tw-text-md tw-leading-snug tw-justify-center">{category.name}</h2>
                          </div>
                        </div>
                      </header>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* <Modal size="lg" show={showModal} onHide={onHide}>
          <div className="tw-p-8 tw-pt-12">
            <div onClick={() => onHide()} className="tw-cursor-pointer tw-absolute tw-top-4 tw-right-8">
              <CloseIcon />
            </div>
            <div className="tw-grid tw-grid-cols-12 tw-gap-6">
              {categories.map((category, i) => (
                <div
                  key={i}
                  className={classNames(
                    'tw-col-span-full sm:tw-col-span-6 xl:tw-col-span-4 tw-bg-white tw-rounded-sm tw-border tw-border-gray-200',
                    selected === i ? 'tw-bg-gray-100' : 'tw-cursor-pointer hover:tw-shadow-lg'
                  )}
                  onClick={() => {
                    if (selected !== i) {
                      setSelected(i);
                      onHide();
                    }
                  }}
                >
                  <div className="tw-flex tw-flex-col tw-h-full">
                    <div className="tw-grow tw-p-3">
                      <div className="tw-flex tw-justify-between tw-items-start">
                        <header>
                          <div className="tw-flex">
                            <div className="tw-relative tw-inline-flex tw-items-start tw-mr-3" href="#0">
                              <img width="32" height="32" src={`/images/categories/${category.name}.svg`} />
                            </div>
                            <div className="tw-inline-flex tw-text-gray-800 hover:tw-text-gray-900" href="#0">
                              <h2 className="tw-text-md tw-leading-snug tw-justify-center">{category.name}</h2>
                            </div>
                          </div>
                        </header>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal> */}
      </Container>
    </GeneralLayout>
  );
}
