import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { uploadProjectFile } from '../../store/actions/organizations';
import { ReactComponent as ExcelIcon } from '../../icons/excelIcon.svg';
import Button from '../button';
import classes from './create-project-inline.module.sass';
import Loader from '../loader';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import eventBus from '../../utils/eventBus';
import { EVENTS } from '../../utils/constant';
import { Checkbox } from '@mui/material';

export default function CreateProjectCardInline() {
  const [preloader, setPreloader] = useState(false);
  const [requireNameChanger, setRequireNameChanger] = useState(false);
  const { id } = useParams();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setPreloader(true);
      await uploadProjectFile(id, acceptedFiles[0], requireNameChanger).then((res) => {
        if (res === null) {
          createErrorNotification('Ошибка загрузки');
          return;
        }
        createNotification('Файл успешно загружен');
        eventBus.emit(EVENTS.RELOAD_PRODUCTS);
      });
      setPreloader(false);
    },
    [id, requireNameChanger]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/png',
      'application/pdf',
    ],
    maxFiles: 1,
  });
  return (
    <div className="lg:tw-pr-[114px] tw-w-full">
      <div className="tw-bg-white tw-rounded-xl tw-flex tw-flex-row tw-items-center  tw-gap-4 tw-py-3 tw-px-[14px] tw-w-full">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {preloader ? (
            <Loader />
          ) : (
            <>
              <Button secondary={false} variant="outline" className={`${classes.button} tw-p-0 tw-text-sm`}>
                <ExcelIcon className="tw-mr-[5px]" /> Загрузить из excel
              </Button>
            </>
          )}
        </div>

        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
          <Checkbox
            className="tw-p-0"
            value={requireNameChanger}
            onChange={() => setRequireNameChanger(!requireNameChanger)}
          />
          <p className="tw-text-sm">Использовать оптимизатор названий</p>
        </div>
      </div>
    </div>
  );
}
