import React from 'react';
import { useSelector } from 'react-redux';
import Search from './components/Search';
import UserZone from './components/UserZone';
import { Badge } from '@mui/material';
import { ReactComponent as NotificationIcon } from '../../icons/notificationIcon.svg';
import { Link } from 'react-router-dom';
import classes from './top-header.module.sass'

function TopHeader({isServerError}) {
  const user = useSelector((state) => state.auth.user);
  const organization = useSelector((state) => state.organizations.selected);
  return (
    <>
      <header className={`tw-fixed ${isServerError ? classes.headerWithServerError : 'tw-top-[10px] sm:tw-top-6'}   tw-right-4 sm:tw-right-8 tw-shadow-[0_2px_12px_0_rgba(178, 178, 178, 0.2)] tw-bg-white tw-border tw-border-[#EEE] tw-z-[31] tw-rounded-full tw-flex tw-flex-row tw-gap-4 tw-items-center tw-py-[6px] tw-pl-2 tw-pr-1`}>
        <Search />
        <Link to="/chatbot">
          <Badge badgeContent={0} className='tw-relative tw-z-10'>
            <NotificationIcon className="tw-cursor-pointer" />
          </Badge>
        </Link>
        <UserZone user={user} organization={organization} />
      </header>
    </>
  );
}

export default TopHeader;
