import { useState } from 'react';
import { Form as FormBootStrap, FormSelect } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { getModificationArchiveReport, modificationArchiveReport } from '../../store/actions/organizations';
import { FREQUENCY_OPTIONS_LABEL } from '../../utils/constant';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import Button from '../button';
// import Input from '../input';
import ViewModificationModal from '../view-modification-modal';
import classes from './modification-archive-report.module.sass';

export default function ModificationArchiveReport({
  projectIds = [],
  canSave,
  showSelectProject,
  setSelectedProject = () => {},
  setIsSelectAll = () => {},
  setShowSelectProject = () => {},
}) {
  const [showModal, setShowModal] = useState(false);
  const [receivers, setReceivers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log({ projectIds });

  const fetchReceivers = async () => {
    setIsLoading(true);
    const response = await getModificationArchiveReport();
    setReceivers(response);
    setIsLoading(false);
  };

  const handleViewModification = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
    await fetchReceivers();
  };

  const handleSubmit = async ({ email, send_email_day }) => {
    try {
      setIsSubmitting(true);
      const success = await modificationArchiveReport({ email, send_email_day, project_ids: projectIds });
      if (success) {
        createNotification('Почта добавлена');
      }
      if (!success) {
        createErrorNotification('почта уже добавлена');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowSelectProject(false);
      setSelectedProject([]);
      setIsSelectAll(false);
      setIsSubmitting(false);
    }
  };

  const frequencyOptions = Object.keys(FREQUENCY_OPTIONS_LABEL).map((key, index) => ({
    label: FREQUENCY_OPTIONS_LABEL[key],
    value: index.toString(),
  }));

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Field
              name="send_email_day"
              defaultValue={frequencyOptions[0].value}
              render={({ input, meta }) => (
                <div>
                  <h5
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Настроить рассылки
                  </h5>
                  <br></br>
                  <FormBootStrap.Label>Периодичность</FormBootStrap.Label>
                  <FormSelect {...input}>
                    {frequencyOptions.map((region) => (
                      <option key={region.value} value={region.value}>
                        {region.label}
                      </option>
                    ))}
                  </FormSelect>
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className={classes.error}>{meta.error || meta.submitError}</span>
                  )}
                </div>
              )}
            />

            <Field
              name="email"
              render={({ input, meta }) => (
                <div>
                  <FormBootStrap.Label>Почта</FormBootStrap.Label>
                  <FormBootStrap.Control type="email" required {...input} />
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className={classes.error}>{meta.error || meta.submitError}</span>
                  )}
                </div>
              )}
            />

            <div className={classes.groupBtn}>
              {showSelectProject && canSave ? (
                <Button secondary type="submit" size="small" disabled={isSubmitting}>
                  Сохранить
                </Button>
              ) : (
                <Button
                  secondary
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowSelectProject(!showSelectProject);
                  }}
                >
                  Выбрать проекты
                </Button>
              )}
              <Button secondary size="small" onClick={handleViewModification}>
                Список рассылки
              </Button>
            </div>
          </form>
        )}
      </Form>

      <ViewModificationModal
        isLoading={isLoading}
        show={showModal}
        onHide={() => setShowModal(false)}
        receivers={receivers}
        fetchReceivers={fetchReceivers}
      />
    </div>
  );
}
