export const EVENTS = {
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  SHOW_ARCHIVE_SOURCE: 'SHOW_ARCHIVE_SOURCE',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  OPEN_TECHNICAL_SUPPORT: 'OPEN_TECHNICAL_SUPPORT',
  OPEN_DIALOG: 'OPEN_DIALOG',
};

export const STANDARD_DATE_FORMAT = 'DD-MM-YYYY';

export const SOURCE_TYPES_VALUE = {
  YANDEX_SEARCH: 'yandex_search',
  ENGINE_SEARCH: 'engine_search',
  ZAKUPKI: 'zakupki',
  UNKNOWN_PRICE: 'unknown_price',
};

export const SOURCE_TYPES = {
  open_sources: 'Открытые источники',
  zakupki: 'Государственные контракты',
  suppliers: 'Поставщики',
};

export const FREQUENCY_OPTIONS_LABEL = {
  mondays: 'По понедельникам',
  tuesdays: 'По вторникам',
  wednesdays: 'По средам',
  thursdays: 'По четвергам',
  fridays: 'По пятницам',
  saturdays: 'По субботам',
  sundays: 'По воскресеньям',
  everyday: 'Каждый день',
};

export const USER_ROLE_OPTIONS = [
  { label: 'Юзер', value: 'user' },
  { label: 'Админ', value: 'admin' },
];

export const IMAGES_CATEGORIES = [
  'simple-cart',
  'Авто',
  'Аптека',
  'Бытовая техника',
  'Бытовая химия',
  'Дача и сад',
  'Детские товары',
  'Для школы и офиса',
  'Дом',
  'Здравоохранение',
  'Зоотовары',
  'Канцелярские товары',
  'Компьютеры',
  'Красота и гигиена',
  'Мебель',
  'Оборудование',
  'Одежда и обувь',
  'Оптика',
  'Продукты питания',
  'Спорт и отдых',
  'Строительство и ремонт',
  'Товары для взрослых',
  'Транспорт',
  'Хобби и творчество',
  'Хозяйственный инвентарь',
  'Цветы',
  'Цифровые товары',
  'Электроника',
  'Ювелирные украшения',
];

export const UNIT_OPTIONS = [
  { value: 'шт', label: 'шт' },
  { value: 'кг', label: 'кг' },
  { value: 'л', label: 'л' },
];
