import React, { useState } from 'react';
import Category from './Category';
import { ReactComponent as ArrowRight } from '../../icons/arrowLeftIcon.svg';

function AllCategories({ categories }) {
  const [selected, setSelected] = useState(0);
  return (
    <>
    <div className='tw-bg-white tw-pt-[20px] tw-pr-[25px] tw-pb-[14px] tw-pl-6 tw-grid tw-grid-cols-12 tw-gap-[37px]'> 
      <div className="tw-flex tw-flex-col tw-col-span-full xl:tw-col-span-4 tw-max-h-[440px] ">
        <header className="tw-mb-[20px] tw-flex tw-flex-row tw-justify-between tw-text-base tw-text-[#191919] tw-font-medium">
          <h2>Категории</h2>
          <h2>Число товаров</h2>
        </header>
        <div className="tw-flex tw-flex-col tw-overflow-y-auto">
          {categories.map((category, index) => {
            return (
              <div
                className={` ${selected === index && 'tw-bg-[#F9FCFF]'} tw-flex tw-flex-row tw-justify-between tw-text-sm hover:tw-bg-[#F9FCFF] tw-border-b tw-border-[#D9D9D9] last-of-type:tw-border-0 tw-py-3`}
                key={index}
                onClick={() => setSelected(index)}
              >
                <p className="tw-text-[#191919] tw-pl-3">{category.name}</p>
                <div className="tw-flex tw-flex-row tw-gap-[1px] tw-items-center">
                  <p className="tw-text-[#666666]">{category.count_products}</p>
                  {selected === index ? (
                    <ArrowRight className="tw-w-[24px] tw-h-[24px]" />
                  ) : (
                    <div className="tw-h-[24px] tw-w-[24px] tw-bg-transparent" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Category category={categories?.[selected]} />
      </div>
    </>
  );
}

export default AllCategories;
