import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  setSelectedOrganization,
  toggleOrganizationModal,
  toggleOrganizationModalCloseBtn,
} from '../../store/actions/organizations';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg';
import Loader from '../loader';
import { checkIsAdmin } from '../../utils/common';
import { organizationsAPIs } from '../../services';
import { createErrorNotification } from '../../utils/notifications';
import { SearchInput } from '../input/search';
import { Form } from 'react-final-form';

export default function OrganizationsModal() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  
  const fetchOrganizations = useCallback(async () => {
    setIsLoading(true);
    if (!isAdmin) {
      await organizationsAPIs
        .getOrganizations(searchQuery)
        .then((res) => setOrganizations(res?.data))
        .catch((err) => {
          createErrorNotification('Произошла ошибка при получении списка доступных организаций');
        })
        .finally(() => setIsLoading(false));
      return;
    }
    await organizationsAPIs
      .getOrganizationsAdmin(searchQuery)
      .then((res) => setOrganizations(res?.data))
      .catch((err) => {
        createErrorNotification('Произошла ошибка при получении списка доступных организаций');
      })
      .finally(() => setIsLoading(false));
  }, [isAdmin, searchQuery]);

  const setCloseModal = () => {
    toggleOrganizationModal();
    toggleOrganizationModalCloseBtn(false);
  };

  const handleSearchTextChange = (value) => {
    setSearchQuery(value);
  };

  const handleSelectOrganization = useCallback(
    (org) => {
      setSelectedOrganization(org)
        .then(() => {
          setCloseModal();
          history.push('/');
        })
        .catch((err) => {
          createErrorNotification('Произошла ошибка при выборе организации');
        });
    },
    [history]
  );

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  return (
    <Modal show dialogClassName="min-[1024px]:tw-max-w-[500px] ">
      <div
        className={`tw-bg-white tw-rounded-lg tw-flex tw-flex-col tw-relative tw-pb-6 tw-pt-[52px] tw-min-h-[350px] tw-max-h-[97vh] sm:tw-max-h-[600px]`}
      >
        <CloseIcon
          className="tw-cursor-pointer tw-absolute tw-top-[14px] tw-right-[16px]"
          onClick={() => setCloseModal()}
        />
        <div className="tw-flex tw-flex-col tw-items-start tw-text-[#191919] tw-mb-2 tw-px-6 tw-leading-[18px]">
          <p className="tw-font-semibold">Выберите организацию</p>
          <p className="tw-text-sm tw-text-[#666666]">Вы всегда можете поменять организацию из настроек.</p>
        </div>
        <Form onSubmit={() => {}}>
          {() => (
            <>
              <SearchInput
                name="search-organization"
                className="tw-mb-4 tw-px-6"
                onSearchChange={handleSearchTextChange}
                placeholder="Название организации"
                type="text"
              />
            </>
          )}
        </Form>
        <div
          className={`tw-flex tw-flex-col tw-gap-[15px] tw-overflow-x-hidden tw-px-6 tw-overflow-y-auto ${
            organizations.length === 0 && 'tw-my-auto'
          }`}
        >
          {isLoading ? (
            <Loader />
          ) : organizations.length > 0 ? (
            organizations.map((organization) => (
              <>
                <div
                  key={organization.id}
                  className="tw-bg-[#eee] tw-p-[10px] tw-rounded-lg tw-cursor-pointer tw-transition-colors hover:tw-bg-[#e6e6e6]"
                  onClick={() => {
                    handleSelectOrganization(organization);
                  }}
                >
                  {organization?.name}
                </div>
              </>
            ))
          ) : (
            <>
              <div className="tw-flex tw-flex-row tw-w-full tw-mt-auto tw-items-center tw-justify-center">
                <p>Нет доступных организаций</p>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
