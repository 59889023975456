import { MenuItem, TextField } from '@mui/material';

export const SelectMUI = ({ input: { name, onChange, value, className, ...restInput }, options, ...rest }) => {
  return (
    <TextField
      {...rest}
      select
      name={name}
      inputProps={restInput}
      onChange={onChange}
      value={value}
      className={`tw-w-full`}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};
