import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import DeleteIcon from '../../icons/delete';
import { deleteEmailModification } from '../../store/actions/organizations';
import { FREQUENCY_OPTIONS_LABEL } from '../../utils/constant';
import Loader from '../loader';
import classes from './view-modification-modal.module.sass';

export default function ViewModificationModal({ isLoading, show, onHide, receivers = [], fetchReceivers }) {
  const handleDeleteEmail = async (email) => {
    const success = await deleteEmailModification(email);
    if (success) {
      fetchReceivers();
    }
  };

  if (!show) {
    return null;
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Row className={classes.container}>
        <Row>
          <Col xs={6}>
            <h5 style={{ marginBottom: 12 }}>
              <b>Почта</b>
            </h5>
          </Col>
          <Col xs={5}>
            <h5 style={{ marginBottom: 12 }}>
              <b>Периодичность</b>
            </h5>
          </Col>
        </Row>
        {receivers.map((item, i) => (
          <Row key={i} className={classes.row}>
            <Col data-tip data-for={i.toString()} xs={6} className="h-100">
              {item.email}
            </Col>

            <Col xs={5}>
              {Object.values(FREQUENCY_OPTIONS_LABEL).find((_, i) => i === parseInt(item.send_email_day))}
            </Col>
            <Col xs={1} className={classes.deleteBtn} onClick={() => handleDeleteEmail(item.email)}>
              <DeleteIcon />
            </Col>
          </Row>
        ))}
        {isLoading && <Loader />}
      </Row>
    </Modal>
  );
}
