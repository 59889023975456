import classes from './search-filters.module.sass';
// import FilterIcon from '../../icons/filter';
// import ChevronDownIcon from '../../icons/chevron-down';
import { ReactComponent as SortIcon } from '../../icons/sortingIcon.svg';
import { ReactComponent as ArrowIcon} from '../../icons/arrowDownIcon.svg'
import Button from '../button';
import queryString from 'query-string';
import { MenuItem, Select } from '@mui/material';
import { useState } from 'react';

const filterList = [
  { name: 'Наименование', value: 'name' },
  { name: 'Порядок добавления', value: 'created_at' },
  { name: 'Цена', value: 'price' },
];
const selectFilterList = [
  { id: 1, name: 'Наименование (возрастание)', value: 'name', sort_order: 'asc' },
  { id: 2, name: 'Наименование (убывание)', value: 'name', sort_order: 'desc' },
  { id: 3, name: 'Порядок добавления (возр.)', value: 'created_at', sort_order: 'asc' },
  { id: 4, name: 'Порядок добавления (убыв.)', value: 'created_at', sort_order: 'desc' },
  { id: 5, name: 'Цена (возрастание)', value: 'price', sort_order: 'asc' },
  { id: 6, name: 'Цена (убывание)', value: 'price', sort_order: 'desc' },
];
export default function SearchFilters({ reportId, filters, onChange, setPriceFilter }) {
  const [currentSort, setCurrentSort] = useState(3);
  const [currentPriceFilter, setCurrentPriceFilter] = useState('min_price');
  const handleFilterChange = (sort_by) => {
    let sort_order = 'desc';
    const isCurrent = filters.sort_by === sort_by;
    if (isCurrent && filters.sort_order !== 'asc') {
      sort_order = 'asc';
    }
    let query = {};
    if (sort_by) query.sort_by = sort_by;
    if (sort_order) query.sort_order = sort_order;

    onChange({ ...filters, sort_by, sort_order });
    window.history.replaceState(null, '', `/projects/${reportId}?${queryString.stringify(query)}`);
  };

  const handleSelectFilterChange = (option) => {
    const { value: sort_by, sort_order } = selectFilterList[option];
    const query = {
      sort_by: sort_by,
      sort_order: sort_order,
    };
    onChange({ ...filters, sort_by, sort_order });
    window.history.replaceState(null, '', `/projects/${reportId}?${queryString.stringify(query)}`);
    setCurrentSort(option);
  };

  const handleButtonChange = (filter) => () => {
    setPriceFilter(filter);
  };

  const buttons = [
    { label: 'Мин. стоимость', onClick: handleButtonChange('min_price'), price: 'min_price' },
    { label: 'Сред. стоимость', onClick: handleButtonChange('avg_price'), price: 'avg_price' },
    { label: 'Макс. стоимость', onClick: handleButtonChange('max_price'), price: 'max_price' },
    // { label: 'Медианная стоимость', onClick: handleButtonChange('price') },
  ];
  const selectButtons = [
    { label: 'Минимальная стоимость', value: 'min_price' },
    { label: 'Средняя стоимость', value: 'avg_price' },
    { label: 'Максимальная стоимость', value: 'max_price' },
  ];
  return (
    <div className={classes.root}>
      <div className="tw-hidden lg:tw-flex tw-flex-row tw-gap-4">
        {filterList.map(({ name, value }, i) => {
          const isCurrent = filters.sort_by === value;
          const isAsc = isCurrent && filters.sort_order === 'asc';
          return (
            <div className={classes.filterItem} key={i} onClick={() => handleFilterChange(value)}>
              <p className={`${classes.filterName} ${isCurrent && 'tw-text-[#0844B4]'} tw-transition-colors`}>{name}</p>
              <div className={classes.icon}>
                <SortIcon
                  className={`${isAsc && classes.revert} ${classes.icon} ${
                    isCurrent && 'tw-fill-[#0844B4]'
                  } tw-transition-colors`}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="lg:tw-hidden">
        <Select
        IconComponent={ArrowIcon}
          value={currentSort}
          className="tw-bg-white tw-rounded-lg"
          sx={{
            fieldset: {
              borderColor: 'transparent',
            },
            svg: {
              top: 'calc(50% - 0.7em)',
              transition: 'all 0.2s linear'
            }
          }}
          onChange={(e) => handleSelectFilterChange(e.target.value)}
        >
          {selectFilterList.map((selectFilter, index) => {
            return (
              <MenuItem key={selectFilter.name} value={index}>
                {selectFilter.name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div className="tw-w-[1px] tw-h-[32px] tw-bg-[#D5D5D5] lg:tw-block tw-hidden" />
      <div className="tw-hidden lg:tw-flex tw-flex-row tw-gap-4">
        {buttons.map((button) => (
          <Button
            className={`tw-max-w-[fit-content] tw-text-sm tw-font-medium tw-text-[#666666] hover:tw-bg-transparent hover:tw-text-[#0A50D4] tw-transition-colors tw-p-0`}
            variant="text"
            onClick={button.onClick}
            key={button.label}
          >
            {button.label}
          </Button>
        ))}
      </div>
      <div className="lg:tw-hidden">
        <Select
          IconComponent={ArrowIcon}
          value={currentPriceFilter}
          className="tw-bg-white tw-rounded-lg"
          sx={{
            fieldset: {
              borderColor: 'transparent',
            },
            svg: {
              top: 'calc(50% - 0.7em)',
              transition: 'all 0.2s linear'
            }
          }}
          onChange={(e) => {
            setPriceFilter(e.target.value);
            setCurrentPriceFilter(e.target.value);
          }}
        >
          {selectButtons.map((button) => {
            return (
              <MenuItem key={button.label} value={button.value}>
                {button.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
}
