import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { createProject } from '../../../store/actions/organizations';
import classes from '../landing.module.sass';
import Button from '../../../components/button';
import { projectsAPIs } from '../../../services';
import { useDialog } from '../../../providers/dialog.provider';
import { CreateProjectDialog } from './CreateProjectDialog';
import { useCallback } from 'react';
import { priceFormatter } from '../../../utils/priceFormatter';

function CreateProjectForm() {
  const [projectsAmount, setProjectsAmount] = useState(0);
  const dialog = useDialog();
  const organizationId = localStorage.getItem('organizationId');
  const handleOpenDialog = useCallback(() => {
    dialog.open(<CreateProjectDialog title="Создание проекта" value={{ name: '' }} onSubmit={onSubmit} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const onSubmit = async ({ name, region_id }, form) => {
    const project = await createProject({ name, region_id });
    if (!project) {
      return { name: 'Такой проект уже существует' };
    }

    dialog.close();
    history.push(`/projects/${project.id}`);
  };
  useEffect(() => {
    projectsAPIs.getAllProjects(organizationId, 3, 1, '').then((res) => {
      setProjectsAmount(res.count);
      setProjects(res.data);
    });
  }, [organizationId]);
  const renderStatus = (status) => {
    switch (status) {
      case 'failed':
        return (
          <span className="tw-px-[0.625rem] tw-py-[0.3125rem] tw-rounded-[9px] tw-bg-[#FFEAEA] tw-text-[#FF4343] tw-text-xs tw-font-medium tw-leading-[16px]">
            Прервано
          </span>
        );
      case 'initial':
      case 'processing':
        return (
          <span className="tw-px-[0.625rem] tw-py-[0.3125rem] tw-rounded-[9px] tw-bg-[#FFF8EC] tw-text-[#FEB945] tw-text-xs tw-font-medium tw-leading-[16px]">
            В процессе
          </span>
        );
      case 'finished':
        return (
          <span className="tw-px-[0.625rem] tw-py-[0.3125rem] tw-rounded-[9px] tw-bg-[#EEFCEE] tw-text-[#49CA4E] tw-text-xs tw-font-medium tw-leading-[16px]">
            Завершено
          </span>
        );
      default:
        return (
          <span className="tw-px-[0.625rem] tw-py-[0.3125rem] tw-rounded-[9px] tw-text-xs tw-font-medium tw-bg-[#EEE] tw-text-[#7F7F7F] tw-leading-[16px]">
            Статус
          </span>
        );
    }
  };
  return (
    <div
      className="tw-relative tw-z-20 tw-overflow-hidden tw-p-4 tw-h-full tw-bg-white tw-rounded-xl"
      style={{ background: '' }}
    >
      <div className="tw-flex tw-flex-col tw-h-full">
        <h2 className={` mb-2 tw-font-bold tw-text-2xl tw-leading-[28px]`}>Проекты</h2>
        <div className="tw-flex">
          <p className="tw-text-[#666] tw-font-medium tw-text-sm tw-leading-[18px]">Всего проектов</p>
          <Link
            to="/projects"
            className="tw-text-[#0844b4] hover:tw-text-[#0A50D4] tw-font-semibold d-flex ms-auto text-decoration-none tw-leading-[18px]"
          >
            Все
          </Link>
        </div>
        <h2 className="tw-font-bold tw-text-[2.5rem] tw-text-[#191919] tw-leading-[48px]">{projectsAmount}</h2>
        <p className="tw-text-[#666] tw-text-14 tw-mt-[12px] tw-mb-[1px] tw-font-medium tw-text-sm tw-leading-[18px]">
          Последние проекты
        </p>
        <div className="tw-flex tw-divide-y tw-flex-col tw-mb-2">
          {projects.map((project) => {
            return (
              <>
                <div className="tw-flex tw-flex-col tw-gap-2 tw-py-2" key={project.id}>
                  <div className="tw-flex tw-flex-row tw-gap-[0.5rem] tw-items-center">
                    <Link
                      to={`/projects/${project.id}`}
                      className="tw-font-medium tw-text-[#0844B4] hover:tw-text-[#0A50D4] tw-text-base tw-max-w-[221px] tw-truncate  tw-leading-[18px]"
                    >
                      {project.name}
                    </Link>
                    {renderStatus(project.report_status)}
                  </div>
                  <div className="tw-flex tw-flex-row tw-gap-5 tw-items-center">
                    <div className="tw-flex tw-flex-col tw-gap-[2px] tw-items-start">
                      <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-medium">Позиций</p>
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
                        {project.product_count}
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[2px] tw-items-start">
                      <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-medium">Цена</p>
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
                        {priceFormatter(project.price)}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <Button className={'tw-mt-auto'} onClick={handleOpenDialog}>
          Создать новый проект
        </Button>
      </div>
    </div>
  );
}

export default CreateProjectForm;
