import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GeneralLayout from '../../components/general-layout';
import Loader from '../../components/loader';
import { chatsAPIs, suppliersAPIs } from '../../services';
import RightActions from './components/RightActions';
// import Suppliers from './components/Suppliers';
import classes from './suppliers.module.sass';
import { ReactComponent as MessageIcon } from '../../icons/messageIcon.svg';
import { CrudTable, useCrudEntity } from '../../components/table/CrudTable';
import { usePagination } from '../../components/table/usePagination';
import { Pagination } from '../../components/table/pagination';
import { useDialog } from '../../providers/dialog.provider';
import { InfoDialog } from './components/InfoDialog';
import { useHistory } from 'react-router-dom';
import { MailingDialog } from './components/MailingDialog';
import { SearchBar } from './components/SearchBar';
import { checkIsAdmin } from '../../utils/common';
import { ReactComponent as GoodSupplierIcon } from '../../icons/goodSupplierIcon.svg';
import { Checkbox, Tooltip } from '@mui/material';
import { createErrorNotification } from '../../utils/notifications';
import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg';

export function useOrganizationSuppliers() {
  const organization_id =
    useSelector((store) => store?.organizations?.selected?.id) ?? localStorage.getItem('organizationId');
  return useCallback(
    (page_size, page_num, query) => {
      return suppliersAPIs.getSuppliers(organization_id, page_size, page_num, query);
    },
    [organization_id]
  );
}

function SuppliersPage() {
  const dialog = useDialog();
  const history = useHistory();
  const organizationSuppliers = useOrganizationSuppliers();
  const [categories, setCategories] = useState([]);
  const [choosenCategory, setChoosenCategory] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchRegion, setSearchRegion] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [isGoodSupplier, setIsGoodSupplier] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);

  const handleSendMessage = async (supplier) => {
    await chatsAPIs
      .getSupplierChats(supplier?.id)
      .then(() => {
        history.push(`/chatbot?host=${supplier.host}`);
      })
      .catch((err) => {
        createErrorNotification('Ошибка при попытке перейти в чат');
      });
  };
  const fetchCategories = async () => {
    const result = await suppliersAPIs.getCategories(isGoodSupplier).catch((err) => {
      createErrorNotification('Ошибка при получении категорий');
    });
    setCategories(result?.data);
  };
  const columns = {
    host: {
      label: 'Название',
      renderBodyCell: (value) => {
        return (
          <div
            className="tw-flex tw-flex-row tw-gap-[18px] tw-items-center tw-max-w-[280px]"
            onClick={() => {
              showInfoDialog(value);
            }}
          >
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
              <Tooltip
                title={
                  <>
                    <p className="tw-text-xs">{value.name}</p>
                  </>
                }
                arrow
                placement="top-start"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#1E1B39',
                      borderColor: '#1E1B39',
                      padding: '8px',
                      '& .MuiTooltip-arrow': {
                        color: '#1E1B39',
                      },
                    },
                  },
                }}
              >
                <p className="tw-text-[#0844B4] tw-text-sm tw-font-medium tw-cursor-pointer tw-max-w-[251px] tw-truncate tw-block hover:tw-text-[#0A50D4]">
                  {value.name}
                </p>
              </Tooltip>
              {value?.is_conscientious && (
                <Tooltip
                  title={
                    <>
                      <p className="tw-text-xs">Добросовестный поставщик</p>
                    </>
                  }
                  arrow
                  placement="top-start"
                  slotProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#1E1B39',
                        borderColor: '#1E1B39',
                        padding: '8px',
                        '& .MuiTooltip-arrow': {
                          color: '#1E1B39',
                        },
                      },
                    },
                  }}
                >
                  <GoodSupplierIcon />
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
    category: {
      label: 'Категория',
    },
    region: {
      label: 'Область',
    },
  };
  const crudHead = () => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-[4px] tw-justify-end" onClick={() => showMailingDialog()}>
        <MessageIcon className="tw-w-6 tw-h-6" />
        <p className="tw-text-sm tw-font-medium tw-text-[#0844B4] tw-cursor-pointer">Создать рассылку</p>
      </div>
    );
  };
  const crudButtons = (entity) => {
    return (
      <>
        <div className="tw-flex tw-flex-row tw-gap-[4px] tw-justify-end" onClick={() => handleSendMessage(entity)}>
          <MessageIcon className="tw-w-6 tw-h-6" />
          <p className="tw-text-sm tw-font-medium tw-text-[#0844B4] tw-cursor-pointer">Написать</p>
        </div>
      </>
    );
  };
  const suppliersCrudAdapter = useCrudEntity({
    readAll: organizationSuppliers,
  });
  const {
    data: paginatedData,
    currentPage,
    goToPage,
    total,
    isLoading,
    setPageSize,
    pageSize,
    handleChangeQuery,
  } = usePagination(suppliersCrudAdapter.readAll, 1, 50);

  const handleSubmit = (searchText = '', region = '') => {
    setSearchText(searchText);
    setSearchRegion(region);
  };
  useEffect(() => {
    setSuppliers(paginatedData);
  }, [paginatedData]);

  useEffect(() => {
    handleChangeQuery(
      `${searchText}&region=${searchRegion}&category=${choosenCategory !== '' ? choosenCategory : ''}${
        isGoodSupplier ? '&is_conscientious=true' : ''
      }`
    );
    fetchCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenCategory, searchText, searchRegion, isGoodSupplier]);
  const showInfoDialog = useCallback(
    (supplier) => {
      dialog.open(<InfoDialog entity={supplier} />);
    },
    [dialog]
  );
  const showMailingDialog = useCallback(
    () => dialog.open(<MailingDialog categories={categories} />),
    [dialog, categories]
  );
  const handleCategoryChange = (category_name) => {
    setChoosenCategory(category_name);
  };
  const handleChecboxChange = (e) => {
    setIsGoodSupplier(e.target.checked);
  };
  return (
    <GeneralLayout mainClass={classes.root} fullWidth={true} fixHeight={true}>
      <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-py-8 tw-w-full tw-max-w-9xl tw-mx-auto">
        <div className="sm:tw-flex sm:tw-justify-between sm:tw-items-center tw-mb-8">
          <h1 className="tw-text-2xl md:tw-text-3xl tw-text-gray-800 tw-font-bold">Поставщики </h1>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-[18px] tw-mb-[40px]">
          <div className="tw-flex tw-flex-row tw-gap-8 tw-items-center tw-w-full ">
            <SearchBar categories={categories} onSubmit={handleSubmit} handleChangeCategory={handleCategoryChange} />
            <RightActions
              isLoading={isLoading}
              setSearchText={handleChangeQuery}
              onSubmitAdd={(newSup) => setSuppliers([newSup].concat(suppliers))}
              isAdmin={isAdmin}
            />
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-bg-white tw-gap-2 tw-px-4 tw-py-[14px] tw-rounded-lg">
            <Checkbox
              sx={{
                padding: 0,
              }}
              checked={isGoodSupplier}
              onChange={handleChecboxChange}
            />
            <p>Добросовестный поставщик</p>
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-text-[#191919] tw-font-medium tw-text-sm">
          {choosenCategory !== '' && (
            <div className="tw-flex tw-flex-row tw-items-center tw-mb-8 tw-bg-white tw-rounded-lg tw-px-4 tw-py-[12px] tw-gap-2">
              <p className="tw-font-semibold">{choosenCategory}</p>
              <CloseIcon className="tw-cursor-pointer" onClick={() => setChoosenCategory('')} />
            </div>
          )}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={`tw-bg-white tw-rounded-[10px] tw-py-1 tw-px-2 ${suppliers.length === 0 && 'tw-pb-5'}`}>
            <CrudTable rows={suppliers} entityColumns={columns} crudButtons={crudButtons} crudHead={crudHead} />
          </div>
        )}

        <Pagination
          rowsPerPage={pageSize}
          count={total}
          page={currentPage}
          onPageChange={goToPage}
          onLimitChange={setPageSize}
          range={[50, 100]}
        />
      </div>
    </GeneralLayout>
  );
}

export default SuppliersPage;
