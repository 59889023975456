import { Dialog } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { useState } from 'react';
import Button from '../../../button';
import { ReactComponent as ArrowLeft } from '../../../../icons/arrowLeftIcon.svg';
import { Field, Form } from 'react-final-form';
import Input from '../../../input';
import { SelectMUI } from '../../../selectMUI';;

export const CALCULATION_FROM = {
  source: 'из источника',
  default: 'по умолчанию',
  user_data: 'введенными вами данными',
  catalogue: 'из справочника габаритов'
}

export const SourceDetail = ({ productName, item, product_id, project_id, onSubmit}) => {
  const dialog = useDialog();
  const [isEditing, setIsEditing] = useState(false);

  const getUrlDomen = (url) => {
    try {
      const path = new URL(url);
      return path.hostname;
    } catch {
      return null;
    }
  };
  const handleFormSubmit = async (values) => {

    let { correct_height, correct_length, correct_weight, correct_width, size_units, weight_units } = values;
    const organization_id = localStorage.getItem('organizationId');
    if (size_units === 'Метр') {
      correct_height = correct_height * 100;
      correct_width = correct_weight * 100;
      correct_length = correct_length * 100;
    }
    if (weight_units === 'кг') {
      correct_weight = correct_weight * 1000;
    }
    const body = {
      correct_height: +correct_height,
      correct_length: +correct_length,
      correct_weight: +correct_weight,
      correct_width: +correct_width,
      height: item?.height,
      length: item?.length,
      organization_id,
      product_id,
      project_id,
      url: item?.url,
      weight: item?.weight,
      width: item?.width,
      name: item?.title
    };
    await onSubmit(body).then(() => {
      setIsEditing(false)
      dialog.close()
    })
  };
  const DefaultView = () => {
    return (
      <>
        <h4 className="tw-text-[#191919] tw-text-base tw-leading-5 tw-font-semibold tw-mb-6">
          Характеристики товара {productName}
        </h4>
        <div className="tw-flex tw-flex-col tw-gap-6 tw-items-start tw-mb-6">
          <div className="tw-flex tw-flex-row tw-gap-[14px]">
            <img
              src={item?.pics[0]}
              alt=""
              loading="lazy"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/images/emptyProduct.png';
              }}
              className="tw-rounded-[4px] tw-w-[70px] tw-h-[70px] tw-aspect-square tw-object-cover"
            />
            <div className="tw-flex tw-flex-col tw-gap-[5px] tw-items-start">
              <a
                href={item?.url}
                className="tw-text-[#0844B4] tw-text-sm tw-font-medium tw-leading-[18px] tw-cursor-pointer"
              >
                {getUrlDomen(item?.url)}
              </a>
              <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-max-w-[497px]">{item?.title}</p>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-mb-5">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-[22px]">
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Длина (см)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.length}</p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Высота (см)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.height}</p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Ширина (см)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.width}</p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Вес (гр)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.weight}</p>
            </div>
          </div>
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Расчет габаритов был произведен {CALCULATION_FROM[item?.dimensions_from]}</p>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-w-full tw-justify-end tw-gap-6 tw-mt-auto">
          <Button
            className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-leading-[18px] tw-text-sm hover:tw-text-[#7F7F7F]"
            variant="text"
            onClick={() => dialog.close()}
          >
            Закрыть
          </Button>
          <Button
            className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-leading-[18px]"
            onClick={() => setIsEditing(true)}
          >
            Пожаловаться на габариты
          </Button>
        </div>
      </>
    );
  };

  const EditingView = () => {
    return (
      <>
        <h4 className="tw-text-[#191919] tw-text-base tw-leading-5 tw-font-semibold tw-mb-6">
          Жалоба на габариты товара
        </h4>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-mb-8">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-[22px]">
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Длина (см)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.length}</p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Высота (см)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.height}</p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Ширина (см)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.width}</p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
              <p className="tw-text-xs tw-leading-[18px] tw-text-[#666666] tw-font-medium">Вес (гр)</p>
              <p className="tw-text-[#191919] tw-text-lg tw-leading-[22px] tw-font-semibold">{item?.weight}</p>
            </div>
          </div>
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium"></p>
        </div>
        <Form onSubmit={(value) => handleFormSubmit(value)}>
          {({ handleSubmit, form, values }) => (
            <>
              <div className="tw-flex tw-flex-col tw-gap-[18px] ">
                <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-semibold">
                  Укажите нужные значения
                </p>

                <form onSubmit={handleSubmit}>
                  <div className="tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-6 tw-mb-[62px]">
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Длина</p>
                      <Input form={form} value={values['correct_length']} name="correct_length" type="number" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Высота</p>
                      <Input form={form} value={values['correct_height']} name="correct_height" type="number" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ширина</p>
                      <Input form={form} value={values['correct_width']} name="correct_width" type="number" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Макс. вес</p>
                      <Input form={form} value={values['correct_weight']} name="correct_weight" type="number" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ед. размера</p>
                      <div className="tw-w-[116px]">
                        <Field name="size_units" component={SelectMUI} form={form} options={['Метр', 'см']} required />
                      </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start tw-max-w-[116px]">
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ед. массы</p>
                      <div className="tw-w-[116px]">
                        <Field name="weight_units" component={SelectMUI} form={form} options={['кг', 'г']} required />
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-6 tw-mt-auto">
                    <Button
                      className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-leading-[18px] tw-text-sm hover:tw-text-[#7F7F7F]"
                      variant="text"
                      onClick={() => setIsEditing(false)}
                    >
                      <ArrowLeft className="tw-rotate-180" />
                      Назад
                    </Button>
                    <Button
                      className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-leading-[18px]"
                      type="submit"
                    >
                      Отправить жалобу
                    </Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </Form>
      </>
    );
  };

  return (
    <>
      <Dialog open={dialog.visibility} className="tw-rounded-xl">
        <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-xl tw-p-6 md:tw-min-h-[376px] md:tw-min-w-[576px]">
          {!isEditing ? <DefaultView /> : <EditingView />}
        </div>
      </Dialog>
    </>
  );
};
