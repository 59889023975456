import { request } from '../utils/request';

export const projectsAPIs = {
  getAllProjects: (organization_id, page_size = 10, page_num = 1, query = '') =>
    request(
      `/api/organizations/${organization_id}/projects?page_size=${page_size}&page_num=${page_num}&query=${query}`,
      {
        method: 'GET',
      }
    ),
  updateProject: (id, body) =>
    request(`/api/projects/${id}`, {
      method: 'PATCH',
      body: body,
    }),
  deleteProject: (id) =>
    request(`/api/projects/${id}`, {
      method: 'DELETE',
    }),
  createProject: (body) =>
    request(`/api/projects`, {
      method: 'POST',
      body: body,
    }),
  stopSearch: (project_id) =>
    request(`/api/communications/cancel/${project_id}`, {
      method: 'GET',
    }),
  restartSearch: (project_id) =>
    request(`/api/communications/refind/${project_id}`, {
      method: 'GET',
    }),
  incorrectDimensions: (body) =>
    request('/api/admin/incorrect_dimensions', {
      method: 'POST',
      body,
    }),
};
