export default function LockIcon({ className }) {
  return (
    <svg
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="484.000000pt"
      height="500.000000pt"
      viewBox="0 0 484.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2270 4194 c-93 -14 -134 -24 -195 -47 -283 -104 -514 -351 -610
-652 -41 -130 -50 -208 -50 -426 l1 -207 -64 -5 c-79 -6 -124 -38 -164 -117
l-28 -54 1 -775 c0 -569 4 -784 12 -806 22 -56 50 -92 90 -119 l41 -26 1116 0
1116 0 42 28 c30 19 51 44 70 82 l27 55 3 778 c2 776 2 778 -19 827 -39 91
-106 130 -221 130 l-58 0 0 181 c0 174 -9 275 -32 369 -49 204 -146 376 -290
516 -169 164 -341 246 -578 274 -99 12 -100 12 -210 -6z m280 -361 c248 -59
432 -262 460 -506 6 -45 10 -169 10 -274 l0 -193 -630 0 -630 0 0 254 c0 242
1 258 24 325 33 98 68 154 145 231 159 158 389 219 621 163z"
        />
      </g>
    </svg>
  );
}
