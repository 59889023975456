import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import classes from './input.module.sass';
import { ReactComponent as CancelIcon } from '../../icons/closeIcon.svg';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';

const InputField = styled(TextField)(() => ({
  maxHeight: '46px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    input: {
      color: '#333333',
      fontSize: '0.875rem',
      padding: '14px 16px',
    },
    fieldset: {
      border: '1px solid #CCC',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #D5D5D5',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#CCC',
    },
    '&:focus fieldset': {
      borderColor: '#000',
    },
  },
}));

export default function Input({
  name,
  value,
  placeholder,
  className,
  sx,
  type = 'text',
  required,
  disabled = false,
  inlineError,
  SearchProps,
  autoFocus = false,
  inputRef,
  errorStyle
}) {
  const { input, meta } = useField(name);

  const hasError = useMemo(() => {
    return meta.touched && (meta.error || meta.submitError);
  }, [meta]);

  const isClearable = useMemo(() => {
    return (input.value && input.value !== '') || (value && value !== '');
  }, [input.value]);

  const clearInput = useCallback(() => {
    input.onChange('');
  }, [input]);

  return (
    <div className={cx(className, classes.container)}>
      <InputField
        sx={sx}
        type={type}
        placeholder={placeholder}
        required={required}
        value={value ?? input.value}
        disabled={disabled}
        fullWidth
        inputRef={inputRef}
        autoFocus={autoFocus}
        error={meta.error}
        {...input}
        variant="outlined"
        InputProps={{
          endAdornment: isClearable && <CancelIcon onClick={clearInput} />,
          startAdornment: SearchProps?.startAdornment,
        }}
      />
      {hasError && (
        <span className={`${classes.error} ${errorStyle}`} style={{ position: inlineError && 'relative' }}>
          {meta.error || meta.submitError}
        </span>
      )}
    </div>
  );
}
