import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Category from '../../components/analytics/Category';
import Button from '../../components/button';
import GeneralLayout from '../../components/general-layout';
import ProductStatisticCardChart from '../../components/product-statistics-card-chart';
import { statisticsAPIs } from '../../services';
import classes from './statistics-category.module.sass';
import VisibilitySensor from 'react-visibility-sensor';
import Loader from '../../components/loader';
import RangePicker from '../../components/range-picker';
import { AnalyticsSearchBar } from '../../components/analytics-searchbar/AnalyticsSearchBar';
import Host from '../../components/analytics/Host';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../icons/arrowRightIcon.svg';
import { Skeleton } from '@mui/material';

export default function StatisticsCategoryPage({ onShowCategoriesModal }) {
  const { id } = useParams();
  const [categories, setCategories] = useState();
  // const history = useHistory();

  const organization = useSelector((state) => state.organizations.selected);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month').startOf('hour').unix() * 1000,
    till: moment().startOf('hour').unix() * 1000,
  });

  const [products, setProducts] = useState([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [productQuery, setProductQuery] = useState({
    since: moment().subtract(1, 'month').startOf('hour').unix() * 1000,
    till: moment().startOf('hour').unix() * 1000,
    page_num: 1,
    page_size: 20,
  });

  const [selectedHost, setSelectedHost] = useState(-1);
  const [hostData, setHostData] = useState(null);

  const hosts = data?.top_hosts || {};
  const hostKeys = Object.keys(hosts).sort((a, b) => hosts[b] - hosts[a]);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const params = queryString.stringify({
        ...productQuery,
        query: searchText,
        organization_id: organization.id,
      });
      const response = await statisticsAPIs.getProductCategoryStatistics(id, params);
      setProducts([...(products || []), ...(response.data || [])]);
      setCanLoadMore(response.data.length === 20);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  function handleLoadMore(isVisible) {
    if (isVisible && !isLoading) {
      const newFilters = {
        ...productQuery,
        page_num: productQuery.page_num + 1,
      };
      setProductQuery(newFilters);
    }
  }

  const fetchData = async () => {
    try {
      const params = queryString.stringify({
        ...queryParams,
        query: searchText,
        organization_id: organization.id,
      });
      const response = await statisticsAPIs.getCategoryStatistics(id, params);
      await statisticsAPIs.getCategoriesStatistics().then((res) => setCategories(res.categories));
      setData(response);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHostData = async () => {
    try {
      const params = queryString.stringify({
        ...queryParams,
        host: hostKeys[selectedHost],
        organization_id: organization.id,
      });
      const rawHost = await statisticsAPIs.getHostCategoryStatistics(id, params);
      setHostData(rawHost);
    } catch (e) {
      console.error(e);
    }
  };

  const handleShowAllSources = () => {
    setHostData(null);
    setSelectedHost(-1);
  };

  const handleSearchSubmit = (search) => {
    setSearchText(search);
    setProductQuery({ ...productQuery, page_num: 1 });
    setProducts([]);
  };
  useEffect(() => {
    if (id && organization) {
      fetchData();
    }
  }, [id, organization, queryParams]);

  useEffect(() => {
    if (id && organization) {
      fetchProducts();
    }
  }, [id, organization, productQuery, searchText]);

  useEffect(() => {
    if (id && organization && selectedHost >= 0 && hostKeys?.[selectedHost]) {
      fetchHostData();
    }
  }, [id, organization, queryParams, selectedHost]);

  return (
    <GeneralLayout>
      <h2 className="tw-text-2xl md:tw-text-3xl tw-text-gray-800 tw-font-bold tw-mb-8">Аналитика</h2>
      <div className="tw-flex tw-flex-row tw-mb-8">
        <AnalyticsSearchBar
          formValues={{ query: '' }}
          categories={categories && categories}
          onSubmit={handleSearchSubmit}
        />
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-mb-[31px]">
        <Link to="/statistics" className="tw-text-[#191919] tw-text-sm tw-font-medium">
          Аналитика
        </Link>
        <ArrowRight className="tw-fill-[#191919]" />
        <Link to={`/statistics/${id}`} className="tw-text-[#191919] tw-text-sm tw-font-medium">
          {data?.category?.name}
        </Link>
      </div>
      <div className="sm:tw-flex sm:tw-justify-between sm:tw-items-center tw-flex-wrap xl:tw-flex-nowrap tw-mb-6 tw-gap-2">
        <RangePicker queryParams={queryParams} setQueryParams={setQueryParams} />
      </div>
      <div className="tw-grid tw-grid-cols-12 tw-gap-6 tw-bg-white tw-pt-[20px] tw-pl-[24px] tw-pb-[14px] tw-pr-[25px] tw-rounded-lg">
        <div className="tw-flex tw-flex-col tw-col-span-full xl:tw-col-span-4 tw-max-h-[440px] ">
          <header className="tw-mb-[20px] tw-flex tw-flex-row tw-justify-between tw-text-base tw-text-[#191919] tw-font-medium">
            <h2>Источники</h2>
            <h2>Число товаров</h2>
          </header>
          <div className="tw-flex tw-flex-col tw-overflow-y-auto">
            {isLoading ? (
              <div className="tw-flex tw-flex-row tw-justify-between">
                <Skeleton variant="text" width={150} /> <Skeleton variant="text" width={150} />{' '}
              </div>
            ) : (
              hostKeys.map((hostKey, index) => {
                return (
                  <div
                    className={` ${
                      selectedHost === index && 'tw-bg-[#F9FCFF]'
                    } tw-flex tw-flex-row tw-justify-between tw-text-sm hover:tw-bg-[#F9FCFF] tw-border-b tw-border-[#D9D9D9] last-of-type:tw-border-0 tw-py-3`}
                    key={index}
                    onClick={() => setSelectedHost(index)}
                  >
                    <p className="tw-text-[#191919] tw-pl-3">{hostKey}</p>
                    <div className="tw-flex tw-flex-row tw-gap-[1px] tw-items-center">
                      <p className="tw-text-[#666666]">{hosts[hostKey]}</p>
                      {selectedHost === index ? (
                        <ArrowRight className="tw-w-[24px] tw-h-[24px] tw-fill-[#666666]" />
                      ) : (
                        <div className="tw-h-[24px] tw-w-[24px] tw-bg-transparent" />
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        {!hostData && data?.category && <Category category={data.category} />}
        {!data?.category && (
          <div className="tw-flex tw-flex-col tw-items-start tw-col-span-full xl:tw-col-span-8">
            <Skeleton variant="text" width={100} height={20} className="tw-mt-[18px]" />
            <Skeleton variant="text" width={100} height={18} />
            <div className="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-[14px]">
              <Skeleton variant="text" width={50} height={38} />
              <Skeleton variant="text" width={100} height={18} />
            </div>
            <Skeleton variant="rounded" width={'100%'} height={245} />
          </div>
        )}
        {/* FIX: С бэка нет данных для точек */}
        {/* {hostData && (
          <Host
            host={hostData}
            name={hostKeys[selectedHost]}
            customRightTopElement={
              <Button size="small" secondary className="tw-h-fit" onClick={handleShowAllSources}>
                Все источников
              </Button>
            }
          />
        )} */}
      </div>
      <div>
        {products.map((product, i) => (
          <div className={classes.savedSources} key={i}>
            <ProductStatisticCardChart product={product} index={i} />
          </div>
        ))}
      </div>

      {canLoadMore && (
        <VisibilitySensor onChange={handleLoadMore}>
          {/* <Loader /> */}
          <div className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-lg tw-mt-4">
            <div className="tw-flex tw-flex-row tw-items-start tw-w-full tw-justify-between">
              <div className="tw-flex tw-flex-row tw-pt-4 tw-pl-4 tw-gap-[23px] tw-w-full">
                <Skeleton variant="rounded" width={120} height={120} />
                <Skeleton variant="text" height={20} width={'40%'} />
              </div>
              <div className="tw-flex tw-flex-row tw-gap-6">
                <div className="tw-bg-[#D9D9D9] tw-w-[1px] tw-h-[226px]"></div>
                <div className="tw-flex tw-flex-col tw-items-start tw-min-w-[220px] tw-pt-6 tw-pr-6 tw-pb-[17px]">
                  <div className="tw-flex tw-flex-col tw-mb-[15px] tw-w-full">
                    <Skeleton variant="text" height={18} width={'100%'} />
                    <Skeleton variant="text" height={28} width={'100%'} />
                  </div>
                  <div className="tw-flex tw-flex-col tw-mb-[18px] tw-w-full">
                    <Skeleton variant="text" height={18} width={'100%'} />
                    <Skeleton variant="text" height={20} width={'100%'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      )}
    </GeneralLayout>
  );
}
