import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { Form } from 'react-final-form';
import Input from '../../../input';
import Button from '../../../button';
import { createErrorNotification } from '../../../../utils/notifications';
import { useCallback } from 'react';

export const AddSupplier = ({ onSubmit }) => {
  const dialog = useDialog();
  const handleFormSubmit = useCallback(async (payload) => {
    const {url, price} = payload
    try {
        onSubmit && await onSubmit(price, url).then(() => dialog.close())
    }
    catch (err) {
        console.error(err)
        createErrorNotification('Что-то пошло не так')
    }
  }, [dialog, onSubmit])
  return (
    <Dialog open={dialog.visibility}>
      <Form
        initialValues={{ url: '', price: '' }}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, submitting, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-lg lg:tw-w-[440px] tw-flex tw-flex-col ">
              <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">Добавление источника</p>
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mb-14">
                <Input form={form} name="url" type="text" required placeholder={'Ссылка на источник'} />
                <Input form={form} name="price" type="number" required placeholder={'Цена в рублях'} />
              </div>
              <DialogActions className="tw-gap-6 tw-p-0">
                <Button
                  variant="text"
                  className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent"
                  disabled={submitting}
                  onClick={() => dialog.close()}
                >
                  Отменить
                </Button>
                <Button
                  type="submit"
                  className="tw-w-fit tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={!values['url'] || !values['price'] || submitting}
                >
                  Добавить источник
                </Button>
              </DialogActions>
            </div>
          </form>
        )}
      ></Form>
    </Dialog>
  );
};
