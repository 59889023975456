import { Input } from '@mui/material';
import Button from '../../button';
import { useCallback } from 'react';
import { debounce } from 'lodash';

export const AmountToggle = ({ handleChange, currentAmount }) => {
    const onInputChangeDebounced = useCallback(debounce(amount => {
        if (amount <= 0) return
        handleChange(amount)
      }, 100), [])
  return (
    <div className="tw-flex tw-flex-row tw-items-center">
      <Button variant="text" className="tw-p-0 hover:tw-bg-transparent tw-w-fit" onClick={() => onInputChangeDebounced(currentAmount - 1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <rect width="28" height="28" rx="4" fill="#F7F7F7" />
          <path
            d="M22.1615 14.1615C22.1615 14.7137 21.7137 15.1615 21.1615 15.1615H6.82812C6.27584 15.1615 5.82812 14.7137 5.82812 14.1615V13.8281C5.82812 13.2758 6.27584 12.8281 6.82812 12.8281H21.1615C21.7137 12.8281 22.1615 13.2758 22.1615 13.8281V14.1615Z"
            fill="#0A50D4"
          />
        </svg>
      </Button>
      <Input
        value={currentAmount}
        onChange={(e) => onInputChangeDebounced(e.target.value)}
        className="tw-w-[46px] tw-border-none tw-text-center"
        disableUnderline
        sx={{
          '& .MuiInput-input': {
            textAlign: 'center !important',
          },
        }}
      />
      <Button variant="text" className="tw-p-0 hover:tw-bg-transparent tw-w-fit" onClick={() => onInputChangeDebounced(currentAmount + 1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <rect width="28" height="28" rx="4" fill="#F7F7F7" />
          <path
            d="M21 14C21 14.2228 20.9115 14.4364 20.754 14.594C20.5964 14.7515 20.3828 14.84 20.16 14.84H14.84V20.16C14.84 20.3828 14.7515 20.5964 14.594 20.754C14.4364 20.9115 14.2228 21 14 21C13.7772 21 13.5636 20.9115 13.406 20.754C13.2485 20.5964 13.16 20.3828 13.16 20.16V14.84H7.84C7.61722 14.84 7.40356 14.7515 7.24603 14.594C7.0885 14.4364 7 14.2228 7 14C7 13.7772 7.0885 13.5636 7.24603 13.406C7.40356 13.2485 7.61722 13.16 7.84 13.16H13.16V7.84C13.16 7.61722 13.2485 7.40356 13.406 7.24603C13.5636 7.0885 13.7772 7 14 7C14.2228 7 14.4364 7.0885 14.594 7.24603C14.7515 7.40356 14.84 7.61722 14.84 7.84V13.16H20.16C20.3828 13.16 20.5964 13.2485 20.754 13.406C20.9115 13.5636 21 13.7772 21 14Z"
            fill="#0A50D4"
          />
        </svg>
      </Button>
    </div>
  );
};
