import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { history, store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApplicationThemeProvider } from './providers/theme.provider';
import { DialogContextProvider } from './providers/dialog.provider';
import { FeatureFlagsProvider } from './providers/feature.provider';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter forceRefresh={true}>
          <ApplicationThemeProvider>
            <DialogContextProvider>
              <FeatureFlagsProvider>
                <App />
              </FeatureFlagsProvider>
            </DialogContextProvider>
          </ApplicationThemeProvider>
          <ToastContainer />
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
