import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CrudTable, useCrudEntity } from '../../../components/table/CrudTable';
import Button from '../../../components/button';
import { ReactComponent as EditIcon } from '../../../icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/deleteIcon.svg';
import { ReactComponent as AddIcon } from '../../../icons/addIcon.svg';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../components/input/search';
import { useCallback } from 'react';
import { useDialog } from '../../../providers/dialog.provider';
import { AddUser } from './modals/AddUser';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { ConfirmModal } from '../../../components/modal/ConfirmModal';
import { usePagination } from '../../../components/table/usePagination';
import { useEffect } from 'react';
import { Pagination } from '../../../components/table/pagination';
import { adminAPIs } from '../../../services/adminAPIs';
import {ReactComponent as UserIcon} from '../../../icons/userIcon.svg'
import { checkIsAdmin } from '../../../utils/common';
import { loginAs } from '../../../store/actions/auth';

export default function Users() {
  const [data, setData] = useState([]);
  const dialog = useDialog();
  const currentUser = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(currentUser)
  const roles = {
    admin: 'Админ',
    user: 'Юзер',
  };
  const columns = {
    full_name: {
      label: 'ФИО',
    },
    email: {
      label: 'Email',
    },
    org: {
      label: 'Организация',
    },
    role: {
      label: 'Роль',
      renderBodyCell: (value) => {
        return <p>{roles[value.role]}</p>;
      },
    },
    // limits: {
    //   label: 'Лимит (месяц)',
    //   renderBodyCell: (value) => {
    //     return (
    //       <Button variant='text' className='tw-w-fit tw-p-0 hover:tw-bg-transparent tw-leading-[18px] tw-font-medium tw-text-sm'>
    //         {value?.count_month_limit} / {value?.max_scan_product_month || '-'}
    //       </Button>
    //     )
    //   }
    // }
  };
  const usersCrudAdapter = useCrudEntity({
    readAll: (limit, page_num, query) => adminAPIs.getUsers(limit, page_num, query),
    create: (payload) =>
      adminAPIs
        .addUser(payload)
        .then(() => {
          createNotification('Пользователь создан');
          dialog.close();
          usersCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data));
        })
        .catch((err) => {
          console.error(err);
          if (err.message === 'password invalid'){
            createErrorNotification('Пароль должен быть не менее 8 символов')
          }
          else {

            createErrorNotification('Ошибка создания пользователя');
          }
        }),
    edit: (payload) =>
      adminAPIs
        .editUser(payload)
        .then(() => {
          createNotification('Пользователь изменён');
          dialog.close();
          usersCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data));
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при изменении пользователя');
        }),
    delete: (id) =>
      adminAPIs
        .deleteUser(id)
        .then(() => usersCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data)))
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при удалении пользователя');
        }),
  });

  const showFormModal = useCallback(() => {
    dialog.open(
      <AddUser
        title={'Новый пользователь'}
        value={{
          full_name: '',
          email: '',
          password: '',
          role: '',
          max_scan_product_day: 300,
          max_scan_product_month: 2000
        }}
        onSubmit={(value) => usersCrudAdapter.create(value)}
      />
    );
  }, [dialog, usersCrudAdapter]);
  const showEditModal = useCallback(
    (entity) => {
      dialog.open(
        <AddUser
          title="Редактировать пользователя"
          value={{
            id: entity.id,
            full_name: entity.full_name,
            email: entity.email,
            role: roles[entity.role],
            max_scan_product_day: entity?.max_scan_product_day,
            max_scan_product_month: entity?.max_scan_product_month
          }}
          onSubmit={(value) => usersCrudAdapter.edit(value)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog, usersCrudAdapter]
  );
  const showConfirmationModal = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal
          value={entity}
          title={'Вы уверенны, что хотите удалить пользователя?'}
          onAgree={usersCrudAdapter.delete}
        />
      );
    },
    [dialog, usersCrudAdapter]
  );

 
  const handleLoginIntoDifferentAccount = async (id) => {
    if (!isAdmin) {
      createErrorNotification('У вас недостаточно прав.')
      return
    }
  // To be able to return to start account (for admin)
  const startedAccount = sessionStorage.getItem('start_account_id')

    await adminAPIs.loginAs(id).then(({token, user}) => {
      loginAs(token, user)
      if (startedAccount === null) {
        sessionStorage.setItem('start_account_id', currentUser.id)
      }
    }).catch((err) => {
      createErrorNotification('Произошла ошибка при попытке входа в аккаунт')
    })
  }
  
  const crudButtons = (entity) => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-6">
        <Button variant="text" className="tw-w-fit tw-p-0 hover:tw-bg-inherit" onClick={() => handleLoginIntoDifferentAccount(entity.id)}>
          <UserIcon />
        </Button>
        <Button variant="text" className="tw-w-fit tw-p-0 hover:tw-bg-inherit" onClick={() => showEditModal(entity)}>
          <EditIcon />
        </Button>
        {entity.id !== currentUser.id && (
          <Button
            variant="text"
            className="tw-w-fit tw-p-0 hover:tw-bg-inherit"
            onClick={() => showConfirmationModal(entity)}
          >
            <DeleteIcon />
          </Button>
        )}
      </div>
    );
  };

  const fetchData = useCallback(
    async (limit = 10, page_num, query = '') => {
      try {
        const res = await usersCrudAdapter.readAll(limit, page_num, query);
        return res;
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      }
    },
    [usersCrudAdapter]
  );

  const {
    data: paginatedData,
    currentPage,
    goToPage,
    total,
    setPageSize,
    pageSize,
    handleChangeQuery
  } = usePagination(fetchData);

  useEffect(() => {
    setData(paginatedData);
  }, [paginatedData]);

  const handleLimitChange = (value) => {
    setPageSize(value);
    goToPage(1);
  };

  return (
    <>
      <div className="tw-flex tw-flex-col">
        <div className="tw-bg-white tw-py-[25px] tw-rounded-lg">
          <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-4">
            <Form onSubmit={() => {}}>
              {() => (
                <>
                  <SearchInput
                    name="search"
                    className="tw-max-w-[312px]"
                    onSearchChange={handleChangeQuery}
                    placeholder="Найти пользователя"
                    type="text"
                    required
                  />
                </>
              )}
            </Form>
            <Button
              variant="text"
              className="tw-p-2 tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold"
              onClick={() => showFormModal()}
            >
              <AddIcon /> Новый пользователь
            </Button>
          </div>
          <CrudTable rows={data} entityColumns={columns} crudButtons={crudButtons} />
        </div>
      </div>
      <div>
        <Pagination
          rowsPerPage={pageSize}
          count={total}
          page={currentPage}
          onPageChange={goToPage}
          onLimitChange={handleLimitChange}
        />
      </div>
    </>
  );
}
