import cx from 'classnames';
import { Spinner } from 'react-bootstrap';
import LoaderIcon from '../../icons/loader';
import classes from './loader.module.sass';

export default function Loader({ absolute = false, className }) {
  return (
    <div className={cx(classes.loader, className, { [classes.absolute]: absolute })}>
      <Spinner animation="border">
        <LoaderIcon />
      </Spinner>
    </div>
  );
}
