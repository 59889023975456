import moment from 'moment';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../icons/arrowRightIcon.svg';
import GeneralLayout from '../../components/general-layout';
import RangePicker from '../../components/range-picker';

export const StatisticsItemPage = () => {
  const { productId } = useParams();
  const { id } = useParams();
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month').startOf('hour').unix() * 1000,
    till: moment().startOf('hour').unix() * 1000,
  });
  const [isLoading, setIsLoading] = useState(true);
  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col">
        <h2 className="tw-text-2xl md:tw-text-3xl tw-text-gray-800 tw-font-bold tw-mb-8">Аналитика</h2>
        <div className="tw-flex tw-flex-row tw-items-center tw-mb-[31px]">
          <Link to="/statistics">Аналитика</Link>
          <ArrowRight className="tw-fill-[#191919]" />
          <Skeleton variant='text' width={80} />
          {/* <Link to={`/statistics/${id}`}>{category}</Link> */}
          <ArrowRight className="tw-fill-[#191919]" />
          <Skeleton variant='text' width={80} />
          {/* <Link to={`/statistics/${id}/${productId}`}>{productName}</Link> */}
        </div>
        <div className="tw-flex tw-flex-row tw-items-start tw-mb-6">
          <RangePicker queryParams={queryParams} setQueryParams={setQueryParams} />
        </div>
        {isLoading && (
          <div className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-rounded-lg">
            <div className="tw-flex tw-flex-row tw-items-start tw-w-full tw-justify-between">
              <div className="tw-flex tw-flex-row tw-pt-4 tw-pl-4 tw-gap-[23px] tw-w-full">
                <Skeleton variant="rounded" width={120} height={120} />
                <Skeleton variant='text' height={20} width={'40%'}/>
              </div>
              <div className="tw-flex tw-flex-row tw-gap-6">
              <div className="tw-bg-[#D9D9D9] tw-w-[1px] tw-h-[226px]"></div>
              <div className="tw-flex tw-flex-col tw-items-start tw-min-w-[220px] tw-pt-6 tw-pr-6 tw-pb-[17px]">
              <div className="tw-flex tw-flex-col tw-mb-[15px] tw-w-full">
                <Skeleton variant='text' height={18} width={'100%'}/>
                <Skeleton variant='text' height={28} width={'100%'}/>
              </div>
              <div className="tw-flex tw-flex-col tw-mb-[18px] tw-w-full">
                <Skeleton variant='text' height={18} width={'100%'}/>
                <Skeleton variant='text' height={20} width={'100%'}/>
              </div>
                </div>
                </div>
            </div>
          </div>
        )}
      </div>
    </GeneralLayout>
  );
};
